import {List} from "@damntools.fr/types"
import {LibraryResource} from "../../../models"
import {LibraryResourceProviderState} from "../../../providers"

export type LibraryViewTableProps = {
  resources: List<LibraryResource>
  provider: LibraryResourceProviderState
  width?: number
  setSelected: (selected: List<number>) => Promise<any>
  selection: List<number>
}
export const BIG_SIZE = 1200
