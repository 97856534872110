import {DownloadPreset, YoutubeResourceType} from "../models"
import {Optionable, Optional} from "@damntools.fr/types"

export class UrlHash {
  type: Optionable<YoutubeResourceType>
  id: Optionable<string>
  preset: Optionable<DownloadPreset>
  relativePath: Optionable<string>

  constructor(
    type?: YoutubeResourceType,
    id?: string,
    preset?: DownloadPreset,
    relativePath?: string
  ) {
    this.type = Optional.nullable(type)
    this.id = Optional.nullable(id)
    this.preset = Optional.nullable(preset)
    this.relativePath = Optional.nullable(relativePath)
  }

  toString() {
    let str = "#/youtubedl/form/"
    this.type.ifPresentDo(value => (str += value.key().toLowerCase()))
    str += "/"
    this.id.ifPresentDo(value => (str += value))
    str += "/"
    this.preset.ifPresentDo(value => (str += value.getConfigString().toLowerCase()))
    str += "/"
    // this.relativePath.ifPresentDo(value => (str += value))
    return str
  }
}

export class UrlHashParser {
  static parse(hash: string): UrlHash {
    if (hash.startsWith("#")) hash = hash.replace(/#\/youtubedl\/form\/?/, "")
    const parts = hash.split("/").map(value => (value === "" ? undefined : value))
    const parsed = new UrlHash()
    if (parts.length === 0) return new UrlHash(undefined)
    else {
      parsed.type = Optional.nullable(parts[0]).map(value =>
        YoutubeResourceType.fromValue<string, YoutubeResourceType>(value.toUpperCase())
      )
      parsed.id = Optional.nullable(parts[1])
      parsed.preset = Optional.nullable(parts[2]).map(value =>
        DownloadPreset.parseFromString(value)
      )
      parsed.relativePath = Optional.nullable(parts[3])
      return parsed
    }
  }
}

export class YoutubeUrlParser {
  static parse(url: string, forceType?: YoutubeResourceType): UrlHash {
    const u = new URL(url)
    console.log(u)
    if (u.searchParams.get("list") && u.searchParams.get("v"))
      return new UrlHash(
        forceType || YoutubeResourceType.TRACK,
        (YoutubeResourceType.PLAYLIST.equals(forceType as any)
          ? u.searchParams.get("list")
          : u.searchParams.get("v")) as string
      )
    else if (u.searchParams.get("list"))
      return new UrlHash(
        YoutubeResourceType.PLAYLIST,
        u.searchParams.get("list") as string
      )
    else if (u.searchParams.get("v"))
      return new UrlHash(YoutubeResourceType.TRACK, u.searchParams.get("v") as string)
    return new UrlHash()
  }
}
