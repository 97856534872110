import React from "react"
import styles from "./ProgressBar.module.scss"
import {CssClass} from "@damntools.fr/utils-simple"
import {defined} from "@damntools.fr/types"

export type ProgressBarProps = {
  value: number
  displayText: boolean
  transparent?: boolean
  textAlign?: "left" | "center" | "right"
  threshold?: number
  thresholdWarning?: number
  animate?: boolean
}

export type ProgressBarState = {
  value: number
}

export class ProgressBar extends React.Component<ProgressBarProps, ProgressBarState> {
  componentDidUpdate(prevProps: ProgressBarProps) {
    const state = {} as any
    if (this.props.value !== prevProps.value) state.value = this.props.value
    if (Object.keys(state).length > 0) this.setState(state)
  }

  constructor(props: any) {
    super(props)
    this.state = {
      value: props.value || 0.0
    }
  }

  render() {
    const textAlign = this.props.textAlign ? this.props.textAlign : "center"
    const value = this.state.value
    return (
      <div className={styles.ProgressBar}>
        <div className={styles.Wrapper}>
          <div
            className={CssClass.from(styles.BarWrapper)
              .classIf(() => styles.Transparent, !!this.props.transparent)
              .classIf(() => styles.ThresholdCross, this.isThresholdCrossed())
              .classIf(
                () => styles.ThresholdWarningCross,
                this.isThresholdWarningCrossed()
              )
              .get()}>
            <div className={styles.Background}></div>
            <div
              className={CssClass.from(styles.ProgressDiv)
                .classIf(styles.Animate, this.props.animate)
                .get()}
              style={{width: this.state.value + "%"}}></div>
          </div>
          <div className={styles.Text} style={{textAlign}}>
            {this.props.displayText ? <span>{value.toFixed(1)} %</span> : null}
          </div>
        </div>
      </div>
    )
  }

  private isThresholdCrossed() {
    const threshold = defined(this.props.threshold)
      ? this.props.threshold
      : Number.MAX_VALUE
    return this.state.value > (threshold as number)
  }

  private isThresholdWarningCrossed() {
    const threshold = defined(this.props.threshold)
      ? this.props.threshold
      : Number.MAX_VALUE
    const thresholdWarning = defined(this.props.thresholdWarning)
      ? this.props.thresholdWarning
      : Number.MAX_VALUE
    return (
      this.state.value > (thresholdWarning as number) &&
      this.state.value <= (threshold as number)
    )
  }
}
