import React from "react"
import styles from "./PresetSelector.module.scss"
import {CssClass, StringUtils} from "@damntools.fr/utils-simple"
import {DownloadPreset, YoutubeFormatQuality} from "../../models"
import {
  png_outline_mixed_black_18dp,
  png_outline_movie_black_18dp,
  png_outline_music_video_black_18dp
} from "../../../common"
import {Optionable, Optional} from "@damntools.fr/types"

export type PresetSelectorProps = {
  value: Optionable<DownloadPreset>
  onChange: (value: Optionable<DownloadPreset>) => void
  onlyAudio?: boolean
  noIcon?: boolean
}

export const PresetSelector = (props: PresetSelectorProps) => {
  return (
    <div className={styles.PresetSelector}>
      <div>
        <div>
          {DownloadPreset.all<DownloadPreset>()
            .stream()
            .filter(dp => dp.isAudio())
            .map((dp: DownloadPreset, i: number, arr: Array<DownloadPreset>) =>
              getButton(props, dp, png_outline_music_video_black_18dp, i, arr.length)
            )
            .collectArray()}
        </div>
        {!props.onlyAudio ? (
          <div>
            {DownloadPreset.all<DownloadPreset>()
              .stream()
              .filter(dp => dp.isVideo())
              .map((dp: DownloadPreset, i: number, arr: Array<DownloadPreset>) =>
                getButton(props, dp, png_outline_movie_black_18dp, i, arr.length)
              )
              .collectArray()}
          </div>
        ) : null}
        {!props.onlyAudio ? (
          <div>
            {DownloadPreset.all<DownloadPreset>()
              .stream()
              .filter(dp => dp.isMixed())
              .map((dp: DownloadPreset, i: number, arr: Array<DownloadPreset>) =>
                getButton(props, dp, png_outline_mixed_black_18dp, i, arr.length)
              )
              .collectArray()}
          </div>
        ) : null}
      </div>
    </div>
  )
}

const getButton = (
  props: PresetSelectorProps,
  preset: DownloadPreset,
  icon: string,
  index: number,
  length: number
) => {
  let str = StringUtils.firstToUpper(preset.quality.key())
  if (
    YoutubeFormatQuality.WORST.equals(preset.quality) ||
    YoutubeFormatQuality.BEST.equals(preset.quality)
  )
    str += " quality"
  if (preset.param) str += ` | ${preset.param.key().toUpperCase()}`
  const classes = CssClass.from(styles.Preset)
    .class(styles[preset.type.key().toLowerCase()])
    .classIf(() => styles.Selected, props.value.filter(v => preset.equals(v)).isPresent())
    .get()
  const rotate = Math.trunc(-1 * ((index * 120) / length))
  return (
    <span
      key={preset.getConfigString()}
      className={classes}
      onClick={() => onClick(props, preset)}
      style={{filter: `hue-rotate(${rotate}deg)`}}>
      <span></span>
      {!props.noIcon ? <img src={icon} alt={preset.type.key()} title={preset.type.key()} />: null}
      <span>{str}</span>
    </span>
  )
}

const onClick = (props: PresetSelectorProps, preset: DownloadPreset) => {
  const value =
    props.value.isPresent() && preset.equals(props.value.get())
      ? Optional.empty<DownloadPreset>()
      : Optional.of(preset)
  props.onChange(value)
}
