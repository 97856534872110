import {Enum} from "@damntools.fr/types"

export class LibraryToolbarSearchType extends Enum<string> {
  static ALL = new LibraryToolbarSearchType("all")
  static TITLE = new LibraryToolbarSearchType("title")
  static ARTIST = new LibraryToolbarSearchType("artist")
  static ALBUM = new LibraryToolbarSearchType("album")
  static GENRE = new LibraryToolbarSearchType("genre")
  static YEAR = new LibraryToolbarSearchType("year")
  static TAG = new LibraryToolbarSearchType("tag")


  constructor(key: string) {
    super(key)
  }
}
