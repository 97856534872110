import {Collectors, containsProperty, List, Lists} from "@damntools.fr/types"
import {
  DiskInfo,
  DiskInfoDto,
  FileResourceType,
  ScannedDirectoryResource,
  ScannedDirectoryResourceCtor,
  ScannedDirectoryResourceDto,
  ScannedFileResource,
  ScannedFileResourceCtor,
  ScannedFileResourceDto,
  ScannedFilesystemResource,
  ScannedFilesystemResourceDto,
  ScannedResourceDownloadRequest,
  ScannedResourceDownloadRequestCtor,
  ScannedResourceDownloadRequestDto,
  ScannedRootDirectoryResource,
  ScannedRootDirectoryResourceCtor,
  ScannedRootDirectoryResourceDto
} from "../../common"

export class ScannedResourceMapper {
  static resourceToDto(
    resource: ScannedFilesystemResource
  ): ScannedFilesystemResourceDto {
    if (resource instanceof ScannedRootDirectoryResource)
      return ScannedResourceMapper.rootDirectoryToDto(resource)
    if (resource instanceof ScannedDirectoryResource)
      return ScannedResourceMapper.directoryToDto(resource)
    else if (resource instanceof ScannedFileResource)
      return ScannedResourceMapper.fileToDto(resource)
    throw new Error("Invalid type !")
  }

  static resourceFromDto(dto: ScannedFilesystemResourceDto): ScannedFilesystemResource {
    if (containsProperty(dto, "diskInfo"))
      return ScannedResourceMapper.rootDirectoryFromDto(
        dto as ScannedRootDirectoryResourceDto
      )
    else if (containsProperty(dto, "count"))
      return ScannedResourceMapper.directoryFromDto(dto as ScannedDirectoryResourceDto)
    else return ScannedResourceMapper.fileFromDto(dto as ScannedFileResourceDto)
  }

  static fileToDto(file: ScannedFileResource): ScannedFileResourceDto {
    return {
      path: file.path,
      extension: file.extension,
      parent: file.parent,
      size: file.size,
      tags: file.tags.getInner(),
      title: file.title,
      type: file.type.key()
    }
  }

  static fileFromDto(dto: ScannedFileResourceDto): ScannedFileResource {
    if (!dto.type) {
      console.log(dto)
    }
    const ctor: ScannedFileResourceCtor = {
      path: dto.path,
      extension: dto.extension,
      parent: dto.parent,
      size: dto.size,
      tags: dto.tags ? Lists.from(dto.tags) : Lists.empty(),
      title: dto.title,
      type: FileResourceType.fromValue(dto.type)
    }
    return new ScannedFileResource(ctor)
  }

  static directoryToDto(
    directory: ScannedDirectoryResource
  ): ScannedDirectoryResourceDto {
    return {
      title: directory.title,
      path: directory.path,
      count: directory.count,
      size: directory.size,
      parent: directory.parent
    }
  }

  static directoryFromDto(dto: ScannedDirectoryResourceDto): ScannedDirectoryResource {
    const ctor: ScannedDirectoryResourceCtor = {
      path: dto.path,
      title: dto.title,
      count: dto.count,
      size: dto.size,
      parent: dto.parent
    }
    return new ScannedDirectoryResource(ctor)
  }

  static rootDirectoryToDto(
    directory: ScannedRootDirectoryResource
  ): ScannedRootDirectoryResourceDto {
    return {
      title: directory.title,
      path: directory.path,
      count: directory.count,
      size: directory.size,
      parent: directory.parent,
      diskInfo: ScannedResourceMapper.diskInfoToDto(directory.diskInfo)
    }
  }

  static rootDirectoryFromDto(
    dto: ScannedRootDirectoryResourceDto
  ): ScannedRootDirectoryResource {
    const ctor: ScannedRootDirectoryResourceCtor = {
      diskInfo: ScannedResourceMapper.diskInfoFromDto(dto.diskInfo),
      path: dto.path,
      title: dto.title,
      count: dto.count,
      size: dto.size,
      parent: dto.parent
    }
    return new ScannedRootDirectoryResource(ctor)
  }

  static diskInfoFromDto(dto: DiskInfoDto): DiskInfo {
    return new DiskInfo(
      dto.availableSize,
      dto.totalSize,
      dto.usedSize,
      dto.rootIdentifier
    )
  }

  static resourceDownloadRequestFromDto(
    dto: ScannedResourceDownloadRequestDto
  ): ScannedResourceDownloadRequest {
    const ctor: ScannedResourceDownloadRequestCtor = {
      filename: dto.filename,
      failed: dto.failed,
      progress: dto.progress,
      ready: dto.ready,
      token: dto.token
    }
    return new ScannedResourceDownloadRequest(ctor)
  }

  static diskInfoToDto(diskInfo: DiskInfo): DiskInfoDto {
    return {
      availableSize: diskInfo.availableSize,
      totalSize: diskInfo.totalSize,
      usedSize: diskInfo.usedSize,
      rootIdentifier: diskInfo.rootIdentifier
    }
  }

  static resourcesFromDto(
    dtos: Array<ScannedFilesystemResourceDto>
  ): List<ScannedFilesystemResource> {
    return Lists.from(dtos)
      .stream()
      .map(r => ScannedResourceMapper.resourceFromDto(r))
      .collect(Collectors.toList)
  }

  static resourcesToDto(
    resources: List<ScannedFilesystemResource>
  ): Array<ScannedFilesystemResourceDto> {
    return resources
      .stream()
      .map(r => ScannedResourceMapper.resourceToDto(r))
      .collectArray()
  }
}
