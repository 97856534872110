import {BackendService} from "../../common"

import {AxiosWrapper} from "@damntools.fr/http"
import {List, Lists} from "@damntools.fr/types"
import {LibraryTagDto} from "../models/dto/LibraryTag"

export class LibraryTagService {
  static INSTANCE: LibraryTagService | null = null
  private readonly client: AxiosWrapper

  constructor() {
    this.client = BackendService.authenticatedAxios().child({baseURL: "/library/tag"})
  }

  getAllTags(): Promise<List<LibraryTagDto>> {
    return this.client
      .get("")
      .then(res => res.data as Array<any>)
      .then(res => Lists.from<LibraryTagDto>(res))
  }

  static get(): LibraryTagService {
    if (this.INSTANCE === null) this.INSTANCE = new LibraryTagService()
    return this.INSTANCE
  }
}
