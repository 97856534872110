import {WsEventHandler} from "../../../common"
import {
  ExpectedMetaScanSizePayload,
  MetaExtractionCompletePayload,
  MetaExtractionSummaryPayload,
  MetaProcessingCompletePayload
} from "./LibraryEventPayloads"
import {LibraryScanProvider} from "../../providers"

export class MetaExtractionCompleteHandler extends WsEventHandler<MetaExtractionCompletePayload> {
  getType(): string {
    return "MetaExtractionCompleteEvent"
  }

  process(payload: MetaExtractionCompletePayload): void {
    LibraryScanProvider.setMetaExtractionComplete(payload)
  }
}

export class MetaProcessingCompleteHandler extends WsEventHandler<MetaProcessingCompletePayload> {
  getType(): string {
    return "MetaProcessingCompleteEvent"
  }

  process(payload: MetaProcessingCompletePayload): void {
    LibraryScanProvider.setMetaProcessingComplete(payload)
  }
}

export class ExpectedMetaScanSizeHandler extends WsEventHandler<ExpectedMetaScanSizePayload> {
  getType(): string {
    return "ExpectedMetaScanSizeEvent"
  }

  process(payload: ExpectedMetaScanSizePayload): void {
    LibraryScanProvider.setStarted(payload.files)
  }
}

export class MetaExtractionSummaryHandler extends WsEventHandler<MetaExtractionSummaryPayload> {
  getType(): string {
    return "MetaExtractionSummaryEvent"
  }

  process(payload: MetaExtractionSummaryPayload): void {
    LibraryScanProvider.setSummary(payload)
  }
}
