import React from "react"
import styles from "./DirectoryResourceEntry.module.scss"
import entryStyles from "../Entry.module.scss"
import {CssClass} from "@damntools.fr/utils-simple"
import {ScannedDirectoryResource, ScannedResourceDownloadRequest} from "../../../common"
import {
    png_delete,
    png_download,
    png_outline_folder_open_black_18dp,
    ProgressBar,
    UrlUtils,
    WithAdminRights,
    WithElevatedRights
} from "../../../common"
import {AlertProvider, Notification, Popin} from "@damntools.fr/react-alert"
import {ExplorerService} from "../../services"
import {Optionable, Optional} from "@damntools.fr/types"

export type DirectoryResourceEntryProps = {
    resource: ScannedDirectoryResource
    selected: boolean
    onDelete: (resourceId: string) => void
}

export type DirectoryResourceEntryState = {
    zipCreation: boolean
    zipCreationProgress: number
    downloadRequest: Optionable<ScannedResourceDownloadRequest>
}

export class DirectoryResourceEntry extends React.Component<
  DirectoryResourceEntryProps,
  DirectoryResourceEntryState
> {
  private zipCreationTrackInterval: NodeJS.Timer | null = null

  constructor(
    props: Readonly<DirectoryResourceEntryProps> | DirectoryResourceEntryProps
  ) {
    super(props)
    this.state = {
      zipCreation: false,
      zipCreationProgress: 0.0,
      downloadRequest: Optional.empty()
    }
  }

  render() {
    return (
      <tr
        className={CssClass.from(styles.DirectoryResourceEntry)
          .class(entryStyles.ResourceEntry)
          .classIf(() => entryStyles.Selected, this.props.selected)
          .get()}>
        <td className={entryStyles.Checkbox}>
          <input type="checkbox" disabled defaultChecked={this.props.selected} />
        </td>
        <td className={entryStyles.Icon}>
          <img
            src={png_outline_folder_open_black_18dp}
            alt="Directory"
            title={"Directory"}
          />
        </td>
        <td
          className={entryStyles.Title}
          onClick={() =>
            (window.location.hash = `#/explorer/dir/${encodeURIComponent(
              this.props.resource.path
            )}`)
          }>
          <span>{this.props.resource.title}</span>
        </td>
        <td className={entryStyles.Size}>
          <span>{this.props.resource.getCleanSize()}</span>
        </td>
        <td className={entryStyles.Count}>
          <span>{this.props.resource.count.toLocaleString()} files</span>
        </td>
        <td className={entryStyles.TagIcons}>{this.getDownloadProgressElement()}</td>
        <td className={entryStyles.ActionIcons + " " + entryStyles.Clickable}>
          <WithElevatedRights>
            <span
              className={CssClass.from(entryStyles.DownloadButton)
                .classIf(() => entryStyles.Unclickable, this.state.zipCreation)
                .get()}
              onClick={() => this.onDownloadClick()}>
              <img src={png_download} alt={"Download as ZIP"} title="Download as ZIP" />
            </span>
          </WithElevatedRights>
          <WithAdminRights>
            <span
              className={entryStyles.DeleteButton}
              onClick={() => this.props.onDelete(this.props.resource.path)}>
              <img src={png_delete} alt={"Delete"} title="Delete" />
            </span>
          </WithAdminRights>
        </td>
      </tr>
    )
  }

  onDownloadClick() {
    if (!this.state.zipCreation) {
      AlertProvider.submit(
        Popin.warning()
          .Subtitle("ZIP creation required !")
          .Content("It may take a while to create the archive.")
          .SuccessAction(id => this.startDownload(id))
      )
    }
  }

  startDownload(id: string) {
    AlertProvider.removeAlert(id)
    return ExplorerService.get()
      .requestResourceDownload(this.props.resource)
      .then(() => {
        this.setState({zipCreation: true}, () => {
          this.zipCreationTrackInterval = setInterval(() => {
            ExplorerService.get()
              .getRequestResourceDownload(this.props.resource)
              .then(request => {
                this.setState({zipCreationProgress: request.progress}, () => {
                  if (request.isReady()) {
                    clearInterval(this.zipCreationTrackInterval as any)
                    this.setState({
                      zipCreation: false,
                      downloadRequest: Optional.of(request),
                      zipCreationProgress: 0
                    })
                  }
                })
              })
          }, 1000)
        })
      })
      .catch(err => {
        if (err?.message == ExplorerService.TOO_BIG_ERROR_MESSAGE) {
          AlertProvider.submit(
            Notification.error().Subtitle("Directory is too big to be downloaded !")
          )
        } else {
          console.error(err)
          AlertProvider.submit(
            Notification.error().Subtitle("Could not start file download !")
          )
        }
      })
  }

  private getDownloadProgressElement() {
    if (this.state.downloadRequest.isPresent()) {
      return (
        <div
          onClick={() => this.openRealDownload()}
          className={styles.RealDownloadButton}
          style={{textAlign: "center"}}>
          <span>Download</span>
        </div>
      )
    } else if (this.state.zipCreation) {
      return (
        <ProgressBar value={this.state.zipCreationProgress} displayText transparent />
      )
    }
    return null
  }

  private openRealDownload() {
    this.state.downloadRequest.ifPresentDo(request => {
      const url = ExplorerService.getDownloadUrl(this.props.resource, request)
      UrlUtils.openUrlWithFilename(url.url, url.name)
      this.setState({downloadRequest: Optional.empty()})
    })
  }
}
