import React from "react"
import styles from "./RootDirectoryResourceEntry.module.scss"
import {CssClass} from "@damntools.fr/utils-simple"
import {
  DiskInfo,
  png_outline_folder_open_black_18dp,
  ProgressBar,
  ScannedRootDirectoryResource
} from "../../../common"
import prettyBytes from "pretty-bytes"
import {Optional} from "@damntools.fr/types"
import {ColorUtils} from "@damntools.fr/react-utils"

export type RootDirectoryResourceEntryProps = {
  index: number
  rootCount: number
  resource: ScannedRootDirectoryResource
}

export class RootDirectoryResourceEntry extends React.Component<
  RootDirectoryResourceEntryProps,
  any
> {
  render() {
    const rgbValue = ColorUtils.calculateRgbForIndex(
      this.props.index,
      this.props.rootCount,
      0.5,
      0.8
    )
    const diskCompletionPercent = this.getDiskBarValue()
    return (
      <div className={CssClass.from(styles.RootDirectoryResourceEntry).get()}>
        <div
          style={{borderColor: ColorUtils.rgbToStyle(rgbValue)}}
          onClick={() => {
            window.location.hash = `#/explorer/dir/${encodeURIComponent(
              this.props.resource.path
            )}`
          }}>
          <div className={styles.Icon}>
            <img src={png_outline_folder_open_black_18dp} alt="Directory" />
          </div>
          <div>
            <div
              className={styles.RootTitle}
              style={{color: ColorUtils.rgbToStyle(rgbValue)}}>
              <span>{this.props.resource.title}</span>
            </div>
            <div className={styles.RootLabels}>
              <span className={styles.RootAvailable}>Files</span>
              <span>{this.props.resource.count.toLocaleString()}</span>
              <span className={styles.RootSize}>Size</span>
              <span>
                {prettyBytes(this.props.resource.size) +
                  " (" +
                  this.getSizePercent() +
                  " %)"}
              </span>
              <span className={styles.RootAvailable}>Available</span>
              <span>
                {prettyBytes(this.props.resource.diskInfo.availableSize) +
                  " (" +
                  this.getAvailablePercent(diskCompletionPercent) +
                  " %)"}
              </span>
              <span className={styles.RootAvailable}>Total</span>
              <span>{prettyBytes(this.props.resource.diskInfo.totalSize)}</span>
            </div>
          </div>
          <div>
            <div>
              <ProgressBar
                textAlign={"right"}
                transparent
                value={diskCompletionPercent}
                displayText
                threshold={88}
                thresholdWarning={85}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  getDiskBarValue(): number {
    return Optional.of(this.props.resource)
      .map(d => d.diskInfo as DiskInfo)
      .map(di => 100 - (di.availableSize * 100) / di.totalSize)
      .orElseReturn(0)
  }

  getAvailablePercent(diskCompletion: number) {
    return Optional.of(100 - diskCompletion)
      .map(x => x.toFixed(1))
      .get()
  }

  getSizePercent() {
    return Optional.of(this.props.resource)
      .map(d => d.diskInfo as DiskInfo)
      .map(di => (this.props.resource.size * 100) / di.totalSize)
      .map(x => x.toFixed(2))
      .get()
  }
}
