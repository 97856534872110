import {BackendService} from "../../common"

import {AxiosWrapper} from "@damntools.fr/http"
import {
  FlatResourceDto,
  LibraryAlbumDto,
  LibraryArtistDto,
  LibraryGenreDto,
  LibraryResource,
  LibraryResourceDto,
  LibraryResourceType,
  LibraryTagDto
} from "../models"
import {ArrayList, List, Lists, toList} from "@damntools.fr/types"
import {LibraryTagService} from "./LibraryTagService"
import {LibraryGenreService} from "./LibraryGenreService"
import {LibraryArtistService} from "./LibraryArtistService"
import {LibraryAlbumService} from "./LibraryAlbumService"
import {AlertProvider, Popin} from "@damntools.fr/react-alert"

export class LibraryResourceService {
  static INSTANCE: LibraryResourceService | null = null
  private readonly client: AxiosWrapper

  constructor() {
    this.client = BackendService.authenticatedAxios().child({
      baseURL: "/library/resource"
    })
  }

  getAllFlatResources(): Promise<List<FlatResourceDto>> {
    return this.client
      .get("/flat")
      .then(res => res.data as Array<any>)
      .then(res => Lists.from<FlatResourceDto>(res))
  }

  getRichResources(): Promise<List<LibraryResource>> {
    return Promise.all([
      this.getAllFlatResources(),
      LibraryTagService.get().getAllTags(),
      LibraryGenreService.get().getAllGenres(),
      LibraryArtistService.get().getAllArtists(),
      LibraryAlbumService.get().getAllAlbums()
    ]).then(results => {
      const dtos = results[0] as List<FlatResourceDto>
      const tags = results[1] as List<LibraryTagDto>
      const genres = results[2] as List<LibraryGenreDto>
      const artists = results[3] as List<LibraryArtistDto>
      const albums = results[4] as List<LibraryAlbumDto>
      return dtos
        .stream()
        .map(dto =>
          LibraryResourceService.completeDtoWithRelations(
            dto,
            tags,
            genres,
            artists,
            albums
          )
        )
        .collect(toList)
    })
  }

  static completeDtoWithRelations(
    dto: FlatResourceDto,
    tags: List<LibraryTagDto>,
    genres: List<LibraryGenreDto>,
    artists: List<LibraryArtistDto>,
    albums: List<LibraryAlbumDto>
  ) {
    const resource: LibraryResource = {...dto} as any
    resource.genres = Lists.empty()
    resource.artists = Lists.empty()
    resource.tags = Lists.empty()
    resource.type = LibraryResourceType.optionalFromValue(
      dto.type.toLowerCase()
    ).orElseUndefined() as LibraryResourceType
    if (dto.genres && dto.genres.length > 0)
      resource.genres = genres
        .stream()
        .filter(genre => dto.genres.includes(genre.id))
        .collect(toList)
    if (dto.tags && dto.tags.length > 0)
      resource.tags = tags
        .stream()
        .filter(tag => dto.tags.includes(tag.id))
        .collect(toList)
    if (dto.artists && dto.artists.length > 0)
      resource.artists = artists
        .stream()
        .filter(artist => dto.artists.includes(artist.id))
        .collect(toList)
    if (dto.codecs && dto.codecs.length > 0) {
      resource.codecs = dto.codecs.toList()
    } else {
      resource.codecs = new ArrayList()
    }
    if (dto.languages && dto.languages.length > 0) {
      resource.languages = dto.languages.toList()
    } else {
      resource.languages = new ArrayList()
    }
    if (dto.albumId)
      resource.album = albums
        .stream()
        .filter(album => album.id === dto.albumId)
        .findFirst()
        .orElseUndefined()
    return resource
  }

  runResetAndFullScan() {
    void AlertProvider.submit(
      Popin.warning("Delete all saved resources and run full scan ?").OnSuccess(() => {
        return this.client.put("/scan")
      })
    )
  }

  retrieveByPath(path: string) {
    return this.client
      .get(`/file?filePath=${encodeURIComponent(path)}`)
      .then(res => res.data as any)
      .then(res => {
        res.type = LibraryResourceType.fromValue(res.type.toLowerCase())
        return res as LibraryResource
      })
  }

  getCompleteByIds(ids: List<string>) {
    return this.client
      .get(`/complete?ids=${encodeURIComponent(ids.getInner().join(","))}`)
      .then(res => res.data as Array<LibraryResourceDto>)
      .then(res =>
        res.map(r => {
          const res = {
            ...r,
            type: LibraryResourceType.fromValue(r.type.toLowerCase()),
            genres: new ArrayList(r.genres),
            artists: new ArrayList(r.artists),
            tags: new ArrayList(r.tags),
            codecs: new ArrayList(r.codecs),
            languages: new ArrayList(r.languages)
          } as LibraryResource
          return res as LibraryResource
        }).toList()
      )
  }

  static get(): LibraryResourceService {
    if (this.INSTANCE === null) this.INSTANCE = new LibraryResourceService()
    return this.INSTANCE
  }
}
