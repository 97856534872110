import prettyBytes from "pretty-bytes"

export type DiskInfoDto = {
  availableSize: number
  totalSize: number
  usedSize: number
  rootIdentifier: string
}

export type DiskInfoCtor = {
  availableSize: number
  totalSize: number
  usedSize: number
  rootIdentifier: string
}

export class DiskInfo {
  availableSize: number
  totalSize: number
  usedSize: number
  rootIdentifier: string

  constructor(
    availableSize: number,
    totalSize: number,
    usedSize: number,
    rootIdentifier: string
  ) {
    this.availableSize = availableSize
    this.totalSize = totalSize
    this.usedSize = usedSize
    this.rootIdentifier = rootIdentifier
  }

  getCleanAvailableSize(): string {
    return prettyBytes(this.availableSize)
  }

  getCleanTotalSize(): string {
    return prettyBytes(this.totalSize)
  }

  getCleanUsedSize(): string {
    return prettyBytes(this.usedSize)
  }
}
