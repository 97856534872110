import React from "react"
import styles from "./FormatCodeSelector.module.scss"
import {YoutubeFormat, YoutubeFormatType} from "../../models"
import {ArrayList, Collectors, List} from "@damntools.fr/types"
import {CheckboxInput} from "@damntools.fr/react-inputs"
import {NumberUtils} from "@damntools.fr/utils-simple"
import prettyBytes from "pretty-bytes"
import {
  png_outline_mixed_black_18dp,
  png_outline_movie_black_18dp,
  png_outline_music_video_black_18dp
} from "../../../common"
import {StyleSize} from "@damntools.fr/react-utils"

export type FormatCodeSelectorProps = {
  selectedValues: List<YoutubeFormat>
  values: List<YoutubeFormat>
  onChange: (formats: List<YoutubeFormat>) => void
}

export const FormatCodeSelector = (props: FormatCodeSelectorProps) => {
  return (
    <div className={styles.FormatCodeSelector}>
      <table>
        <tbody>
        {props.values
          .sort(
            (a: YoutubeFormat, b: YoutubeFormat) =>
              a.type.compare(b.type) ||
              a.size - b.size ||
              a.code.localeCompare(b.code)
          )
          .stream()
          .map(format => getEntry(props, format))
          .collectArray()}
        </tbody>
      </table>
    </div>
  )
}


const getEntry = (props: FormatCodeSelectorProps, format: YoutubeFormat) => {
  return (
    <tr className={styles.YoutubeDLFormatEntry} key={`Format${format.code}`}>
      <td>
        <CheckboxInput
          checked={
            !!props.selectedValues
              .stream()
              .find(f => f.code === format.code)
          }
          dark size={StyleSize.px(16)} color={"white"}
          onChange={e => onChangeValue(props, e.orElseReturn(false), format.code)}
        />
      </td>
      <td>
        <img
          src={getIcon(format.type) as string}
          alt={format.type.key()}
        />
      </td>
      <td>
        <strong>{format.code}</strong>
      </td>
      <td>.{format.extension}</td>
      {formatContainsVideo(format) ? (
        <td>{format.resolution?.replace("*", " x ")}</td>
      ) : null}
      {formatContainsVideo(format) ? (
        <td>
          <strong>{format.resolutionSize}p</strong>
        </td>
      ) : null}
      {formatContainsVideo(format) ? (
        <td>{format.frameRate}fps</td>
      ) : null}
      {formatContainsAudio(format) && format.audioFrequency ? (
        <td>{NumberUtils.formatNumber(format.audioFrequency / 1000, 1)} kHz</td>
      ) : null}
      {format.size ? (
        <td>
          <strong>{prettyBytes(format.size)}</strong>
        </td>
      ) : null}
    </tr>
  )
}

const formatContainsVideo = (format: YoutubeFormat) => {
  return (
    YoutubeFormatType.MIXED.equals(format.type) ||
    YoutubeFormatType.VIDEO.equals(format.type)
  )
}

const formatContainsAudio = (format: YoutubeFormat) => {
  return (
    YoutubeFormatType.MIXED.equals(format.type) ||
    YoutubeFormatType.AUDIO.equals(format.type)
  )
}

const getIcon = (type: YoutubeFormatType):
  string => {
  switch (type) {
    case YoutubeFormatType.AUDIO:
      return png_outline_music_video_black_18dp
    case YoutubeFormatType.VIDEO:
      return png_outline_movie_black_18dp
    case YoutubeFormatType.MIXED:
      return png_outline_mixed_black_18dp
    default:
      return ""
  }
}

const onChangeValue = (props: FormatCodeSelectorProps, value: boolean, code: string) => {
  const existing = props.values.stream().find(v => v.code === code)
  let formats: List<YoutubeFormat> = new ArrayList()
  if (props.selectedValues.stream().find(f => f.code === code) && !value)
    formats = props.selectedValues
      .stream()
      .filter(f => f.code !== code)
      .collect(Collectors.toList)
  else if (existing && value) formats.concat(props.selectedValues).push(existing)
  props.onChange(formats)
}
