import {Collectors, List, Lists, Optionable, Optional} from "@damntools.fr/types";
import {DownloadPreset, YoutubeFormat, YoutubeFormatAudioExt, YoutubeFormatQuality, YoutubeFormatType} from "../models";

export class YoutubeFormatUtils {

    static getPresetAssociatedConf(
        preset: Optionable<DownloadPreset>,
        resourceFormats: List<YoutubeFormat>
    ) {
        if (preset.isPresent()) {
            const presetConf = preset.get().presetFn(resourceFormats)
            return {
                audioFormat: Optional.nullable(presetConf.audioFormat),
                convertToAudio: Optional.of(presetConf.convertToAudio),
                formatCodes: presetConf.formatCodes
            }
        }
        return {
            audioFormat: Optional.empty<YoutubeFormatAudioExt>(),
            convertToAudio: Optional.empty<boolean>()
        }
    }

    static getFormatCodeForAudioRequest(
        formatCodes: List<YoutubeFormat>,
        quality: YoutubeFormatQuality
    ): YoutubeFormat {
        const formats = formatCodes
            .stream()
            .filter(f => YoutubeFormatType.AUDIO.equals(f.type))
            .sort((a, b) => a.size - b.size)
        return YoutubeFormatQuality.BEST.equals(quality)
            ? formats.findLast().get()
            : formats.findFirst().get()
    }

    static getFormatCodeForVideoRequest(
        formatCodes: List<YoutubeFormat>,
        quality: YoutubeFormatQuality
    ): YoutubeFormat | undefined {
        let formats = formatCodes
            .stream()
            .filter(f => YoutubeFormatType.VIDEO.equals(f.type))
            .sort((a, b) => a.size - b.size)
        if (
            YoutubeFormatQuality.BEST.equals(quality) ||
            YoutubeFormatQuality.WORST.equals(quality)
        )
            return YoutubeFormatQuality.BEST.equals(quality)
                ? formats.findLast().get()
                : formats.findFirst().get()
        else if (/^\d+p$/i.test(quality.key())) {
            formats = formats.filter(f => quality.key() === `${f.resolutionSize}p`)
            if (formats.collectArray().length > 0) return formats.findFirst().get()
        }
    }

    static getFormatCodeForMixedRequest(
        formatCodes: List<YoutubeFormat>,
        quality: YoutubeFormatQuality
    ): List<YoutubeFormat> {
        if (YoutubeFormatQuality.UNSET.equals(quality))
            return formatCodes
                .stream()
                .filter(f => YoutubeFormatType.MIXED.equals(f.type))
                .sort((a, b) => a.size - b.size)
                .collect(Collectors.toList)
        else if (/^\d+p$/i.test(quality.key())) {
            const videoFormat = this.getFormatCodeForVideoRequest(
                formatCodes,
                quality
            )
            const audioFormat = this.getFormatCodeForAudioRequest(
                formatCodes,
                YoutubeFormatQuality.WORST
            )
            return Lists.of(audioFormat, videoFormat)
        }
        return Lists.empty()
    }
}