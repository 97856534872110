import ReactDOM from "react-dom/client"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import {
  consoleTransport,
  Logging,
  nativeConsoleInterceptor
} from "@damntools.fr/logger-simple"
import {Globals} from "@damntools.fr/react-globals"
import {BackendService, CustomLocalStorageManager} from "./common"
import {App, AuthService, WebSocketListener} from "./application"

Logging.configure({
  level: "DEBUG",
  useCache: false,
  printEmptyMessage: true,
  transports: [
    consoleTransport({
      pattern: "[%V] %L{-25} %red>%Cend %m"
    })
  ],
  loggers: {
    "damntools.layout": "INFO",
    player: "DEBUG"
  },
  collectLocationInfo: true,
  nativeConsoleInterceptor
})
window.onresize = function () {
  location.reload()
}
export const LocalStorageManager = CustomLocalStorageManager.get()

Globals.init().then(globals => {
  AuthService.get().init(() => {
    const backendUrl = globals
      .get<string>("backend.url")
      .orElseThrow(() => new Error("backend.url should be set"))
    BackendService.init(backendUrl)
    const WS_URL = globals
      .get<string>("ws.url")
      .orElseThrow(() => new Error("ws.url should be set"))
    new WebSocketListener(WS_URL).init()
    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
    root.render(<App />)
  })
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
