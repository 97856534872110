import {
  AuthenticatedWrapper,
  AxiosWrapper,
  ConnectionFailed,
  LocalStorageAuthManager
} from "@damntools.fr/http"
import {StorageHelper, StorageSource} from "@damntools.fr/local-storage/browser"
import {AlertProvider, Notification} from "@damntools.fr/react-alert"

export class CustomLocalStorageManager extends LocalStorageAuthManager {
  private static INSTANCE: CustomLocalStorageManager

  protected callTokenRefresh(): Promise<string> {
    return BackendService.axios()
      .post(
        "/auth/refresh",
        {},
        {
          withCredentials: true
        }
      )
      .then(res => res.data as string)
  }

  static get() {
    if (!this.INSTANCE)
      this.INSTANCE = new CustomLocalStorageManager(
        StorageHelper.with(StorageSource.WINDOW_LOCAL)
      )
    return this.INSTANCE
  }

  protected validateAuthentication(): Promise<boolean> {
    return BackendService.authenticatedAxios()
      .get("/user")
      .then(() => true)
      .catch(() => false)
  }
}

export const LocalStorageManager = CustomLocalStorageManager.get()

export class BackendService {
  private static INSTANCE: BackendService
  private readonly baseInstance: AxiosWrapper
  private readonly authenticatedInstance: AuthenticatedWrapper
  public readonly baseUrl: string

  constructor(url: string) {
    this.baseUrl = url
    this.baseInstance = new AxiosWrapper({
      baseURL: url
    })
    this.baseInstance.setResponseErrorHandler(response => {
      if (response instanceof ConnectionFailed) {
        AlertProvider.submit(Notification.error("Could not connect to backend !"))
      }
      throw response
    })
    this.authenticatedInstance = AuthenticatedWrapper.fromWrapper(
      this.baseInstance,
      LocalStorageManager
    )
    this.authenticatedInstance.setTokenExpiredHandler(() => {
      LocalStorageManager.removeAuthentication().then(() => {
        window.location.reload()
      })
    })
  }

  static axios(): AxiosWrapper {
    if (!this.INSTANCE) throw new Error("Access before initialization")
    return this.INSTANCE.baseInstance
  }

  static authenticatedAxios(): AxiosWrapper {
    if (!this.INSTANCE) throw new Error("Access before initialization")
    return this.INSTANCE.authenticatedInstance
  }

  static init(url: string) {
    if (!this.INSTANCE) this.INSTANCE = new BackendService(url)
  }

  static url(): string {
    if (this.INSTANCE) return this.INSTANCE.baseUrl
    return undefined as any
  }
}
