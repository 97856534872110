import {ArrayList, List} from "@damntools.fr/types"
import {
  ScannedFilesystemResource,
  ScannedFilesystemResourceCtor,
  ScannedFilesystemResourceDto
} from "./ScannedFilesystemResource"
import {FileResourceType} from "./FileResourceType";

export interface ScannedFileResourceDto extends ScannedFilesystemResourceDto {
  type: string
  extension: string
  tags: Array<string>
}

export interface ScannedFileResourceCtor extends ScannedFilesystemResourceCtor {
  type: FileResourceType
  extension: string
  tags: List<string>
}

export class ScannedFileResource extends ScannedFilesystemResource {
  type: FileResourceType
  extension: string
  tags: List<string>

  constructor(obj: ScannedFileResourceCtor) {
    super(obj)
    this.type = obj.type
    this.extension = obj.extension
    this.tags = obj.tags || new ArrayList()
  }
}
