import React from "react"
import styles from "./FileTreeView.module.scss"
import {FileTreeViewProps, FileTreeViewState} from "./FileTreeView.types"
import {LibraryResourceConsumer} from "../../../providers"
import {
  Table,
  TableCell,
  TableCellStyle,
  TableScroll,
  TableSelectionConfig,
  TableStyle
} from "@damntools.fr/react-layout"
import {CssClass, NumberUtils, StringUtils} from "@damntools.fr/utils-simple"
import {
  LibraryResource,
  LibraryResourceType,
  LibraryToolbarSearchType
} from "../../../models"
import {ResourceRatingTool} from "../../ResourceRatingTool"
import {compareStringsIgnoreCase, List, Optionable, toList} from "@damntools.fr/types"
import {LibraryResourceFilter} from "../../../services"
import {StyleSize} from "@damntools.fr/react-utils"

export class FileTreeView extends React.Component<FileTreeViewProps, FileTreeViewState> {
  componentDidUpdate(prevProps: Readonly<FileTreeViewProps>) {
    console.log("upda", prevProps)
  }

  render() {
    const cellClasses = CssClass.from(styles.TableCell)
    const headerClasses = cellClasses.with(styles.HeaderCell)
    const toolbar = this.props.toolbar
    return (
      <div className={styles.FileTreeView}>
        <LibraryResourceConsumer>
          {({resources, updateResource, refreshId}) => {
            let filtered = this.filterResourcesByType(resources, toolbar.resourceType)
            filtered = this.filterResourcesFromSearch(
              filtered,
              toolbar.search,
              toolbar.searchType
            )
            return (
              <div>
                <Table
                  data={filtered}
                  refreshId={refreshId}
                  rowIdentifierFn={(row: LibraryResource) => row.id}>
                  <TableSelectionConfig />
                  <TableScroll scrollDownElementAddCount={50} />
                  <TableStyle classNames={cellClasses} headerClassNames={headerClasses} />

                  <TableCell
                    title={"Title"}
                    rowField={"title"}
                    sorted
                    sortFn={(a, b) => compareStringsIgnoreCase(a.title, b.title)}>
                    <TableCellStyle align={"left"} />
                  </TableCell>
                  <TableCell
                    title={"Artists"}
                    valueLoader={(row: LibraryResource) =>
                      row.artists
                        ?.stream()
                        .map(a => a.name)
                        .join(", ")
                    }>
                    <TableCellStyle align={"left"} width={StyleSize.percent(15)} />
                  </TableCell>
                  <TableCell
                    title={"№"}
                    rowField={"albumIndex"}
                    displayFn={row =>
                      row.albumIndex ? NumberUtils.formatNumber(row.albumIndex, 0) : ""
                    }>
                    <TableCellStyle
                      align={"center"}
                      headerAlign={"center"}
                      width={StyleSize.px(40)}
                    />
                  </TableCell>
                  <TableCell
                    sorted
                    title={"Album"}
                    valueLoader={(row: LibraryResource) => row.album?.name}>
                    <TableCellStyle align={"left"} width={StyleSize.percent(15)} />
                  </TableCell>
                  <TableCell
                    title={"Genres"}
                    valueLoader={(row: LibraryResource) =>
                      row.genres
                        ?.stream()
                        .map(a => a.name)
                        .join(", ")
                    }>
                    <TableCellStyle align={"left"} width={StyleSize.px(110)} />
                  </TableCell>
                  <TableCell
                    title={"Tags"}
                    valueLoader={(row: LibraryResource) =>
                      row.tags
                        ?.stream()
                        .map(a => a.name)
                        .join(", ")
                    }>
                    <TableCellStyle align={"left"} width={StyleSize.px(100)} />
                  </TableCell>
                  <TableCell
                    title={"Duration"}
                    rowField={"duration"}
                    sorted
                    displayFn={(row: LibraryResource) => {
                      const format = NumberUtils.formatDurationSeconds(
                        row.duration
                      ).split(":")
                      return (
                        <span className={styles.DurationStrong}>
                          {format[0]}
                          <strong>:</strong>
                          <span>{format[1]}</span>
                          <strong>:</strong>
                          {format[2]}
                        </span>
                      )
                    }}>
                    <TableCellStyle
                      align={"center"}
                      headerAlign={"center"}
                      width={StyleSize.px(70)}
                    />
                  </TableCell>
                  <TableCell title={"Year"} rowField={"year"} sorted filtered>
                    <TableCellStyle
                      align={"center"}
                      headerAlign={"center"}
                      width={StyleSize.px(50)}
                    />
                  </TableCell>
                  <TableCell
                    title={"Size"}
                    sorted
                    displayFn={(row: LibraryResource) => {
                      const format = NumberUtils.formatBytes(
                        row.resource?.size || 0,
                        0
                      ).split(" ")
                      return (
                        <span className={styles.SizeStrong}>
                          <span>{format[0]}</span>
                          <span>{format[1].at(0)}</span>
                          {format[1].at(1)}
                        </span>
                      )
                    }}
                    valueLoader={(row: LibraryResource) =>
                      row.resource?.size || undefined
                    }>
                    <TableCellStyle
                      headerAlign={"center"}
                      align={"center"}
                      width={StyleSize.px(70)}
                    />
                  </TableCell>
                  <TableCell
                    title={"Ext."}
                    sorted
                    filtered
                    valueLoader={(row: LibraryResource) =>
                      row.resource?.extension || undefined
                    }>
                    <TableCellStyle
                      headerAlign={"center"}
                      align={"center"}
                      width={StyleSize.px(40)}
                    />
                  </TableCell>
                  <TableCell
                    title={"Rating"}
                    sorted
                    filtered
                    valueLoader={(row: LibraryResource) => row.rating || undefined}
                    displayFn={r => (
                      <ResourceRatingTool
                        value={r.rating}
                        onChange={v => this.onChangeRating(r, v, updateResource)}
                      />
                    )}>
                    <TableCellStyle
                      align={"center"}
                      headerAlign={"center"}
                      width={StyleSize.px(80)}
                    />
                  </TableCell>
                </Table>
              </div>
            )
          }}
        </LibraryResourceConsumer>
      </div>
    )
  }

  private onChangeRating(
    row: LibraryResource,
    value: Optionable<number>,
    updateResource: (id: number, field: keyof LibraryResource, value: any) => void
  ) {
    updateResource(row.id, "rating", value.orElseUndefined())
  }

  private filterResourcesFromSearch(
    resources: List<LibraryResource>,
    search: Optionable<string>,
    searchType: LibraryToolbarSearchType
  ) {
    if (search.isEmpty() || StringUtils.isEmpty(search.get())) return resources
    const regex = search.map(s => new RegExp(s, "i"))
    return resources
      .stream()
      .filter(r => LibraryResourceFilter.matchSearch(r, regex, searchType))
      .collect(toList)
  }

  private filterResourcesByType(
    resources: List<LibraryResource>,
    type: Optionable<LibraryResourceType>
  ) {
    if (type.isEmpty()) return resources
    return resources
      .stream()
      .filter(r => LibraryResourceFilter.matchType(r, type.get()))
      .collect(toList)
  }
}
