import {ArrayList, List, Optionable, Optional, toList} from "@damntools.fr/types"
import {PlaylistElement} from "../providers"
import {LibraryResource} from "../../library"

export class MediaPlayerPlaylistService {
  private readonly playlist: List<PlaylistElement>

  constructor(playlist: List<PlaylistElement>) {
    this.playlist = playlist || new ArrayList()
  }

  shouldPlay(): boolean {
    return this.getPlaying().isPresent()
  }

  getPlaying(): Optionable<PlaylistElement> {
    return this.playlist.stream().findOptional(e => e.playing)
  }

  playNow(resource: LibraryResource): List<PlaylistElement> {
    return this.playlist
      .copy()
      .stream()
      .peek(e => {
        e.playing = false
        e.paused = false
        e.elapsed = 0
      })
      .collect(toList)
      .push({
        resource: resource,
        elapsed: 0,
        paused: false,
        closed: false,
        playing: true,
        index: this.playlist.size()
      })
  }

  playNext(resource: LibraryResource): List<PlaylistElement> {
    const playing = MediaPlayerPlaylistService.get(this.playlist).getPlaying()
    if (playing.isPresent()) {
      const element = playing.get()
      const playlist = this.playlist.copy()
      if (element.index === playlist.size() - 1) {
        playlist.push({
          resource: resource,
          elapsed: 0,
          paused: false,
          closed: false,
          playing: false,
          index: playing.get().index
        })
      } else {
        playlist.insert(playing.get().index + 1, [
          {
            resource: resource,
            elapsed: 0,
            paused: false,
            closed: false,
            playing: false,
            index: playing.get().index + 1
          }
        ])
      }
      return playlist
        .stream()
        .peek((e, i) => (e.index = i as number))
        .collect(toList)
    } else {
      return this.playLast(resource)
    }
  }

  playLast(resource: LibraryResource): List<PlaylistElement> {
    return this.playlist.copy().push({
      resource: resource,
      elapsed: 0,
      paused: false,
      playing: false,
      closed: false,
      index: this.playlist.size()
    })
  }

  isLast(elem: PlaylistElement) {
    return elem.index === this.playlist.size() - 1
  }

  getNextElement(elem: PlaylistElement): Optionable<PlaylistElement> {
    const index = elem.index + 1
    if (index < this.playlist.size()) {
      return this.playlist.getOptional(index)
    }
    return Optional.empty()
  }

  getPreviousElement(elem: PlaylistElement): Optionable<PlaylistElement> {
    const index = elem.index - 1
    if (index >= 0) {
      return this.playlist.getOptional(index)
    }
    return Optional.empty()
  }

  static get(playlist: List<PlaylistElement>) {
    return new MediaPlayerPlaylistService(playlist)
  }
}
