import {
  BackendService, FileResourceType,
  png_outline_archive_black_18dp,
  png_outline_binary_black_18dp,
  png_outline_binary_image_black_18dp,
  png_outline_book_black_18dp,
  png_outline_code_black_18dp,
  png_outline_description_black_18dp,
  png_outline_image_black_18dp,
  png_outline_movie_black_18dp,
  png_outline_music_video_black_18dp,
  png_outline_subtitle_black_18dp,
  png_outline_torrent_black_18dp,
  png_tag_codec_aac,
  png_tag_codec_ac3,
  png_tag_codec_h264,
  png_tag_codec_h265,
  png_tag_codec_x264,
  png_tag_codec_x265,
  png_tag_def_1080,
  png_tag_def_4k,
  png_tag_def_720,
  png_tag_extended,
  png_tag_lg_fr,
  png_tag_lg_multi,
  png_tag_lg_vo,
  png_tag_lg_vostfr,
  ScannedDirectoryResource, ScannedFileResource,
  ScannedFilesystemResource,
  ScannedResourceDownloadRequest,
  ScannedRootDirectoryResource
} from "../../common"
import {List} from "@damntools.fr/types"
import {ScannedResourceMapper} from "../mappers"
import {AxiosWrapper} from "@damntools.fr/http"
import {LibraryResource, LibraryResourceService} from "../../library"

// @ts-ignore
export const cast = <T>(): ((obj: any) => T) => {
  return (obj: any): T => obj as T
}

export type HrefDownloadInfo = {
  name: string
  url: string
}

export class ExplorerService {
  static INSTANCE: ExplorerService | null = null
  private readonly client: AxiosWrapper

  public static readonly TOO_BIG_ERROR_MESSAGE = "TOO_BIG"

  constructor() {
    this.client = BackendService.authenticatedAxios()
  }

  getRoots(): Promise<List<ScannedRootDirectoryResource>> {
    return this.client
      .get(`/explorer/root`)
      .then(res => res.data)
      .then(resources => ScannedResourceMapper.resourcesFromDto(resources))
      .then(cast<List<ScannedRootDirectoryResource>>())
  }

  deleteResource(resourceId: string): Promise<boolean> {
    const path = ExplorerService.preparePath(resourceId)
    return this.client.delete(`/explorer/?resourceId=${path}`).then(res => !!res.data)
  }

  requestResourceDownload(
    resource: ScannedFilesystemResource
  ): Promise<ScannedResourceDownloadRequest> {
    const path = ExplorerService.preparePath(resource.path)
    return this.client
      .get(`/resource/download/?resourceId=${path}`)
      .catch(err => {
        if (err?.response?.status === 507)
          throw new Error(ExplorerService.TOO_BIG_ERROR_MESSAGE)
        throw err
      })
      .then(res => res.data)
      .then(resources => ScannedResourceMapper.resourceDownloadRequestFromDto(resources))
  }

  getRequestResourceDownload(
    resource: ScannedFilesystemResource
  ): Promise<ScannedResourceDownloadRequest> {
    const path = ExplorerService.preparePath(resource.path)
    return this.client
      .get(`/resource/download/status?resourceId=${path}`)
      .then(res => res.data)
      .then(resources => ScannedResourceMapper.resourceDownloadRequestFromDto(resources))
  }

  getResourceByPath(path: string): Promise<ScannedFilesystemResource> {
    path = ExplorerService.preparePath(path)
    return this.client
      .get(`/explorer/details?resourceId=${path}`)
      .then(res => res.data)
      .then(resources => ScannedResourceMapper.resourceFromDto(resources))
  }

  getDirectoryChildren(
    resource: ScannedDirectoryResource
  ): Promise<List<ScannedFilesystemResource>> {
    const path = ExplorerService.preparePath(resource.path)
    return this.client
      .get(`/explorer/children?resourceId=${path}`)
      .then(res => res.data)
      .then(resources => ScannedResourceMapper.resourcesFromDto(resources))
  }

  static get(): ExplorerService {
    if (this.INSTANCE === null) this.INSTANCE = new ExplorerService()
    return this.INSTANCE
  }

  static getStreamingUrl(path: string) {
    const baseUrl = BackendService.url()
    return `${baseUrl}/resource/download/file/stream?resourceId=${ExplorerService.preparePath(
      path
    )}`
  }

  static getDownloadUrl(
    resource: ScannedFilesystemResource,
    request: ScannedResourceDownloadRequest
  ): HrefDownloadInfo {
    const path = ExplorerService.preparePath(resource.path)
    const baseUrl = BackendService.url()
    return {
      url: `${baseUrl}/resource/download/${request.token}?resourceId=${path}`,
      name: request.filename
    }
  }

  static preparePath(path: string) {
    return encodeURIComponent(
      path
      // .replaceAll(/([\\/]+$)/g, "").replaceAll(/:(\\+)/g, ":")
    )
  }

  static getImageForType(type: string): string {
    switch (type) {
      case "VIDEO":
        return png_outline_movie_black_18dp
      case "MUSIC":
        return png_outline_music_video_black_18dp
      case "BOOK":
        return png_outline_book_black_18dp
      case "SUBTITLE":
        return png_outline_subtitle_black_18dp
      case "PICTURE":
        return png_outline_image_black_18dp
      case "ARCHIVE":
        return png_outline_archive_black_18dp
      case "TORRENT":
        return png_outline_torrent_black_18dp
      case "BINARY":
        return png_outline_binary_black_18dp
      case "BINARY_IMAGE":
        return png_outline_binary_image_black_18dp
      case "CODE":
        return png_outline_code_black_18dp
      default:
        return png_outline_description_black_18dp
    }
  }

  static getIconForTag(tag: string): string {
    switch (tag) {
      case "MULTI":
        return png_tag_lg_multi
      case "VFF":
        return png_tag_lg_fr
      case "VOSTFR":
        return png_tag_lg_vostfr
      case "VOST":
        return png_tag_lg_vo
      case "HD2160":
        return png_tag_def_4k
      case "HD1080":
        return png_tag_def_1080
      case "HD720":
        return png_tag_def_720
      case "X265":
        return png_tag_codec_x265
      case "X264":
        return png_tag_codec_x264
      case "H265":
        return png_tag_codec_h265
      case "H264":
        return png_tag_codec_h264
      case "AC3":
        return png_tag_codec_ac3
      case "AAC":
        return png_tag_codec_aac
      case "EXTENDED":
        return png_tag_extended
      default:
        return ""
    }
  }

  static isStreamable(type: FileResourceType) {
    return (
      type.equals(FileResourceType.MUSIC) ||
      type.equals(FileResourceType.VIDEO)
    )
  }

  static findLibraryResource(resource: ScannedFileResource): Promise<LibraryResource> {
    return this.findLibraryResourcePath(resource.path)
  }

  static findLibraryResourcePath(path: string): Promise<LibraryResource> {
    return LibraryResourceService.get().retrieveByPath(path)
  }
}
