import React from "react"
import styles from "./YoutubeBlock.module.scss"
import {YoutubeLinks} from "../YoutubeLinks";

export class YoutubeBlock extends React.Component<any, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            expandForm: window.location.hash !== "#/youtubedl"
        }
    }

    render() {
        return (
            <div className={styles.YoutubeDLBlock}>
                <YoutubeLinks/>
            </div>
        )
    }
}
