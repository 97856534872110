import React from "react"
import styles from "./SlimAudioPlayer.module.scss"
import "react-h5-audio-player/lib/styles.css"
import {AudioPlayerWrapper} from "../AudioPlayerWrapper"
import {Logger, Logging} from "@damntools.fr/logger-simple"
import {MediaPlayerConsumer} from "../../providers"
import {MediaPlayerPlaylistService} from "../../services"
import {ExplorerService} from "../../../explorer"

export type SlimAudioPLayerProps = any
export type SlimAudioPLayerState = {
  none: string
}

export class SlimAudioPlayer extends React.Component<
  SlimAudioPLayerProps,
  SlimAudioPLayerState
> {
  private readonly logger: Logger

  constructor(props: SlimAudioPLayerProps) {
    super(props)
    this.logger = Logging.getLogger("player.Slim")
    //TODO this could be Functional class
  }

  render() {
    return (
      <MediaPlayerConsumer>
        {({isOpen, playlist, onEnd, onProgress, onPause, onPlay}) => {
          const playlistService = new MediaPlayerPlaylistService(playlist)
          const playing = playlistService.getPlaying()
          return isOpen ? (
            <div className={styles.SlimPlayer}>
              {playing
                .map(elem => (
                  <div className={styles.SlimAudioPLayer}>
                    <AudioPlayerWrapper
                      src={ExplorerService.getStreamingUrl(elem.resource.filename as any)}
                      autoPlay
                      onEnded={() => onEnd()}
                      // @ts-ignore
                      onPlay={e => onPlay(e.target.currentTime)}
                      // @ts-ignore
                      onPause={e => onPause(e.target.currentTime)}
                      // @ts-ignore
                      onListen={e => onProgress(e.target.currentTime)}
                    />
                  </div>
                ))
                .orElseUndefined()}
            </div>
          ) : null
        }}
      </MediaPlayerConsumer>
    )
  }
}
