import {BackendService} from "../../common"

import {AxiosWrapper} from "@damntools.fr/http"
import {List, Lists} from "@damntools.fr/types"
import {LibraryGenreDto} from "../models"

export class LibraryGenreService {
  static INSTANCE: LibraryGenreService | null = null
  private readonly client: AxiosWrapper

  constructor() {
    this.client = BackendService.authenticatedAxios().child({baseURL: "/library/genre"})
  }

  getAllGenres(): Promise<List<LibraryGenreDto>> {
    return this.client
      .get("")
      .then(res => res.data as Array<any>)
      .then(res => Lists.from<LibraryGenreDto>(res))
  }

  static get(): LibraryGenreService {
    if (this.INSTANCE === null) this.INSTANCE = new LibraryGenreService()
    return this.INSTANCE
  }
}
