import {ContextMenu, ContextMenuProvider} from "@damntools.fr/react-layout"
import styles from "./LibraryViewTables.module.scss"
import {CssClass} from "@damntools.fr/utils-simple"
import {openLibraryResourcePropertiesForm} from "../../forms/LibraryResourcePropertiesForm"
import {LibraryResource} from "../../../models"
import {LibraryResourceProviderState} from "../../../providers"

const LIBRARY_VIEW_TABLE_CONTEXT_MENU_FIND_MORE = ContextMenu.right()
  .menuClass(CssClass.from(styles.ContextMenu))
  .separatorClass(CssClass.from(styles.ContextMenuSeparator))
  .itemClass(CssClass.from(styles.ContextMenuItem))
  .addAction({
    display: "... artists",
    onClick: (action, event) => {
      console.log("... artists", action, event)
    }
  })
  .addAction({
    display: "... albums",
    onClick: (action, event) => {
      console.log("... artists", action, event)
    }
  })
  .addAction({
    display: "... genres",
    onClick: (action, event) => {
      console.log("... artists", action, event)
    }
  })
  .addAction({
    display: "... tags",
    onClick: (action, event) => {
      console.log("... artists", action, event)
    }
  })
  .addAction({
    display: "... year",
    onClick: (action, event) => {
      console.log("... artists", action, event)
    }
  })

export const LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE = (
  resource: LibraryResource,
  provider: LibraryResourceProviderState
) =>
  ContextMenu.right()
    .menuClass(CssClass.from(styles.ContextMenu))
    .separatorClass(CssClass.from(styles.ContextMenuSeparator))
    .itemClass(CssClass.from(styles.ContextMenuItem))
    .addAction({
      display: "Play now",
      onClick: (action, event) => {
        console.log("Play now", action, event)
      }
    })
    .addAction({
      display: "Play next",
      onClick: (action, event) => {
        console.log("Play next", action, event)
      }
    })
    .addAction({
      display: "Play last",
      onClick: (action, event) => {
        console.log("Play next", action, event)
      }
    })
    .addSeparator()
    .addAction({
      display: "Edit",
      onClick: (action, event) => {
        console.log("Edit", action, event)
        return openLibraryResourcePropertiesForm({model: resource, provider})
      }
    })
    .addAction({
      classes: CssClass.from(styles.RedItem),
      display: "Delete",
      onClick: (action, event) => {
        console.log("Delete", action, event)
      }
    })
    .addSeparator()
    .addAction({
      classes: CssClass.from(styles.ContextItemWithSub),
      display: "Find more from same ...",
      onClick: (action, event) => {
        console.log("Find more from same ...", action, event)
        ContextMenuProvider.showContextMenuPopup(
          event,
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_FIND_MORE
        )
      }
    })
    .addAction({
      classes: CssClass.from(styles.ContextItemWithSub),
      display: "Find more complex search ...",
      onClick: (action, event) => {
        console.log("Find more complex search ...", action, event)
      }
    })
    .addSeparator()
    .addAction({
      display: "Show in explorer",
      onClick: (action, event) => {
        console.log("Show in explorer", action, event)
      }
    })
