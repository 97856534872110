import {ResourceInfo, ResourceInfoCtor, ResourceInfoDto} from "./ResourceInfo"
import {TrackInfo, TrackInfoDto} from "./TrackInfo"
import {ArrayList, List} from "@damntools.fr/types"

export type PlaylistInfoDto = ResourceInfoDto & {
  tracks: Array<TrackInfoDto>
}

export type PlaylistInfoCtor = ResourceInfoCtor & {
  tracks: List<TrackInfo>
}

export class PlaylistInfo extends ResourceInfo {
  tracks: List<TrackInfo>

  constructor(obj: PlaylistInfoCtor) {
    super(obj)
    this.tracks = obj.tracks || new ArrayList()
  }
}
