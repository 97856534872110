import Keycloak, {KeycloakTokenParsed} from "keycloak-js"
import {Globals} from "@damntools.fr/react-globals"

export class KeycloakService {
  static INSTANCE: KeycloakService | null = null

  private readonly keycloak: Keycloak

  constructor(url?: string, realm?: string, clientId?: string) {
    const initOptions = {
      url: Globals.get<string>("auth.keycloak.url").orElseReturn(url as string),
      realm: Globals.get<string>("auth.keycloak.realm").orElseReturn(realm as string),
      clientId: Globals.get<string>("auth.keycloak.clientId").orElseReturn(
        clientId as string
      )
    }

    this.keycloak = new Keycloak(initOptions)
  }

  init() {
    return this.keycloak.init({
      onLoad: "login-required",
      checkLoginIframe: false
    })
  }

  isAuthenticated(): boolean {
    return !!this.keycloak.authenticated
  }

  isAdmin(): boolean {
    return !!this.getTokenInfo()?.realm_access?.roles.find(
      r => r.toLowerCase() === "admin"
    )
  }

  isVip(): boolean {
    return !!this.getTokenInfo()?.realm_access?.roles.find(r => r.toLowerCase() === "vip")
  }

  getTokenInfo(): KeycloakTokenParsed | undefined {
    return this.keycloak.idTokenParsed
  }

  getUsername(): string {
    return this.getTokenInfo()?.preferred_username
  }

  getToken() {
    return this.keycloak.token
  }

  static get(): KeycloakService {
    if (this.INSTANCE === null) this.INSTANCE = new KeycloakService()
    return this.INSTANCE
  }
}
