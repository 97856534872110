export type WsEventPayload = object

export interface WsEventWrapper<P extends WsEventPayload> {
  type: string
  payload: P
}

export abstract class WsEventHandler<P extends WsEventPayload> {
  accept(e: WsEventWrapper<P>): boolean {
    return e.type === this.getType()
  }

  abstract process(payload: P): void

  protected abstract getType(): string
}
