import {YoutubeResourceType} from "../YoutubeResourceType"
import {YoutubeFormatAudioExt} from "../YoutubeFormatAudioExt"
import {ArrayList, List, Optionable} from "@damntools.fr/types"

export type DownloadRequestDto = {
  type: string
  id: string
  resourceId?: string
  onlyAudio: boolean
  audioFormat?: string
  title?: string
  relativePath?: string
  formats: Array<string>
  playlistElementIds: Array<string>
}
export type DownloadRequestCtor = {
  type: YoutubeResourceType
  id: string
  onlyAudio: Optionable<boolean>
  resourceId?: string
  audioFormat: Optionable<YoutubeFormatAudioExt>
  title: Optionable<string>
  relativePath: Optionable<string>
  formats?: List<string>
  playlistElementIds?: List<string>
}

export class DownloadRequest {
  type: YoutubeResourceType
  id: string
  resourceId?: string
  onlyAudio: Optionable<boolean>
  audioFormat: Optionable<YoutubeFormatAudioExt>
  title: Optionable<string>
  relativePath: Optionable<string>
  formats: List<string>
  playlistElementIds: List<string>

  constructor(obj: DownloadRequestCtor) {
    this.type = obj.type
    this.id = obj.id
    this.onlyAudio = obj.onlyAudio
    this.audioFormat = obj.audioFormat
    this.title = obj.title
    this.relativePath = obj.relativePath
    this.resourceId = obj.resourceId
    this.formats = obj.formats || new ArrayList()
    this.playlistElementIds = obj.playlistElementIds || new ArrayList()
  }
}
