import {CssClass, NumberUtils} from "@damntools.fr/utils-simple"
import styles from "./LibraryViewTables.module.scss"
import {
  Table,
  TableCell,
  TableCellStyle,
  TableScroll,
  TableSelectionConfig,
  TableStyle
} from "@damntools.fr/react-layout"
import {LibraryResource} from "../../../models"
import {compareStringsIgnoreCase} from "@damntools.fr/types"
import {StyleSize} from "@damntools.fr/react-utils"
import {ResourceRatingTool} from "../../ResourceRatingTool"
import React from "react"
import {onChangeRating} from "./LibraryViewTableAudio"
import {BIG_SIZE, LibraryViewTableProps} from "./common.types"
import {LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE} from "./LibraryViewTableContextMenus"

export const LibraryViewTableVideo = ({
  resources,
  provider,
  width,
  setSelected
}: LibraryViewTableProps) => {
  width = width || 0
  const cellClasses = CssClass.from(styles.TableCell)
  const headerClasses = cellClasses.with(styles.HeaderCell)
  return (
    <Table
      data={resources}
      refreshId={provider.refreshId}
      rowIdentifierFn={(row: LibraryResource) => row.id}>
      <TableSelectionConfig onSelected={setSelected} />
      <TableScroll scrollDownElementAddCount={50} />
      <TableStyle classNames={cellClasses} headerClassNames={headerClasses} />

      <TableCell
        title={"Title"}
        rowField={"title"}
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        sorted
        sortFn={(a, b) => compareStringsIgnoreCase(a.title, b.title)}>
        <TableCellStyle align={"left"} />
      </TableCell>
      <TableCell
        title={"Genres"}
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        valueLoader={(row: LibraryResource) =>
          row.genres
            ?.stream()
            .map(a => a.name)
            .join(", ")
        }>
        <TableCellStyle
          align={"left"}
          width={StyleSize.px(width > BIG_SIZE ? 100 : 100)}
        />
      </TableCell>
      <TableCell
        title={"Languages"}
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        valueLoader={(row: LibraryResource) => row.languages?.stream().join(", ")}>
        <TableCellStyle align={"left"} width={StyleSize.px(width > BIG_SIZE ? 80 : 80)} />
      </TableCell>
      <TableCell
        title={"Tags"}
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        valueLoader={(row: LibraryResource) =>
          row.tags
            ?.stream()
            .map(a => a.name)
            .join(", ")
        }>
        <TableCellStyle align={"left"} width={StyleSize.px(width > BIG_SIZE ? 50 : 50)} />
      </TableCell>
      <TableCell
        title={"Codecs"}
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        valueLoader={(row: LibraryResource) => row.codecs?.stream().join(", ")}>
        <TableCellStyle align={"left"} width={StyleSize.px(width > BIG_SIZE ? 60 : 60)} />
      </TableCell>
      <TableCell
        title={"Length"}
        rowField={"duration"}
        blockBrowserContextMenu
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        sorted
        displayFn={(row: LibraryResource) => {
          const format = NumberUtils.formatDurationSeconds(row.duration).split(":")
          return (
            <span className={styles.DurationStrong}>
              {format[0]}
              <strong>:</strong>
              <span>{format[1]}</span>
              <strong>:</strong>
              {format[2]}
            </span>
          )
        }}>
        <TableCellStyle
          align={"center"}
          headerAlign={"center"}
          width={StyleSize.px(width > BIG_SIZE ? 70 : 70)}
        />
      </TableCell>
      <TableCell
        title={"Year"}
        rowField={"year"}
        sorted
        filtered
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }>
        <TableCellStyle
          align={"center"}
          headerAlign={"center"}
          width={StyleSize.px(width > BIG_SIZE ? 50 : 50)}
        />
      </TableCell>
      <TableCell
        title={"Definition"}
        sorted
        filtered
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        displayFn={(row: LibraryResource) => {
          const width = row.width || 0
          const height = row.height || 0
          return (
            <span className={styles.DefinitionStrong}>
              {width && height ? <span>{NumberUtils.formatNumber(width, 0)}</span> : null}
              {width && height ? <strong>x</strong> : null}
              {height ? <span>{NumberUtils.formatNumber(height, 0)}</span> : null}
              {!width && height ? <span>p</span> : null}
            </span>
          )
        }}
        valueLoader={(row: LibraryResource) => row.width || 0}>
        <TableCellStyle
          headerAlign={"right"}
          align={"right"}
          width={StyleSize.px(width > BIG_SIZE ? 80 : 80)}
        />
      </TableCell>
      <TableCell
        title={"Size"}
        sorted
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        displayFn={(row: LibraryResource) => {
          const format = NumberUtils.formatBytes(row.resource?.size || 0, 0).split(" ")
          return (
            <span className={styles.SizeStrong}>
              <span>{format[0]}</span>
              <span>{format[1].at(0)}</span>
              {format[1].at(1)}
            </span>
          )
        }}
        valueLoader={(row: LibraryResource) => row.resource?.size || undefined}>
        <TableCellStyle
          headerAlign={"center"}
          align={"center"}
          width={StyleSize.px(width > BIG_SIZE ? 70 : 70)}
        />
      </TableCell>
      <TableCell
        title={"Ext."}
        sorted
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        filtered
        valueLoader={(row: LibraryResource) => row.resource?.extension || undefined}>
        <TableCellStyle
          headerAlign={"center"}
          align={"center"}
          width={StyleSize.px(width > BIG_SIZE ? 40 : 40)}
        />
      </TableCell>
      <TableCell
        title={"Rating"}
        sorted
        blockBrowserContextMenu
        disableSelectionOnClick
        filtered
        valueLoader={(row: LibraryResource) => row.rating || undefined}
        displayFn={r => (
          <ResourceRatingTool
            value={r.rating}
            onChange={v => onChangeRating(r, v, provider.updateResource)}
          />
        )}>
        <TableCellStyle
          align={"center"}
          headerAlign={"center"}
          width={StyleSize.px(70)}
        />
      </TableCell>
    </Table>
  )
}
