import styles from "./ExplorerBlock.module.scss"
import React from "react"
import {ScannedDirectoryResource, ScannedFileResource} from "../../../common"
import {ExplorerConsumer} from "../../providers"
import {ScannedFileResourceView} from "../ScannedFileResourceView"
import {ScannedDirectoryResourceView} from "../ScannedDirectoryResourceView"
import {MediaPlayerConsumer} from "../../../player"
import {RouteComponentProps} from "react-router"

export type ExplorerUrlConfig = {
  directory?: string
  resource?: string
}

export class ExplorerBlock extends React.Component<RouteComponentProps<any>, any> {
  render() {
    return (
      <MediaPlayerConsumer>
        {({playNow, playNext, playLast}) => (
          <div className={styles.ExplorerBlock}>
            <div>
              <ExplorerConsumer>
                {state => {
                  const resource = state.currentResource
                  if (resource.isPresent()) {
                    if (resource.get() instanceof ScannedFileResource)
                      return <ScannedFileResourceView />
                    else
                      return (
                        <ScannedDirectoryResourceView
                          resource={resource.get() as ScannedDirectoryResource}
                          onDelete={state.onDelete}
                          onPlay={playNow}
                          onPlayNext={playNext}
                          onPlayLast={playLast}
                          children={state.children}
                          selectedResources={state.selectedResources}
                        />
                      )
                  }
                }}
              </ExplorerConsumer>
            </div>
          </div>
        )}
      </MediaPlayerConsumer>
    )
  }
}
