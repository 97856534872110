import {
  ScannedDirectoryResource,
  ScannedDirectoryResourceCtor,
  ScannedDirectoryResourceDto
} from "./ScannedDirectoryResource"
import {DiskInfo, DiskInfoDto} from "./DiskInfo"

export interface ScannedRootDirectoryResourceDto extends ScannedDirectoryResourceDto {
  diskInfo: DiskInfoDto
}

export interface ScannedRootDirectoryResourceCtor extends ScannedDirectoryResourceCtor {
  diskInfo: DiskInfo
}

export class ScannedRootDirectoryResource extends ScannedDirectoryResource {
  diskInfo: DiskInfo

  constructor(obj: ScannedRootDirectoryResourceCtor) {
    super(obj)
    this.diskInfo = obj.diskInfo
  }
}
