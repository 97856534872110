import React from "react"
import styles from "./YoutubeTrackDownloadEntry.module.scss"
import prettyBytes from "pretty-bytes"
import {TimeUtils} from "@damntools.fr/utils-simple"

import {
  png_outline_mixed_black_18dp,
  png_outline_open_in_new_black_18dp,
  ProgressBar
} from "../../../common"
import {TrackDownloadExec} from "../../models"
import {ExplorerService} from "../../../explorer"
import {AlertProvider, Notification} from "@damntools.fr/react-alert"

export type YoutubeDownloadEntryProps = {
  info: TrackDownloadExec
  showIcon: boolean
}

export class YoutubeTrackDownloadEntry extends React.Component<
  YoutubeDownloadEntryProps,
  any
> {
  render() {
    const info = this.props.info
    return (
      <div className={styles.YoutubeDLDownloadEntry}>
        <div>
          <span></span>
          {this.props.showIcon ? (
            <img src={png_outline_mixed_black_18dp} alt={"Music"} />
          ) : null}
        </div>
        <div>
          <div className={styles.Title}>
            <span>{info.title}</span>
          </div>
          <div className={styles.Info}>
            <div className={styles.DlInfo}>
              <span>{info.request.resourceId}</span>
              <span className={styles.Tiret}>-</span>
              <span>{TimeUtils.secondsToDuration(info.resourceDuration)}</span>
              {info.progression !== 100 ? (
                <span>
                  <span className={styles.Tiret}>-</span>
                  <strong>ETA :</strong> {TimeUtils.secondsToDuration(info.eta)}
                </span>
              ) : null}
              {info.progression !== 100 ? (
                <span>
                  <span className={styles.Tiret}>-</span>
                  <strong>Speed :</strong> {prettyBytes(info.rate || 0.0)}/s
                </span>
              ) : null}
              {info.totalSize ? (
                <span>
                  <span className={styles.Tiret}>-</span>
                  <strong>Size :</strong> {prettyBytes(info.totalSize || 0.0)}
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div>
          <div className={styles.Buttons}>
            {info.progression === 100.0 ? (
              <span onClick={() => this.onOpenEntry(info)}>
                <span></span>
                <img
                  src={png_outline_open_in_new_black_18dp}
                  alt={"Open in explorer"}
                  title={"Open in explorer"}
                />
              </span>
            ) : null}
          </div>
        </div>
        <div>
          <div className={styles.ProgressBar}>
            <ProgressBar value={info.progression} displayText />
          </div>
        </div>
      </div>
    )
  }

  onOpenEntry(info: TrackDownloadExec) {
    ExplorerService.get()
      .getResourceByPath(info.currentFile)
      .then(res => ExplorerService.get().getResourceByPath(res.parent))
      .then(res => {
        window.location.href = `#/explorer/dir/${encodeURIComponent(res.path)}`
      })
      .catch(() =>
        AlertProvider.submit(
          Notification.error("Explorer can not find file !").Timeout(5000)
        )
      )
    // TODO
  }
}
