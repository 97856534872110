import prettyBytes from "pretty-bytes"

export interface ScannedFilesystemResourceDto {
  path: string
  size: number
  title: string
  parent: string
}

export interface ScannedFilesystemResourceCtor {
  path: string
  size: number
  title: string
  parent: string
}

export abstract class ScannedFilesystemResource {
  path: string
  size: number
  title: string
  parent: string

  protected constructor(obj: ScannedFilesystemResourceCtor) {
    this.path = obj.path
    this.size = obj.size
    this.title = obj.title
    this.parent = obj.parent
  }

  getCleanSize(): string {
    return prettyBytes(this.size)
  }
}
