import React from "react"
import styles from "./YoutubeTrackDownloadForm.module.scss"

import {
  AutoCompleteInput,
  CheckboxInput,
  ChoiceSelector,
  TextInput,
  ValueDesc,
  VD
} from "@damntools.fr/react-inputs"
import {
  DownloadPreset,
  TrackInfo,
  YoutubeFormatAudioExt,
  YoutubeResourceType
} from "../../models"
import {
  FormatCodeSelector,
  PresetSelector,
  YoutubeUrlSelector
} from "../selector"
import {AlertProvider, Notification} from "@damntools.fr/react-alert"
import {Collectors, List, Optionable, toList} from "@damntools.fr/types"
import {YoutubeTrackDownloadFormConsumer} from "../../providers"
import {TimeUtils} from "@damntools.fr/utils-simple"
import {ContentBlock} from "@damntools.fr/react-layout"
import {gif_loading, png_invalid, png_valid} from "../../../common"
import {StyleSize} from "@damntools.fr/react-utils"

const onChangeRelativePath = (
  value: Optionable<ValueDesc<string>>,
  setRelativePath: (value: Optionable<string>) => void
) => {
  setRelativePath(value.map(v => v.returnValue))
}

const getRelativePathValues = (subDirectories: List<string>) => {
  return subDirectories.stream().map(VD).collect(toList)
}

export const YoutubeTrackDownloadForm = () => {
  return (
    <YoutubeTrackDownloadFormConsumer>
      {context => {
        const relativePathValues = getRelativePathValues(context.subDirectories)
        return (
          <div className={styles.YoutubeTrackDownloadForm}>
            <div className={styles.Form}>
              <div className={styles.Upper}>
                <div className={styles.UpperLeft}>
                  <ContentBlock
                    title={"Resource"}
                    titleIcon={getIcon(context.validation)}
                    iconSize={"20px"}
                    className={styles.ResourceZone}>
                    <YoutubeUrlSelector
                      id={context._form.id}
                      onChangeId={context.setId}
                      type={YoutubeResourceType.TRACK}
                    />
                  </ContentBlock>

                  <ContentBlock title={"Override title"}>
                    <TextInput
                      dark
                      placeholder={"..."}
                      hideFormat
                      value={context._form.title.map(VD)}
                      onChange={context.setTitle}
                    />
                  </ContentBlock>

                  <ContentBlock title={"Relative path"}>
                    <AutoCompleteInput
                      dark
                      maxItems={4}
                      search={context._form.relativePath}
                      placeholder={"sub/path"}
                      values={relativePathValues}
                      onChange={value =>
                        onChangeRelativePath(value, context.setRelativePath)
                      }
                    />
                  </ContentBlock>
                </div>
                <div className={styles.UpperRight}>
                  <div className={styles.TrackInfoZone}>
                    {context.info
                      .filter(i => i instanceof TrackInfo)
                      .map(i => getTrackElement(i as TrackInfo))
                      .orElseUndefined()}
                  </div>
                </div>
              </div>
              <div className={styles.Bottom}>
                <ContentBlock
                  title={`Presets (${DownloadPreset.all().size()})`}
                  canToggle>
                  <PresetSelector
                    value={context._form.preset}
                    onChange={context.setPreset}
                  />
                </ContentBlock>

                <ContentBlock title={"Audio"}>
                  <div>
                    <div className={styles.ConvertToAudio}>
                      <CheckboxInput
                        checked={context._form.convertToAudio.orElseReturn(
                          false
                        )}
                        dark
                        color={"#678a23"}
                        size={StyleSize.px(18)}
                        onChange={() =>
                          context.setConvertToAudio(
                            context._form.convertToAudio.map(c => !c)
                          )
                        }
                      />
                      <label>Convert to audio</label>
                    </div>
                    {context._form.convertToAudio.filter(b => b).isPresent() ? (
                      <ContentBlock title={"Audio format"}>
                        <ChoiceSelector
                          dark
                          selectedValues={context._form.audioFormat.map(VD).toStream().collect(toList)}
                          values={getAudioFormatValues()}
                          onChange={context.setAudioFormat}
                        />
                      </ContentBlock>
                    ) : null}
                  </div>
                </ContentBlock>
                {context.resourceFormats.size() > 0 ? (
                  <ContentBlock
                    title={`Formats (${context.resourceFormats.size()} / ${context._form.formatCodes.size()} selected)`}
                    contentClassName={styles.Formats}
                    canToggle>
                    <FormatCodeSelector
                      selectedValues={context._form.formatCodes
                        .stream()
                        .map(f =>
                          context.resourceFormats
                            .stream()
                            .find(rf => rf.code === f)
                        )
                        .filterPresent()
                        .collect(Collectors.toList)}
                      values={context.resourceFormats}
                      onChange={context.setFormatCodes}
                    />
                  </ContentBlock>
                ) : null}
                <div className={styles.Buttons}>
                  <span onClick={() => onSave(context.sendRequest)}>
                    Download !
                  </span>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </YoutubeTrackDownloadFormConsumer>
  )
}

const onSave = (storeFn: () => Promise<string>) => {
  storeFn()
    .then(downloadId => {
      AlertProvider.submit(
        Notification.success("Download requested !")
          .Subtitle(`Id : ${downloadId}`)
          .Timeout(5000)
      )
    })
    .catch(err => {
      console.log("Error while requesting downloads", err)
      AlertProvider.submit(
        Notification.error().Subtitle("Error while requesting downloads")
      )
    })
}

const getAudioFormatValues = () => {
  return [
    ValueDesc.returns(YoutubeFormatAudioExt.MP3).Display("MP3 (worst)"),
    ValueDesc.returns(YoutubeFormatAudioExt.FLAC).Display("FLAC (best)")
  ]
}

const getTrackElement = (info: TrackInfo): JSX.Element => {
  return (
    <div key={"FormTrackInfo" + info.id} className={styles.TrackInfoEntry}>
      <h1>
        <img src={info.thumbnail?.url} width={"200px"} alt={"thumbnail"}/>
      </h1>
      <h1 className={styles.Title}>
        <a target={"_blank"} href={`https://youtube.com/watch?v=${info.id}`}>
          {info.title}
        </a>
      </h1>
      {info.uploader ? (
        <div className={styles.Uploader}>
          <span>Uploader</span>
          <span>{info.uploader}</span>
          {info.uploaderId && (
            <span>
              (
              <a
                target={"_blank"}
                href={`https://youtube.com/${info.uploaderId}`}>
                {info.uploaderId}
              </a>
              )
            </span>
          )}
        </div>
      ) : null}
      <div className={styles.Duration}>
        <span>Duration</span>
        <span>{TimeUtils.secondsToDuration(info.duration)}</span>
      </div>
    </div>
  )
}

const getIcon = (validation: Optionable<string>): string | undefined => {
  if (validation.isPresent()) {
    if (validation.filter(v => v === "loading").isPresent()) return gif_loading
    else if (validation.filter(v => v === "valid").isPresent()) return png_valid
    else if (validation.filter(v => v === "invalid").isPresent())
      return png_invalid
  }
}
