import React from "react"
import {
  FlatResourceDto,
  LibraryAlbumDto,
  LibraryArtistDto,
  LibraryGenreDto,
  LibraryResource,
  LibraryTagDto
} from "../../models"
import {List, Lists, toList} from "@damntools.fr/types"
import {
  LibraryArtistService,
  LibraryGenreService,
  LibraryResourceService,
  LibraryTagService
} from "../../services"
import {LibraryAlbumService} from "../../services/LibraryAlbumService"
import {TimeUtils} from "@damntools.fr/utils-simple"
import {UUID} from "@damntools.fr/identifiers"
import {Logger, Logging} from "@damntools.fr/logger-simple"

export const LibraryResourceContext = React.createContext(
  {} as LibraryResourceProviderState
)

export const LibraryResourceConsumer = LibraryResourceContext.Consumer

export type LibraryResourceProviderState = {
  refresh: () => void
  refreshId: string
  updateResource: (id: number, field: keyof LibraryResource, value: any) => void
  resources: List<LibraryResource>
  tags: List<LibraryTagDto>
  albums: List<LibraryAlbumDto>
  genres: List<LibraryGenreDto>
  artists: List<LibraryArtistDto>
}

export class LibraryResourceProvider extends React.Component<
  any,
  LibraryResourceProviderState
> {
  private static INSTANCE: LibraryResourceProvider | null = null

  state: LibraryResourceProviderState = {
    refresh: () => {
      return this.prepareData()
    },
    updateResource: (id: number, field: keyof LibraryResource, value: any) => {
      return this.updateResource(id, field, value)
    },
    refreshId: UUID.random(),
    resources: Lists.empty(),
    tags: Lists.empty(),
    genres: Lists.empty(),
    albums: Lists.empty(),
    artists: Lists.empty()
  }
  private readonly logger: Logger

  constructor(props: any) {
    super(props)
    this.logger = Logging.getLogger("LibraryResourceProvider")
    LibraryResourceProvider.INSTANCE = this
  }

  static refresh() {
    if (this.INSTANCE) this.INSTANCE.state.refresh()
  }

  componentDidMount() {
    void this.prepareData()
  }

  updateResource(id: number, field: keyof LibraryResource, value: any) {
    const resources = this.state.resources.copy()
    const found = resources.stream().find(r => r.id === id)
    if (found) {
      // @ts-ignore
      found[field] = value
      this.setState({resources, refreshId: UUID.random()})
    }
  }

  prepareData() {
    const start = TimeUtils.now()
    return Promise.all([
      LibraryResourceService.get().getAllFlatResources(),
      LibraryTagService.get().getAllTags(),
      LibraryGenreService.get().getAllGenres(),
      LibraryArtistService.get().getAllArtists(),
      LibraryAlbumService.get().getAllAlbums()
    ]).then(results => {
      const dtos = results[0] as List<FlatResourceDto>
      const tags = results[1] as List<LibraryTagDto>
      const genres = results[2] as List<LibraryGenreDto>
      const artists = results[3] as List<LibraryArtistDto>
      const albums = results[4] as List<LibraryAlbumDto>
      const resources = dtos
        .stream()
        .map(dto =>
          LibraryResourceService.completeDtoWithRelations(
            dto,
            tags,
            genres,
            artists,
            albums
          )
        )
        .collect(toList)
      return new Promise(resolve => {
        this.setState(
          {
            resources,
            tags,
            artists,
            albums,
            genres,
            refreshId: UUID.random()
          },
          () => {
            this.logger.debug(
              `Refreshed ${resources.size()} library resources in ${
                TimeUtils.now() - start
              }ms`
            )
            resolve(undefined)
          }
        )
      })
    })
  }

  render() {
    return (
      <LibraryResourceContext.Provider value={this.state}>
        {this.props.children}
      </LibraryResourceContext.Provider>
    )
  }
}
