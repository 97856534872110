import {Enum} from "@damntools.fr/types"

export class FileResourceType extends Enum<string> {
  static VIDEO = new FileResourceType("VIDEO")
  static BOOK = new FileResourceType("BOOK")
  static MUSIC = new FileResourceType("MUSIC")
  static GAME = new FileResourceType("GAME")
  static OTHER = new FileResourceType("OTHER")
  static SUBTITLE = new FileResourceType("SUBTITLE")
  static PICTURE = new FileResourceType("PICTURE")
  static ARCHIVE = new FileResourceType("ARCHIVE")
  static TORRENT = new FileResourceType("TORRENT")
  static BINARY = new FileResourceType("BINARY")
  static BINARY_IMAGE = new FileResourceType("BINARY_IMAGE")
  static CODE = new FileResourceType("CODE")
}
