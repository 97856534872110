import {LibraryResource} from "../../../models"
import React from "react"
import styles from "./LibraryResourceInfoZone.module.scss"
import {NumberUtils} from "@damntools.fr/utils-simple"


export type ResourceInfoViewProps = {
  resource: LibraryResource
}
export const ResourceInfoView = ({resource}: ResourceInfoViewProps): React.ReactElement => {
  return (
    <div className={styles.InfoSelectionZone}>
      <div className={styles.InfoWrapper}>
        <div className={styles.InfoCover}>
            <span>
              <img
                src={
                  "https://cdns-images.dzcdn.net/images/cover/40c512924fb46d48f74229ce20fa83e2/264x264.jpg"
                }
                alt={""}
              />
            </span>
        </div>
        <div className={styles.InfoTitle}>
          <span>{resource.title}</span>
        </div>
        <div className={styles.InfoLeft}>
          <div>
              <span>
                {resource.artists
                  .stream()
                  .map(t => t.name)
                  .join(", ")}
              </span>
          </div>
          {resource.album ? <div><span>{resource.album.name}</span></div> : null}
          {resource.year ? <div><span>{resource.year}</span></div> : null}
          <div>
              <span>
                {resource.genres
                  .stream()
                  .map(t => t.name)
                  .join(", ")}
              </span>
          </div>
          <div>
              <span>
                {resource.languages
                  .stream()
                  .join(", ")}
              </span>
          </div>
          <div>
              <span>
                {resource.codecs
                  .stream()
                  .join(", ")}
              </span>
          </div>
        </div>
        <div className={styles.InfoRight}>
          <span>{NumberUtils.formatDurationSeconds(resource.duration)}</span>
          <div>
              <span>
                {resource.tags
                  .stream()
                  .map(t => t.name)
                  .join(", ")}
              </span>
          </div>
        </div>
      </div>
    </div>
  )
}
