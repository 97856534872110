import entryStyles from "../Entry.module.scss"
import React from "react"
import {png_outline_folder_open_black_18dp} from "../../../common"

export type ExplorerPreviousDirectoryProps = {
  onClick: () => void
}

export class ExplorerPreviousDirectory extends React.Component<
  ExplorerPreviousDirectoryProps,
  any
> {
  render() {
    return (
      <tr
        className={entryStyles.ResourceEntry}
        style={{background: "rgba(0, 0, 0, 0.1)"}}>
        <td className={entryStyles.Checkbox}></td>
        <td className={entryStyles.Icon}>
          <img
            src={png_outline_folder_open_black_18dp}
            alt="Parent directory"
            title={"Parent directory"}
          />
        </td>
        <td
          className={entryStyles.Title}
          style={{padding: "2px 5px"}}
          onClick={() => this.props.onClick()}
          title={"Parent directory"}>
          <span>..</span>
        </td>
        <td className={entryStyles.Size}></td>
        <td className={entryStyles.Count}></td>
        <td className={entryStyles.TagIcons}></td>
        <td className={entryStyles.ActionIcons}></td>
        <td></td>
      </tr>
    )
  }
}
