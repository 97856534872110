import React from "react"
import {LibrarySelectionConsumer, LibraryToolbarConsumer} from "../../providers"
import {LibraryNavigationViewType} from "../../models"
import {AllListedView} from "./AllListedView"
import {FileTreeView} from "./FileTreeView"

export const LibraryViewSelector = (props: {width: number}) => {
  return (
    <LibraryToolbarConsumer>
      {toolbar => {
        return (
          <LibrarySelectionConsumer>
            {selection => {
              if (LibraryNavigationViewType.GENRE_LIST.equals(toolbar.navigationViewType))
                return (
                  <AllListedView
                    width={props.width}
                    toolbar={toolbar}
                    selection={selection}
                  />
                )
              else if (
                LibraryNavigationViewType.FILE_TREE.equals(toolbar.navigationViewType)
              )
                return <FileTreeView toolbar={toolbar} />
              else if (
                LibraryNavigationViewType.ALBUM_LIST.equals(toolbar.navigationViewType)
              )
                return (
                  <AllListedView
                    width={props.width}
                    toolbar={toolbar}
                    selection={selection}
                  />
                )
              else if (
                LibraryNavigationViewType.CASCADE.equals(toolbar.navigationViewType)
              )
                return (
                  <AllListedView
                    width={props.width}
                    toolbar={toolbar}
                    selection={selection}
                  />
                )
              else if (
                LibraryNavigationViewType.RESOURCE_LIST.equals(toolbar.navigationViewType)
              ) {
                return (
                  <AllListedView
                    width={props.width}
                    toolbar={toolbar}
                    selection={selection}
                  />
                )
              }
            }}
          </LibrarySelectionConsumer>
        )
      }}
    </LibraryToolbarConsumer>
  )
}
