import React from "react"
import styles from "./LibraryMainToolbar.module.scss"
import {LibraryToolbarConsumer} from "../../providers"
import {FlexRowBlock, GrowFlexCell, ShrinkFlexCell} from "@damntools.fr/react-layout"
import {TextInput, VD} from "@damntools.fr/react-inputs"
import {LibraryToolbarSearchType} from "../../models"
import {
  png_album,
  png_artist,
  png_asterisk,
  png_genre,
  png_tag,
  png_time,
  png_title
} from "../../../common"
import {StringUtils} from "@damntools.fr/utils-simple"
import {LibraryResourceService} from "../../services"

export type LibraryMainToolbarProps = any
export type LibraryMainToolbarState = any

export class LibraryMainToolbar extends React.Component<
  LibraryMainToolbarProps,
  LibraryMainToolbarState
> {
  constructor(props: LibraryMainToolbarProps) {
    super(props)
  }

  render() {
    return (
      <div className={styles.LibraryMainToolbar}>
        <LibraryToolbarConsumer>
          {({search, setSearch, searchType, setSearchType}) => {
            return (
              <FlexRowBlock>
                <GrowFlexCell>
                  <div>File | Properties | About | </div>
                  <div><a onClick={() => LibraryResourceService.get().runResetAndFullScan()}>Scan</a> </div>
                </GrowFlexCell>
                <ShrinkFlexCell>
                  <div>
                    <FlexRowBlock>
                      <GrowFlexCell className={styles.SearchInputZone}>
                        <TextInput
                          onChange={setSearch}
                          value={search.map(VD)}
                          dark
                          manageDisplayValueOutside
                          placeholder={`Search ${searchType.key()} ...`}
                          hideFormat
                        />
                      </GrowFlexCell>
                      <ShrinkFlexCell width={"20px"}>
                        {this.getSearchTypeSelector(searchType, setSearchType)}
                      </ShrinkFlexCell>
                    </FlexRowBlock>
                  </div>
                </ShrinkFlexCell>
              </FlexRowBlock>
            )
          }}
        </LibraryToolbarConsumer>
      </div>
    )
  }

  private getSearchTypeSelector(
    type: LibraryToolbarSearchType,
    setSearchType: (type: LibraryToolbarSearchType) => void
  ) {
    return (
      <div
        className={styles.TypeSelector}
        onClick={() => this.setSearchType(type, setSearchType)}>
        <img
          src={this.getSearchTypeSelectorIcon(type)}
          alt={"Search Type"}
          title={StringUtils.firstToUpper(type.key())}
        />
      </div>
    )
  }

  private getSearchTypeSelectorIcon(type: LibraryToolbarSearchType) {
    if (type.equals(LibraryToolbarSearchType.ALL)) {
      return png_asterisk
    } else if (type.equals(LibraryToolbarSearchType.ARTIST)) {
      return png_artist
    } else if (type.equals(LibraryToolbarSearchType.ALBUM)) {
      return png_album
    } else if (type.equals(LibraryToolbarSearchType.TITLE)) {
      return png_title
    } else if (type.equals(LibraryToolbarSearchType.TAG)) {
      return png_tag
    } else if (type.equals(LibraryToolbarSearchType.GENRE)) {
      return png_genre
    } else if (type.equals(LibraryToolbarSearchType.YEAR)) {
      return png_time
    }
  }

  private setSearchType(
    current: LibraryToolbarSearchType,
    setSearchType: (type: LibraryToolbarSearchType) => void
  ) {
    const currentIndex = LibraryToolbarSearchType.all()
      .stream()
      .findIndex(t => t.equals(current))
    const nextIndex = currentIndex + 1
    if (nextIndex < LibraryToolbarSearchType.all().size())
      setSearchType(
        LibraryToolbarSearchType.all().get(nextIndex) as LibraryToolbarSearchType
      )
    else setSearchType(LibraryToolbarSearchType.all().get(0) as LibraryToolbarSearchType)
  }
}
