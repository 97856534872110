import {Enum} from "@damntools.fr/types"
import {
  png_outline_movie_black_18dp,
  png_outline_music_video_black_18dp
} from "../../common"

export class LibraryResourceType extends Enum<string> {
  static MUSIC = new LibraryResourceType("music", png_outline_music_video_black_18dp)
  static VIDEO = new LibraryResourceType("video", png_outline_movie_black_18dp)
  private readonly _icon: string

  constructor(key: string, icon: string) {
    super(key)
    this._icon = icon
  }

  icon(): string {
    return this._icon
  }
}
