import {Optionable} from "@damntools.fr/types"
import {LibraryResource, LibraryResourceType, LibraryToolbarSearchType} from "../models"
import {StringUtils} from "@damntools.fr/utils-simple"

export class LibraryResourceFilter {
  static matchType(resource: LibraryResource, type: LibraryResourceType): boolean {
    if (resource.resource?.type) {
      return type.key() === resource.resource.type.toLowerCase()
    }
    return false
  }

  static matchSearch(
    resource: LibraryResource,
    search: Optionable<RegExp>,
    searchType: LibraryToolbarSearchType
  ): boolean {
    if (search.isEmpty()) return true
    if (searchType.equals(LibraryToolbarSearchType.ALL))
      return this.matchSearchInAll(resource, search.get())
    else if (searchType.equals(LibraryToolbarSearchType.GENRE))
      return this.matchSearchInGenre(resource, search.get())
    else if (searchType.equals(LibraryToolbarSearchType.ARTIST))
      return this.matchSearchInArtist(resource, search.get())
    else if (searchType.equals(LibraryToolbarSearchType.ALBUM))
      return this.matchSearchInAlbum(resource, search.get())
    else if (searchType.equals(LibraryToolbarSearchType.TAG))
      return this.matchSearchInTag(resource, search.get())
    else if (searchType.equals(LibraryToolbarSearchType.TITLE))
      return this.matchSearchInTitle(resource, search.get())
    else if (searchType.equals(LibraryToolbarSearchType.YEAR))
      return this.matchSearchInYear(resource, search.get())
    return false
  }

  static matchSearchInAll(resource: LibraryResource, search: RegExp) {
    return (
      this.matchSearchInTitle(resource, search) ||
      this.matchSearchInArtist(resource, search) ||
      this.matchSearchInAlbum(resource, search) ||
      this.matchSearchInTag(resource, search) ||
      this.matchSearchInGenre(resource, search) ||
      this.matchSearchInYear(resource, search)
    )
  }

  private static matchSearchInGenre(resource: LibraryResource, search: RegExp) {
    if (resource.genres.isEmpty()) return false
    return resource.genres.stream().some(g => search.test(g.name))
  }

  private static matchSearchInTitle(resource: LibraryResource, search: RegExp) {
    if (StringUtils.isEmpty(resource.title)) return false
    return search.test(resource.title)
  }

  private static matchSearchInAlbum(resource: LibraryResource, search: RegExp) {
    if (!resource.album) return false
    return search.test(resource.album.name)
  }

  private static matchSearchInArtist(resource: LibraryResource, search: RegExp) {
    if (resource.artists.isEmpty()) return false
    return resource.artists.stream().some(a => search.test(a.name))
  }

  private static matchSearchInTag(resource: LibraryResource, search: RegExp) {
    if (resource.tags.isEmpty()) return false
    return resource.tags.stream().some(t => search.test(t.name))
  }

  private static matchSearchInYear(resource: LibraryResource, search: RegExp) {
    if (!resource.year) return false
    return search.test(resource.year + "")
  }
}
