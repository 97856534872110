import React from "react"
import styles from "./MediaPlayer.module.scss"
import {Logger, Logging} from "@damntools.fr/logger-simple"
import {MediaPlayerPlaylistService} from "../../services"
import {
  MediaPlayerConsumer,
  MediaPlayerProviderState,
  PlaylistElement
} from "../../providers"
import {Optionable} from "@damntools.fr/types"
import WaveSurfer from "wavesurfer.js"

export type MediaPlayerProps = {
  onPlay?: (e: React.SyntheticEvent<HTMLAudioElement>) => void
  onPause?: (e: React.SyntheticEvent<HTMLAudioElement>) => void
  onListen?: (e: React.SyntheticEvent<HTMLAudioElement>) => void
  onEnded?: (e: React.SyntheticEvent<HTMLAudioElement>) => void
  onSeeking?: (e: React.SyntheticEvent<HTMLAudioElement>) => void
  onSeeked?: (e: React.SyntheticEvent<HTMLAudioElement>) => void
  onAbort?: (e: React.SyntheticEvent<HTMLAudioElement>) => void
  onError?: (e: React.SyntheticEvent<HTMLAudioElement>) => void
  onLoadStart?: (e: React.SyntheticEvent<HTMLAudioElement>) => void
  onLoadedMetaData?: (e: React.SyntheticEvent<HTMLAudioElement>) => void
  onLoadedData?: (e: React.SyntheticEvent<HTMLAudioElement>) => void
  onPlaying?: (e: React.SyntheticEvent<HTMLAudioElement>) => void
  onSuspend?: (e: React.SyntheticEvent<HTMLAudioElement>) => void
  onWaiting?: (e: React.SyntheticEvent<HTMLAudioElement>) => void
  onVolumeChange?: (e: React.SyntheticEvent<HTMLAudioElement>) => void
}
export type MediaPlayerState = object

export abstract class MediaPlayer extends React.Component<
  MediaPlayerProps,
  MediaPlayerState
> {
  protected readonly logger: Logger
  protected wavesurfer: WaveSurfer | null = null

  protected constructor(props: MediaPlayerProps) {
    super(props)
    this.logger = Logging.getLogger("player.MediaPlayer")
  }

  abstract getPlayer(
    provider: MediaPlayerProviderState,
    current: Optionable<PlaylistElement>
  ): React.ReactElement

  render() {
    const alwaysOpen = false
    return (
      <MediaPlayerConsumer>
        {provider => {
          const playlistService = new MediaPlayerPlaylistService(provider.playlist)
          const current = playlistService.getPlaying()
          return provider.isOpen || alwaysOpen ? (
            <div className={styles.AudioPlayer}>
              <div>{this.getPlayer(provider, current)}</div>
            </div>
          ) : null
        }}
      </MediaPlayerConsumer>
    )
  }
}
