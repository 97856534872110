import React from "react"
import {KeycloakService, LocalStorageManager} from "../services"

export type AuthProviderState = {
  isAuthenticated: () => boolean
  isAdmin: () => boolean
  isVip: () => boolean
  getUsername: () => string
}

export const AuthContext = React.createContext({} as AuthProviderState)

export const AuthConsumer = AuthContext.Consumer

export class AuthProvider extends React.Component<any, AuthProviderState> {
  state: AuthProviderState = {
    isVip(): boolean {
      return KeycloakService.get().isVip()
    },
    getUsername(): string {
      return KeycloakService.get().getUsername()
    },
    isAdmin(): boolean {
      return KeycloakService.get().isAdmin()
    },
    isAuthenticated(): boolean {
      return KeycloakService.get().isAuthenticated()
    }
  }

  constructor(props: any) {
    super(props)
    void LocalStorageManager.setAuthentication(KeycloakService.get().getToken() as string)
  }


  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

export type WithRightsProps = {
  children: JSX.Element
}

export const WithAdminRights = ({children}: WithRightsProps) => {
  return (
    <AuthConsumer>{({isAdmin}) => (isAdmin() ? children : null)}</AuthConsumer>
  )
}

export const WithVipRights = ({children}: WithRightsProps) => {
  return <AuthConsumer>{({isVip}) => (isVip() ? children : null)}</AuthConsumer>
}

export const WithElevatedRights = ({children}: WithRightsProps) => {
  return (
    <AuthConsumer>
      {({isVip, isAdmin}) => (isVip() || isAdmin() ? children : null)}
    </AuthConsumer>
  )
}
