import {BackendService} from "../../common"

import {AxiosWrapper} from "@damntools.fr/http"
import {List, Lists} from "@damntools.fr/types"
import {LibraryAlbumDto} from "../models"

export class LibraryAlbumService {
  static INSTANCE: LibraryAlbumService | null = null
  private readonly client: AxiosWrapper

  constructor() {
    this.client = BackendService.authenticatedAxios().child({baseURL: "/library/album"})
  }

  getAllAlbums(): Promise<List<LibraryAlbumDto>> {
    return this.client
      .get("")
      .then(res => res.data as Array<LibraryAlbumDto>)
      .then(res => Lists.from(res))
  }

  static get(): LibraryAlbumService {
    if (this.INSTANCE === null) this.INSTANCE = new LibraryAlbumService()
    return this.INSTANCE
  }
}
