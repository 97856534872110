import {DownloadExec, DownloadExecCtor, DownloadExecDto} from "./DownloadExec"
import {List} from "@damntools.fr/types"

export type TrackDownloadExecCtor = DownloadExecCtor & {
  currentFile: string
  finalFile: string
  downloadedFormats: List<string>
  totalSize: number
  duration: number
  rate: number
  eta: number
  step: number
  stepCount: number
  stepName: string
  progression: number
  playlistId: string | undefined
  viewCount: number
}

export type TrackDownloadExecDto = DownloadExecDto & {
  currentFile: string
  finalFile: string
  downloadedFormats: string[]
  totalSize: number
  duration: number
  rate: number
  eta: number
  step: number
  stepCount: number
  stepName: string
  progression: number
  playlistId: string | undefined
  viewCount: number
}

export class TrackDownloadExec extends DownloadExec {
  currentFile: string
  finalFile: string
  downloadedFormats: List<string>
  totalSize: number
  duration: number
  rate: number
  eta: number
  step: number
  stepCount: number
  stepName: string
  progression: number
  playlistId: string | undefined
  viewCount: number

  constructor(obj: TrackDownloadExecCtor) {
    super(obj)
    this.currentFile = obj.currentFile
    this.finalFile = obj.finalFile
    this.downloadedFormats = obj.downloadedFormats
    this.totalSize = obj.totalSize
    this.duration = obj.duration
    this.rate = obj.rate
    this.eta = obj.eta
    this.step = obj.step
    this.stepCount = obj.stepCount
    this.stepName = obj.stepName
    this.progression = obj.progression
    this.playlistId = obj.playlistId
    this.viewCount = obj.viewCount
  }
}
