import React from "react"
import {ReactState, ReactStateAccumulator} from "@damntools.fr/react-utils"
import {Logger, Logging} from "@damntools.fr/logger-simple"
import {ArrayList, List, Optionable, Optional, toList} from "@damntools.fr/types"
import {MediaPlayerPlaylistService} from "../../services"
import {LibraryResource, LibraryResourceService} from "../../../library"
import styles from "../../components/MedialPlayer/MediaPlayer.module.scss"
import {ExplorerService} from "../../../explorer"

export const MediaPlayerContext = React.createContext({} as MediaPlayerProviderState)

export const MediaPlayerConsumer = MediaPlayerContext.Consumer

export type MediaPlayerShowOptions = {
  full?: boolean
}

export type PlaylistElementExport = {
  index: number
  resourceId: number
  resourceType: string
  playing: boolean
  elapsed: number
  paused: boolean
  closed: boolean
}

export type MediaPlayerProviderOptionsExport = {
  isOpen: boolean
  isShuffled: boolean
  isReplay: boolean
  isMuted: boolean
  volume: number
  playlist: Array<PlaylistElementExport>
}

export type MediaPlayerProviderOptionsExportPartial = {
  isOpen: boolean
  isShuffled: boolean
  isReplay: boolean
  isMuted: boolean
  volume: number
  playlist: List<PlaylistElementExport>
}

export type PlaylistElement = {
  index: number
  resource: LibraryResource
  playing: boolean
  elapsed: number
  paused: boolean
  closed: boolean
}
export type MediaPlayerProviderOptionsState = {
  isOpen: boolean
  isShuffled: boolean
  isReplay: boolean
  isMuted: boolean
  volume: number
  playlist: List<PlaylistElement>
}
export type MediaPlayerProviderState = MediaPlayerProviderOptionsState & {
  show: (options?: MediaPlayerShowOptions) => Promise<any>
  hide: () => Promise<any>
  toggle: (options?: MediaPlayerShowOptions) => Promise<any>
  playNow: (resource: LibraryResource) => Promise<any>
  playNext: (resource: LibraryResource) => Promise<any>
  playLast: (resource: LibraryResource) => Promise<any>
  onEnd: () => Promise<any>
  onProgress: (progress: number) => Promise<any>
  onPause: (progress: number) => Promise<any>
  onPrevious: () => Promise<any>
  onNext: () => Promise<any>
  onPlay: (progress: number) => Promise<any>
  toggleShuffleMode: () => Promise<any>
  toggleReplayMode: () => Promise<any>
  togglePlayState: () => Promise<any>
  setMuted: (muted: boolean) => Promise<any>
  setVolume: (volume: number) => Promise<any>
  getAudioRef: () => HTMLAudioElement
  clickPrevious: () => Promise<any>
  clickNext: () => Promise<any>
}

export class MediaPlayerProvider extends React.Component<any, MediaPlayerProviderState> {
  private static INSTANCE: MediaPlayerProvider | null = null
  public static readonly STORAGE_KEY = "seedbox_library_player_state"

  private readonly logger: Logger
  private readonly stater: ReactStateAccumulator<Readonly<MediaPlayerProviderState>>
  private ref: HTMLAudioElement | null = null

  constructor(props: any) {
    super(props)
    this.logger = Logging.getLogger("player.Provider")
    this.stater = ReactState.prepare(this, this.logger)
    MediaPlayerProvider.INSTANCE = this
    this.state = {
      playlist: new ArrayList(),
      volume: 0,
      isOpen: false,
      isShuffled: false,
      isReplay: false,
      isMuted: false,
      playNow: this.playNow.bind(this),
      playNext: this.playNext.bind(this),
      playLast: this.playLast.bind(this),
      hide: this.hide.bind(this),
      show: this.show.bind(this),
      toggle: this.toggle.bind(this),
      onEnd: this.onEnd.bind(this),
      onProgress: this.onProgress.bind(this),
      onPause: this.onPause.bind(this),
      onPlay: this.onPlay.bind(this),
      toggleShuffleMode: this.toggleShuffleMode.bind(this),
      togglePlayState: this.togglePlayState.bind(this),
      toggleReplayMode: this.toggleReplayMode.bind(this),
      setMuted: this.setMuted.bind(this),
      setVolume: this.setVolume.bind(this),
      getAudioRef: this.getAudioRef.bind(this),
      onNext: this.onNext.bind(this),
      onPrevious: this.onPrevious.bind(this),
      clickPrevious: this.clickPrevious.bind(this),
      clickNext: this.clickNext.bind(this)
    }
  }

  componentDidMount() {
    void this.loadState()
    window.addEventListener("beforeunload", () => {
      const service = this.getService()
      service
        .getPlaying()
        .map(elem => {
          elem.closed = true
          return this.stater
            .applyState({playlist: this.state.playlist.copy()})
            .then(() => this.saveState())
        })
        .orElseReturn(Promise.resolve() as Promise<any>)
      return
    })
  }

  private getRealPlayer(current: Optionable<PlaylistElement>) {
    return (
      <div className={styles.RealPlayer} style={{display: "none"}}>
        <audio
          ref={r => {
            this.ref = r
            if (r) {
              r.volume = this.state.volume || 0.0
            }
          }}
          autoPlay
          src={current
            .map(c => ExplorerService.getStreamingUrl(c.resource.filename))
            .orElseUndefined()}
          muted={this.state.isMuted}
          onPlay={e => this.onPlay((e.target as any).currentTime)}
          onPause={e => this.onPause((e.target as any).currentTime)}
          // onSeeking={e => this.onEvent("onSeeking", e)}
          // onVolumeChange={e => this.onEvent("onVolumeChange", e)}
          onEnded={() => this.onEnd()}
          // onSeeked={e => this.onEvent("onSeeked", e, provider)}
          // onAbort={e => this.onEvent("onAbort", e, provider)}
          // onError={e => this.onEvent("onError", e, provider)}
          // onLoadStart={e => this.onEvent("onLoadStart", e, provider)}
          // onLoadedData={() => {
          // this.onEvent("onLoadedData", e, provider)
          // this.onLoaded()
          // onPlaying={e => this.onEvent("onPlaying", e, provider)}
          // onSuspend={e => this.onEvent("onSuspend", e, provider)}
          // onWaiting={e => this.onEvent("onWaiting", e, provider)}
          onTimeUpdate={e => this.onProgress((e.target as any).currentTime)}
        />
      </div>
    )
  }

  private getService() {
    return MediaPlayerPlaylistService.get(this.state.playlist)
  }

  private getRef(): Optionable<HTMLAudioElement> {
    return Optional.nullable(this.ref as any)
  }

  getAudioRef(): HTMLAudioElement {
    return this.getRef().orElseUndefined() as HTMLAudioElement
  }

  toggleShuffleMode() {
    return this.stater
      .applyState({isShuffled: !this.state.isShuffled})
      .then(() => this.saveState())
  }

  setMuted(muted: boolean) {
    this.getRef().peek(r => (r.muted = muted))
    return this.stater.applyState({isMuted: muted}).then(() => this.saveState())
  }

  setVolume(volume: number) {
    volume = Math.min(volume, 1)
    volume = Math.max(volume, 0)
    this.getRef().peek(r => (r.volume = volume))
    return this.stater.applyState({volume}).then(() => this.saveState())
  }

  toggleReplayMode() {
    return this.stater
      .applyState({isReplay: !this.state.isReplay})
      .then(() => this.saveState())
  }

  togglePlayState() {
    const service = this.getService()
    return service
      .getPlaying()
      .map(elem => {
        elem.paused = elem.closed ? false : !elem.paused
        elem.closed = false
        return this.stater
          .applyState({playlist: this.state.playlist.copy()})
          .then(() => this.saveState())
          .then(() => this.getRef().peek(r => (elem.paused ? r.pause() : r.play())))
      })
      .orElseReturn(Promise.resolve() as Promise<any>)
  }

  onEnd(): Promise<any> {
    const service = this.getService()
    return service
      .getPlaying()
      .flatMap(elem => {
        const nextElement = service.getNextElement(elem)
        return nextElement.map(next => {
          elem.paused = false
          elem.playing = false
          elem.elapsed = 0
          next.closed = false
          next.playing = true
          next.elapsed = 0
          return this.stater
            .applyState({playlist: this.state.playlist.copy()})
            .then(() => this.saveState())
        })
      })
      .orElseReturn(Promise.resolve() as Promise<any>)
  }

  clickNext(): Promise<any> {
    const service = this.getService()
    return service
      .getPlaying()
      .flatMap(elem => {
        const nextElement = service.getNextElement(elem)
        return nextElement.map(next => {
          elem.paused = false
          elem.playing = false
          elem.closed = false
          elem.elapsed = 0
          next.closed = false
          next.playing = true
          next.elapsed = 0
          return this.stater
            .applyState({playlist: this.state.playlist.copy()})
            .then(() => this.saveState())
        })
      })
      .orElseReturn(Promise.resolve() as Promise<any>)
  }

  onNext() {
    return this.clickNext()
  }

  onPrevious() {
    return this.clickPrevious()
  }

  clickPrevious(): Promise<any> {
    const service = this.getService()
    return service
      .getPlaying()
      .flatMap(elem => {
        const previousElement = service.getPreviousElement(elem)
        return previousElement.map(prev => {
          elem.paused = false
          elem.playing = false
          elem.closed = false
          elem.elapsed = 0
          prev.closed = false
          elem.paused = false
          prev.playing = true
          prev.elapsed = 0
          return this.stater
            .applyState({playlist: this.state.playlist.copy()})
            .then(() => this.saveState())
        })
      })
      .orElseReturn(Promise.resolve() as Promise<any>)
  }

  onProgress(progress: number) {
    const service = this.getService()
    return service
      .getPlaying()
      .map(elem => {
        if (!elem.closed) {
          elem.elapsed = progress
          return this.stater
            .applyState({playlist: this.state.playlist.copy()})
            .then(() => this.saveState())
        }
        return Promise.resolve()
      })
      .orElseReturn(Promise.resolve() as Promise<any>)
  }

  onPause(progress: number) {
    const service = this.getService()
    this.logger.debug("Paused at", progress)
    return service
      .getPlaying()
      .map(elem => {
        elem.paused = true
        elem.closed = false
        return this.stater
          .applyState({playlist: this.state.playlist.copy()})
          .then(() => this.saveState())
      })
      .orElseReturn(Promise.resolve() as Promise<any>)
  }

  onPlay(progress: number) {
    const service = this.getService()
    this.logger.debug("Resumed at", progress)
    return service
      .getPlaying()
      .map(elem => {
        elem.paused = false
        elem.closed = false
        return this.stater
          .applyState({playlist: this.state.playlist.copy()})
          .then(() => this.saveState())
      })
      .orElseReturn(Promise.resolve() as Promise<any>)
  }

  playNow(resource: LibraryResource) {
    return this.stater
      .applyState({
        isOpen: true,
        playlist: this.getService().playNow(resource)
      })
      .then(() => this.saveState())
  }

  playNext(resource: LibraryResource) {
    return this.stater
      .applyState({
        isOpen: true,
        playlist: this.getService().playNext(resource)
      })
      .then(() => this.saveState())
  }

  playLast(resource: LibraryResource) {
    return this.stater
      .applyState({
        isOpen: true,
        playlist: this.getService().playLast(resource)
      })
      .then(() => this.saveState())
  }

  hide() {
    return this.stater.applyState({isOpen: false}).then(() => this.saveState())
  }

  show(options: MediaPlayerShowOptions | undefined) {
    this.logger.info(options)
    return this.stater.applyState({isOpen: true}).then(() => this.saveState())
  }

  toggle() {
    return this.stater
      .applyState({isOpen: !this.state.isOpen})
      .then(() => this.saveState())
  }

  saveState() {
    const exports: MediaPlayerProviderOptionsExport = {
      isMuted: this.state.isMuted,
      isOpen: this.state.isOpen,
      isReplay: this.state.isReplay,
      isShuffled: this.state.isShuffled,
      volume: this.state.volume,
      playlist: this.state.playlist
        .stream()
        .map(pe => {
          return {
            index: pe.index,
            resourceId: pe.resource.id,
            resourceType: pe.resource.type.key().toUpperCase(),
            playing: pe.playing,
            elapsed: pe.elapsed,
            paused: pe.paused,
            closed: pe.closed
          }
        })
        .collectArray()
    }
    localStorage.setItem(MediaPlayerProvider.STORAGE_KEY, JSON.stringify(exports))
  }

  loadState() {
    const exports =
      JSON.parse(localStorage.getItem(MediaPlayerProvider.STORAGE_KEY) as string) || {}
    const state: MediaPlayerProviderOptionsExportPartial = {
      isMuted: exports.isMuted || false,
      isOpen: exports.isOpen || false,
      isReplay: exports.isReplay || false,
      isShuffled: exports.isShuffled || false,
      volume: exports.volume || 0,
      playlist:
        (exports.playlist &&
          exports.playlist
            .map((pe: PlaylistElementExport) => ({
              index: pe.index,
              resourceId: pe.resourceId,
              resourceType: pe.resourceType,
              playing: pe.playing,
              elapsed: pe.elapsed,
              closed: pe.closed,
              paused: pe.paused
            }))
            .toList()) ||
        new ArrayList()
    }
    LibraryResourceService.get()
      .getCompleteByIds(
        state.playlist
          .stream()
          .map(e => `${e.resourceType}:${e.resourceId}`)
          .collect(toList)
      )
      .then(results => {
        return this.stater.applyState({
          ...state,
          playlist: state.playlist
            .stream()
            .map(el => {
              const found = results
                .stream()
                .find(
                  res =>
                    res.id === el.resourceId &&
                    res.type.key().toUpperCase() === el.resourceType.toUpperCase()
                )
              return {...el, resource: found}
            })
            .filterPresent()
            .collect(toList)
        })
      })
      .then(() => this.saveState())
      .then(() => {
        this.state.playlist
          .stream()
          .filter(el => el.closed && el.playing)
          .peek(el => {
            this.getRef().peek(r => (r.currentTime = el.elapsed))
            el.paused = true
            el.closed = false
          })
        return this.stater.applyState({playlist: this.state.playlist})
      })
      .then(() => this.saveState())
  }

  static get(): MediaPlayerProvider {
    if (!this.INSTANCE) {
      throw Error("Can not get media provider before initialisation ")
    }
    return this.INSTANCE
  }

  render() {
    const current = this.getService().getPlaying()
    return (
      <MediaPlayerContext.Provider value={this.state}>
        {this.getRealPlayer(current)}
        {this.props.children}
      </MediaPlayerContext.Provider>
    )
  }
}
