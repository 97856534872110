import {BackendService} from "../../common"
import {
  DownloadExec,
  DownloadRequest,
  PlaylistInfo,
  ResourceInfo,
  TrackDownloadExecDto,
  TrackInfo,
  YoutubeFormat,
  YoutubeResourceType
} from "../models"
import {Collectors, List, Lists, toList} from "@damntools.fr/types"
import {StringUtils} from "@damntools.fr/utils-simple"
import {
  YoutubeDownloadMapper,
  YoutubeFormatMapper,
  YoutubeResourceInfoMapper
} from "../mappers"
import {AxiosWrapper} from "@damntools.fr/http"

export class YoutubeService {
  static INSTANCE: YoutubeService | null = null
  private readonly client: AxiosWrapper
  private readonly mapper: YoutubeResourceInfoMapper
  private readonly downloadMapper: YoutubeDownloadMapper
  private readonly formatMapper: YoutubeFormatMapper

  constructor() {
    this.client = BackendService.authenticatedAxios()
    this.mapper = YoutubeResourceInfoMapper.get()
    this.formatMapper = YoutubeFormatMapper.get()
    this.downloadMapper = YoutubeDownloadMapper.get()
  }

  getTrackInfo(id: string): Promise<TrackInfo> {
    return this.client
      .get(`/youtube/resource/track/${id}`)
      .then(res => res.data)
      .then(res => this.mapper.trackFromDto(res))
  }

  getTrackFormats(id: string): Promise<List<YoutubeFormat>> {
    return this.client
      .get(`/youtube/resource/track/${id}/formats`)
      .then(res => res.data)
      .then(res => this.formatMapper.fromDtos(res))
  }

  getPlaylistInfo(id: string): Promise<PlaylistInfo> {
    return this.client
      .get(`/youtube/resource/playlist/${id}`)
      .then(res => res.data)
      .then(res => this.mapper.playlistFromDto(res))
      .thenDo(playlist => {
        playlist.tracks = playlist.tracks
          .stream()
          .unique((a, b) => a.id === b.id)
          .collect(toList)
      })
  }

  getSubDirectories(relativePath: string): Promise<List<string>> {
    return this.client
      .post(`/youtube/download/relative-path`, {relativePath})
      .then(res => res.data as Array<string>)
      .then(Lists.from)
  }

  getResourceInfo(type: YoutubeResourceType, id: string): Promise<ResourceInfo> {
    if (YoutubeResourceType.PLAYLIST.equals(type)) return this.getPlaylistInfo(id)
    else if (YoutubeResourceType.TRACK.equals(type)) return this.getTrackInfo(id)
    throw new Error("Invalid type !")
  }

  requestDownload(request: DownloadRequest): Promise<string> {
    const dto = this.mapper.requestDownloadToDto(request)
    return this.client
      .post(`/youtube/download/${request.type.key().toLowerCase()}?force=true`, dto)
      .then(res => res.data as string)
  }

  getDownloads(): Promise<List<DownloadExec>> {
    return Promise.all([
      this.client
        .get(`/youtube/download/track?excludePlaylistInclude=true`)
        .then(res => res.data as Array<TrackDownloadExecDto>)
        .then(res => res.filter(value => StringUtils.isEmpty(value.playlistId)))
        .then(tracks => this.downloadMapper.tracksFromDto(tracks)),
      this.client
        .get(`/youtube/download/playlist`)
        .then(res => res.data)
        .then(playlists => this.downloadMapper.playlistsFromDto(playlists))
    ]).then(results =>
      Lists.from(results).stream().flat<DownloadExec>(1).collect(Collectors.toList)
    )
  }

  static get(): YoutubeService {
    if (this.INSTANCE === null) this.INSTANCE = new YoutubeService()
    return this.INSTANCE
  }
}
