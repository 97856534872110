import React from "react"
import {Logger, Logging} from "@damntools.fr/logger-simple"
import {
  MetaExtractionCompletePayload,
  MetaExtractionSummaryPayload,
  MetaProcessingCompletePayload
} from "../../models"
import {Optionable, Optional} from "@damntools.fr/types"
import {AlertProvider, Notification} from "@damntools.fr/react-alert"
import {NumberUtils} from "@damntools.fr/utils-simple"

export const LibraryScanContext = React.createContext({} as LibraryScanProviderState)

export const LibraryScanConsumer = LibraryScanContext.Consumer

export type ScanStep = 0 | 1 | 2 | 3

export type LibraryScanProviderState = {
  running: boolean
  expectedFiles: number
  step: ScanStep
  lastSummary: Optionable<MetaExtractionSummaryPayload>

  // setStarted: (expectedFiles: number) => void
  // setSummary: (summary: MetaExtractionSummaryPayload) => void
  // complete: () => void
}

export class LibraryScanProvider extends React.Component<any, LibraryScanProviderState> {
  private static INSTANCE: LibraryScanProvider | null = null

  state: LibraryScanProviderState = {
    step: 0,
    expectedFiles: 0,
    lastSummary: Optional.empty(),
    running: false
    /*step: 2,
    running: true,
    expectedFiles: 500,
    lastSummary: Optional.of({
      expectedSize: 500,
      errors: 2,
      done: 50,
      scanned: 2,
      extracted: 0,
      baseMetaStored: 0,
      processed: 0,
      warnings: 5
    })*/
  }
  private static readonly logger: Logger = Logging.getLogger("LibraryScanProvider")

  constructor(props: any) {
    super(props)
    LibraryScanProvider.INSTANCE = this
  }

  static complete() {
    if (this.INSTANCE)
      this.INSTANCE.setState({
        step: 0,
        running: false,
        expectedFiles: 0,
        lastSummary: Optional.empty()
      })
  }

  static setStarted(expectedFiles: number) {
    if (this.INSTANCE) {
      this.logger.info("Metadata scan started for", expectedFiles, "files")
      this.INSTANCE.setState({
        step: 0,
        running: true,
        expectedFiles,
        lastSummary: Optional.empty()
      })
    }
  }

  static setMetaExtractionComplete(payload: MetaExtractionCompletePayload) {
    if (this.INSTANCE) {
      this.INSTANCE.setState(
        {
          step: 1
        },
        () => {
          void AlertProvider.submit(
            Notification.info()
              .Subtitle(
                <div>
                  <div>Metadata successfully extracted for {payload.files} files.</div>
                  <div>
                    Task 1 / 3 done in{" "}
                    {NumberUtils.formatNumber(payload.duration / 1000, 1)}s.
                  </div>
                </div>
              )
              .Timeout(20000)
          )
        }
      )
    }
  }

  static setMetaProcessingComplete(payload: MetaProcessingCompletePayload) {
    if (this.INSTANCE)
      this.INSTANCE.setState(
        {
          step: 2
        },
        () => {
          void AlertProvider.submit(
            Notification.info()
              .Subtitle(
                <div>
                  <div>
                    Metadata successfully stored and processed for {payload.files} files.
                  </div>
                  <div>
                    Task 2 / 3 done in{" "}
                    {NumberUtils.formatNumber(payload.duration / 1000, 1)}s.
                  </div>
                </div>
              )
              .Timeout(20000)
          )
        }
      )
  }

  static setSummary(summary: MetaExtractionSummaryPayload) {
    if (this.INSTANCE) {
      this.logger.info("Received meta scan summary", summary)
      const state = {
        lastSummary: Optional.of(summary),
        expectedFiles: summary.expectedSize,
        running: true
      } as LibraryScanProviderState
      this.INSTANCE.setState(state)
    }
  }

  render() {
    return (
      <LibraryScanContext.Provider value={this.state}>
        {this.props.children}
      </LibraryScanContext.Provider>
    )
  }
}
