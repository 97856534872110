import React, {JSX} from "react"
import styles from "./AppContent.module.scss"
import {RouteComponentProps} from "react-router"
import {Globals} from "@damntools.fr/react-globals"
import {MediaPlayerProvider} from "../../../player"
import {AlertProvider} from "@damntools.fr/react-alert"
import {ExplorerProvider} from "../../../explorer"
import {AuthProvider} from "../../../common"
import {HeaderUserInfo} from "../global"
import {getMenuEntries, MenuEntry} from "../MenuEntry"
import {InfoPanel, LibraryResourceProvider, LibraryScanProvider} from "../../../library"
import {LibraryScanStatusFooter} from "../LibraryScanStatusFooter"
import {ContextMenuProvider} from "@damntools.fr/react-layout"
import {SlimMediaPlayer} from "../../../player/components/MedialPlayer"

export type AppContentProps = {
  route: RouteComponentProps
  title?: string
  children: JSX.Element
}

export class AppContent extends React.Component<AppContentProps, any> {
  constructor(props: AppContentProps) {
    super(props)
  }

  render() {
    const title = Globals.get<string>("description.title").orElseReturn("seedbox")
    const popinStyle = {
      title: styles.PopinTitle,
      root: styles.PopinRoot,
      header: styles.PopinHeader,
      subtitle: styles.PopinSubTitle,
      content: styles.PopinContent,
      closeButton: styles.PopinCloseButton,
      actionRow: styles.PopinActionRow,
      container: styles.PopinContainer
    }
    return (
      <MediaPlayerProvider>
        <AlertProvider
          defaultNotificationTimeout={5000}
          classes={{
            popin: popinStyle
          }}>
          <ContextMenuProvider>
            <ExplorerProvider route={this.props.route}>
              <LibraryScanProvider>
                <LibraryResourceProvider>
                  <AuthProvider>
                    <div className={styles.HomeMainFrame}>
                      <div className={styles.MainAppBlock}>
                        <div className={styles.Header}>
                          <div className={styles.HeaderContent}>
                            Welcome on the <span>{title}</span>
                          </div>
                          <HeaderUserInfo />
                        </div>
                        <div className={styles.MainPanel}>
                          <div className={styles.Panel}>
                            <ul className={styles.Menus}>
                              {getMenuEntries()
                                .stream()
                                .filter(e => e.enabled)
                                .map(e => <MenuEntry key={e.link} {...e} />)
                                .collectArray()}
                            </ul>
                          </div>
                          <div className={styles.Page}>
                            <div className={styles.Wrapper}>
                              {this.props.title ? (
                                <h1 className={styles.Title}>{this.props.title}</h1>
                              ) : null}
                              <div className={styles.Content}>
                                <div>{this.props.children}</div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.RightPane}>
                            <div>
                              <InfoPanel />
                            </div>
                          </div>
                        </div>
                        <div className={styles.Footer}>
                          {/*<SlimAudioPlayer />*/}
                          <SlimMediaPlayer />
                          <LibraryScanStatusFooter />
                        </div>
                      </div>
                    </div>
                  </AuthProvider>
                </LibraryResourceProvider>
              </LibraryScanProvider>
            </ExplorerProvider>
          </ContextMenuProvider>
        </AlertProvider>
      </MediaPlayerProvider>
    )
  }
}
