export type ResourceInfoDto = {
  id: string
  title: string
  uploader: string
  uploaderId: string
  duration: number
  deletedResource: boolean
  privateResource: boolean
}

export type ResourceInfoCtor = {
  id: string
  title: string
  uploader: string
  uploaderId: string
  duration: number
  deletedResource: boolean
  privateResource: boolean
}

export abstract class ResourceInfo {
  id: string
  title: string
  uploader: string
  uploaderId: string
  duration: number
  deletedResource: boolean
  privateResource: boolean

  protected constructor(obj: ResourceInfoCtor) {
    this.id = obj.id
    this.title = obj.title
    this.uploader = obj.uploader
    this.uploaderId = obj.uploaderId
    this.duration = obj.duration
    this.deletedResource = obj.deletedResource
    this.privateResource = obj.privateResource
  }
}
