import React from "react"
import styles from "./FileResourceEntry.module.scss"
import entryStyles from "../Entry.module.scss"

import {CssClass, StringUtils} from "@damntools.fr/utils-simple"
import {
  FileResourceType,
  png_delete,
  png_download,
  png_play,
  png_play_last,
  png_play_next,
  png_stream,
  ScannedFileResource,
  UrlUtils,
  WithAdminRights
} from "../../../common"
import {ExplorerService} from "../../services"
import {AlertProvider, Notification} from "@damntools.fr/react-alert"
import {LibraryResource} from "../../../library"

export type FileResourceEntryProps = {
  resource: ScannedFileResource
  selected: boolean
  onDelete: (resourceId: string) => void
  onPlay: (resource: LibraryResource) => void
  onPlayNext: (resource: LibraryResource) => void
  onPlayLast: (resource: LibraryResource) => void
}

export class FileResourceEntry extends React.Component<FileResourceEntryProps, any> {
  render() {
    const resource = this.props.resource
    const isMusic = resource.type.equals(FileResourceType.MUSIC)
    const type = resource.type.key()
    return (
      <tr
        onClick={() => {
          //window.location.hash = `#/explorer/file/${encodeURIComponent(this.props.resource.path)}`
        }}
        className={CssClass.from(styles.FileResourceEntry)
          .class(entryStyles.ResourceEntry)
          .classIf(() => entryStyles.Selected, this.props.selected)
          .get()}>
        <td className={entryStyles.Checkbox}>
          <input type="checkbox" disabled defaultChecked={this.props.selected} />
        </td>
        <td className={entryStyles.Icon}>
          <img
            src={ExplorerService.getImageForType(type)}
            alt={type}
            title={StringUtils.firstToUpper(type)}
          />
        </td>
        <td className={entryStyles.Title}>
          <span>{resource.title}</span>
        </td>
        <td className={entryStyles.Size}>
          <span>{resource.getCleanSize()}</span>
        </td>
        <td className={styles.Ext}>
          <span>{resource.extension}</span>
        </td>
        <td className={styles.TagIcons}>
          <span>
            {resource.tags
              .stream()
              .map((x: string) => (
                <span key={"Tag" + x}>
                  <img src={ExplorerService.getIconForTag(x)} alt={x} title={x} />
                </span>
              ))
              .collectArray()}
          </span>
        </td>
        <td className={entryStyles.ActionIcons + " " + entryStyles.Clickable}>
          <span>
            {ExplorerService.isStreamable(this.props.resource.type)
              ? this.getButton(
                  isMusic ? "Play" : "Stream",
                  () =>
                    ExplorerService.findLibraryResource(resource).then(r =>
                      this.props.onPlay(r)
                    ),
                  isMusic ? png_play : png_stream,
                  entryStyles.StreamButton
                )
              : null}
            {ExplorerService.isStreamable(this.props.resource.type) && isMusic
              ? this.getButton(
                  "Play next",
                  () =>
                    ExplorerService.findLibraryResource(resource).then(r =>
                      this.props.onPlayNext(r)
                    ),
                  png_play_next,
                  entryStyles.StreamButton
                )
              : null}
            {ExplorerService.isStreamable(this.props.resource.type) && isMusic
              ? this.getButton(
                  "Play last",
                  () =>
                    ExplorerService.findLibraryResource(resource).then(r =>
                      this.props.onPlayLast(r)
                    ),
                  png_play_last,
                  entryStyles.StreamButton
                )
              : null}
            {this.getButton(
              "Download",
              () => this.onClick(),
              png_download,
              entryStyles.DownloadButton
            )}
            <WithAdminRights>
              {this.getButton(
                "Delete",
                () => this.props.onDelete(resource.path),
                png_delete,
                entryStyles.DeleteButton
              )}
            </WithAdminRights>
          </span>
        </td>
      </tr>
    )
  }

  getButton(title: string, onClick: () => any, icon: string, className: string) {
    return (
      <span className={CssClass.from(className).get()} onClick={() => onClick()}>
        <img src={icon} alt={title} title={title} />
      </span>
    )
  }

  onClick() {
    ExplorerService.get()
      .requestResourceDownload(this.props.resource)
      .then(request => {
        if (request.isReady()) {
          const url = ExplorerService.getDownloadUrl(this.props.resource, request)
          console.log("Opening", url)
          UrlUtils.openUrlWithFilename(url.url, url.name)
        } else {
          throw new Error("Resource is not ready for download")
        }
      })
      .catch(err => {
        console.error(err)
        AlertProvider.submit(
          Notification.error().Subtitle("Could not start file download !")
        )
      })
  }
}
