import {Logger, Logging} from "@damntools.fr/logger-simple"
import {List, Lists} from "@damntools.fr/types"
import {WsEventHandler, WsEventWrapper} from "../common"
import {
  ExpectedMetaScanSizeHandler,
  MetaExtractionCompleteHandler,
  MetaExtractionSummaryHandler,
  MetaProcessingCompleteHandler
} from "../library"
import {FsRootScanDoneHandler} from "../common/models/ResourceEventHandlers"

export class WebSocketListener {
  private readonly socket: WebSocket
  private readonly logger: Logger
  private readonly handlers: List<WsEventHandler<any>>

  constructor(url: string) {
    this.socket = new WebSocket(url)
    this.logger = Logging.getLogger("WebSocket")
    this.handlers = Lists.of<WsEventHandler<any>>(
      // new DownloadProgressUpdateHandler(),
      new MetaExtractionCompleteHandler(),
      new MetaExtractionSummaryHandler(),
      new MetaProcessingCompleteHandler(),
      new ExpectedMetaScanSizeHandler(),
      new FsRootScanDoneHandler()
    )
  }

  init() {
    // Connection opened
    this.socket.addEventListener("open", () => {
      const obj = {
        _type: "PING"
      }
      this.socket.send(JSON.stringify(obj))
    })

    // Listen for messages
    this.socket.addEventListener("message", event => {
      const parsed = JSON.parse(event.data) as WsEventWrapper<any>
      this.logger.trace("Message from server ", parsed)
      this.handlers
        .stream()
        .filter(h => h.accept(parsed))
        .peek(h => h.process(parsed.payload))
    })
  }
}
