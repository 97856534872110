import {DownloadStatus} from "../DownloadStatus"
import {DownloadRequest, DownloadRequestDto} from "./DownloadRequest"

export type DownloadExecCtor = {
  id: number
  request: DownloadRequest
  status: DownloadStatus
  stoppedOrCancelled: boolean
  title: string
  uploader: string
  uploaderId: string
  resourceDuration: number
}

export type DownloadExecDto = {
  id: number
  status: string
  request: DownloadRequestDto
  stoppedOrCancelled: boolean
  title: string
  uploader: string
  uploaderId: string
  resourceDuration: number
}

export abstract class DownloadExec {
  id: number
  title: string
  uploader: string
  uploaderId: string
  resourceDuration: number
  request: DownloadRequest
  status: DownloadStatus
  stoppedOrCancelled: boolean

  protected constructor(obj: DownloadExecCtor) {
    this.id = obj.id
    this.request = obj.request
    this.status = obj.status
    this.stoppedOrCancelled = obj.stoppedOrCancelled
    this.title = obj.title
    this.uploader = obj.uploader
    this.uploaderId = obj.uploaderId
    this.resourceDuration = obj.resourceDuration
  }
}
