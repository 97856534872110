import React from "react"
import styles from "./HeaderUserInfo.module.scss"
import {AuthConsumer} from "../../../../common"

export class HeaderUserInfo extends React.Component<any, any> {
  render() {
    return (
      <AuthConsumer>
        {({isAuthenticated, isAdmin, getUsername, isVip}) => (
          <div className={styles.HeaderUserInfo}>
            <span>Hello</span>
            <span>{isAuthenticated() ? getUsername() || "" : null}</span>,
            <span>
              logged in with <span>{this.getTag(isAdmin, isVip)}</span> role
            </span>
          </div>
        )}
      </AuthConsumer>
    )
  }

  getTag(isAdmin: () => boolean, isVip: () => boolean) {
    if (isAdmin())
      if (isVip()) return `vip`
      else return "admin"
    return "regular"
  }
}
