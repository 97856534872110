import {Enum} from "@damntools.fr/types"

export class YoutubeFormatQuality extends Enum<string> {
  static WORST = new YoutubeFormatQuality("worst")
  static BEST = new YoutubeFormatQuality("best")
  static UNSET = new YoutubeFormatQuality("unset")
  static VIDEO_2160P = new YoutubeFormatQuality("2160p")
  static VIDEO_1080P = new YoutubeFormatQuality("1080p")
  static VIDEO_720P = new YoutubeFormatQuality("720p")
  static VIDEO_360P = new YoutubeFormatQuality("360p")
  static VIDEO_144P = new YoutubeFormatQuality("144p")
}
