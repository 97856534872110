export interface ScannedResourceDownloadRequestDto {
  token: string
  progress: number
  ready: boolean
  failed: boolean
  filename: string
}

export interface ScannedResourceDownloadRequestCtor {
  token: string
  progress: number
  ready: boolean
  failed: boolean
  filename: string
}

export class ScannedResourceDownloadRequest {
  token: string
  progress: number
  ready: boolean
  failed: boolean
  filename: string

  constructor(obj: ScannedResourceDownloadRequestCtor) {
    this.token = obj.token
    this.progress = obj.progress
    this.ready = obj.ready
    this.failed = obj.failed
    this.filename = obj.filename
  }

  public isReady(){
    return this.ready && !this.failed && this.progress >= 100
  }
}
