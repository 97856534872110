import React from "react"
import styles from "./SoulseekBlock.module.scss"

export class SoulseekBlock extends React.Component {
  render() {
    return (
      <div className={styles.SoulseekBlock}>
        <h1>Soulseek</h1>
        <p>Coming soon ...</p>
      </div>
    )
  }
}
