import {Globals} from "@damntools.fr/react-globals"
//#IoEBBhJ8kHmy1OcTA9w1cqJLz5gQqL

export const TransmissionPage = () => {
  // eslint-disable-next-line no-undef
  // const url =
  //   (window as any).GlobalVariables.transmissionUrl ||
  //   "https://client.seedbox.damntools.fr/transmission/web/"
    const url = Globals.get<string>("components.transmission.url")
        .orElseReturn("https://client.seedbox.damntools.fr/transmission/web/")
  return (
    <div style={{width: "100%", height: "100%"}}>
      <iframe title={"Transmission client"} src={url} />
    </div>
  )
}

