import React from "react"
import styles from "./AllListedView.module.scss"
import {AllListedViewProps, AllListedViewState} from "./AllListedView.types"
import {LibraryResourceConsumer, LibraryResourceProviderState} from "../../../providers"
import {StringUtils} from "@damntools.fr/utils-simple"
import {
  LibraryResource,
  LibraryResourceType,
  LibraryToolbarSearchType
} from "../../../models"
import {List, Optionable, toList} from "@damntools.fr/types"
import {LibraryResourceFilter} from "../../../services"
import {LibraryViewTableAudio, LibraryViewTableVideo} from "../LibraryViewTables"

export class AllListedView extends React.Component<AllListedViewProps, AllListedViewState> {
  render() {
    const toolbar = this.props.toolbar
    return (
      <div className={styles.AllListedView}>
        <LibraryResourceConsumer>
          {provider => {
            let filtered = this.filterResourcesByType(
              provider.resources,
              toolbar.resourceType
            )
            filtered = this.filterResourcesFromSearch(
              filtered,
              toolbar.search,
              toolbar.searchType
            )
            return <div>{this.getTable(filtered, provider)}</div>
          }}
        </LibraryResourceConsumer>
      </div>
    )
  }

  private getTable(
    filtered: List<LibraryResource>,
    provider: LibraryResourceProviderState
  ) {
    if (
      this.props.toolbar.resourceType
        .filter(t => t.equals(LibraryResourceType.MUSIC))
        .isPresent()
    )
      return (
        <LibraryViewTableAudio
          provider={provider}
          width={this.props.width}
          resources={filtered}
          selection={this.props.selection.selected}
          setSelected={this.props.selection.setSelection}
        />
      )
    else if (
      this.props.toolbar.resourceType
        .filter(t => t.equals(LibraryResourceType.VIDEO))
        .isPresent()
    ) {
      return (
        <LibraryViewTableVideo
          width={this.props.width}
          provider={provider}
          resources={filtered}
          selection={this.props.selection.selected}
          setSelected={this.props.selection.setSelection}
        />
      )
    }
    return (
      <LibraryViewTableAudio
        provider={provider}
        width={this.props.width}
        resources={filtered}
        selection={this.props.selection.selected}
        setSelected={this.props.selection.setSelection}
      />
    )
  }

  private filterResourcesFromSearch(
    resources: List<LibraryResource>,
    search: Optionable<string>,
    searchType: LibraryToolbarSearchType
  ) {
    if (search.isEmpty() || StringUtils.isEmpty(search.get())) return resources
    const regex = search.map(s => new RegExp(s, "i"))
    return resources
      .stream()
      .filter(r => LibraryResourceFilter.matchSearch(r, regex, searchType))
      .collect(toList)
  }

  private filterResourcesByType(
    resources: List<LibraryResource>,
    type: Optionable<LibraryResourceType>
  ) {
    if (type.isEmpty()) return resources
    return resources
      .stream()
      .filter(r => LibraryResourceFilter.matchType(r, type.get()))
      .collect(toList)
  }
}
