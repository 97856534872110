import {
  Collectors,
  compare,
  Enum,
  equals,
  List,
  Lists
} from "@damntools.fr/types"
import {YoutubeFormat} from "./dto"
import {YoutubeFormatType} from "./YoutubeFormatType"
import {YoutubeFormatQuality} from "./YoutubeFormatQuality"
import {YoutubeFormatAudioExt} from "./YoutubeFormatAudioExt"
import {YoutubeFormatVideoFramerate} from "./YoutubeFormatVideoFramerate"
import {StringUtils} from "@damntools.fr/utils-simple"

export type PresetParam =
  | YoutubeFormatAudioExt
  | YoutubeFormatVideoFramerate
  | undefined

export type CompiledPreset = {
  formatCodes: List<string>
  audioFormat?: YoutubeFormatAudioExt
  convertToAudio: boolean
}

export type PresetCompilationFunction = (
  formats?: List<YoutubeFormat>
) => CompiledPreset

export const getFormatFunctionForType = (
  formats: List<YoutubeFormat>,
  type: YoutubeFormatType,
  resolution: number,
  framerate?: number
) =>
  formats
    .stream()
    .filter(f => f.type === type)
    .map(f => f.resolutionSize)
    .unique()
    .sort(compare)
    .flatMap<YoutubeFormat>(resolutionKey =>
      formats
        .stream()
        .filter(f => f.type === type && f.resolutionSize === resolutionKey)
        .map(f => f.frameRate)
        .unique()
        .sort(compare)
        .flatMap<YoutubeFormat>(framerateKey =>
          formats
            .stream()
            .filter(
              f =>
                f.type === type &&
                f.resolutionSize === resolutionKey &&
                f.frameRate === framerateKey
            )
            .sort((a, b) => b.size - a.size)
            .collect(Collectors.toList)
        )
        .collect(Collectors.toList)
    )
    .map(e => e)
    .map(e => e)
    .flat<YoutubeFormat>(3)
    .unique((a, b) => equals(a.code, b.code))
    .filter(f => !!f.resolutionSize && f.resolutionSize <= resolution)
    .filter(f => !!f.frameRate && f.frameRate <= (framerate || 30))
    .reverse()
    .findFirst()
    .map(f => f.code)
    .orElseReturn("")

export const getFormatFunctionForVideo =
  (resolution: number, framerate?: number): PresetCompilationFunction =>
  (formats?: List<YoutubeFormat>): CompiledPreset => {
    return {
      formatCodes: Lists.of(
        getFormatFunctionForType(
          formats || Lists.empty(),
          YoutubeFormatType.VIDEO,
          resolution,
          framerate
        )
      ),
      convertToAudio: false
    }
  }
export const getFormatFunctionForMixed =
  (
    presetString: string,
    resolution: number,
    framerate?: number
  ): PresetCompilationFunction =>
  (formats?: List<YoutubeFormat>): CompiledPreset => {
    return {
      formatCodes: Lists.of(
        getFormatFunctionForType(
          formats || Lists.empty(),
          YoutubeFormatType.VIDEO,
          resolution,
          framerate
        ),
        presetString
      ),
      convertToAudio: false
    }
  }

export class DownloadPreset extends Enum<string> {
  /**
   * AUDIO
   */
  static AUDIO_BEST = new DownloadPreset(
    YoutubeFormatType.AUDIO,
    YoutubeFormatQuality.BEST,
    undefined,
    () => ({formatCodes: Lists.of("bestaudio"), convertToAudio: true})
  )
  static AUDIO_BEST_FLAC = new DownloadPreset(
    YoutubeFormatType.AUDIO,
    YoutubeFormatQuality.BEST,
    YoutubeFormatAudioExt.FLAC,
    () => ({
      formatCodes: Lists.of("bestaudio"),
      audioFormat: YoutubeFormatAudioExt.FLAC,
      convertToAudio: true
    })
  )
  static AUDIO_BEST_MP3 = new DownloadPreset(
    YoutubeFormatType.AUDIO,
    YoutubeFormatQuality.BEST,
    YoutubeFormatAudioExt.MP3,
    () => ({
      formatCodes: Lists.of("bestaudio"),
      audioFormat: YoutubeFormatAudioExt.MP3,
      convertToAudio: true
    })
  )
  static AUDIO_WORST_FLAC = new DownloadPreset(
    YoutubeFormatType.AUDIO,
    YoutubeFormatQuality.WORST,
    YoutubeFormatAudioExt.FLAC,
    () => ({
      formatCodes: Lists.of("worstaudio"),
      audioFormat: YoutubeFormatAudioExt.FLAC,
      convertToAudio: true
    })
  )
  static AUDIO_WORST_MP3 = new DownloadPreset(
    YoutubeFormatType.AUDIO,
    YoutubeFormatQuality.WORST,
    YoutubeFormatAudioExt.MP3,
    () => ({
      formatCodes: Lists.of("worstaudio"),
      audioFormat: YoutubeFormatAudioExt.MP3,
      convertToAudio: true
    })
  )
  static AUDIO_WORST = new DownloadPreset(
    YoutubeFormatType.AUDIO,
    YoutubeFormatQuality.WORST,
    undefined,
    () => ({
      formatCodes: Lists.of("worstaudio"),
      convertToAudio: true
    })
  )

  /**
   * VIDEO
   */
  static VIDEO_BEST = new DownloadPreset(
    YoutubeFormatType.VIDEO,
    YoutubeFormatQuality.BEST,
    undefined,
    () => ({
      formatCodes: Lists.of("bestvideo"),
      convertToAudio: false
    })
  )
  static VIDEO_2160P = new DownloadPreset(
    YoutubeFormatType.VIDEO,
    YoutubeFormatQuality.VIDEO_2160P,
    undefined,
    getFormatFunctionForVideo(2160)
  )
  static VIDEO_1080P = new DownloadPreset(
    YoutubeFormatType.VIDEO,
    YoutubeFormatQuality.VIDEO_1080P,
    undefined,
    getFormatFunctionForVideo(1080)
  )
  static VIDEO_720P_60FPS = new DownloadPreset(
    YoutubeFormatType.VIDEO,
    YoutubeFormatQuality.VIDEO_720P,
    YoutubeFormatVideoFramerate.FPS_60,
    getFormatFunctionForVideo(720, 60)
  )
  static VIDEO_720P_30FPS = new DownloadPreset(
    YoutubeFormatType.VIDEO,
    YoutubeFormatQuality.VIDEO_720P,
    YoutubeFormatVideoFramerate.FPS_30,
    getFormatFunctionForVideo(720, 30)
  )
  static VIDEO_720P = new DownloadPreset(
    YoutubeFormatType.VIDEO,
    YoutubeFormatQuality.VIDEO_720P,
    undefined,
    getFormatFunctionForVideo(720)
  )
  static VIDEO_360P = new DownloadPreset(
    YoutubeFormatType.VIDEO,
    YoutubeFormatQuality.VIDEO_360P,
    undefined,
    getFormatFunctionForVideo(360)
  )
  static VIDEO_144P = new DownloadPreset(
    YoutubeFormatType.VIDEO,
    YoutubeFormatQuality.VIDEO_144P,
    undefined,
    getFormatFunctionForVideo(144)
  )
  static VIDEO_WORST = new DownloadPreset(
    YoutubeFormatType.VIDEO,
    YoutubeFormatQuality.WORST,
    undefined,
    () => ({
      formatCodes: Lists.of("worstvideo"),
      convertToAudio: false
    })
  )

  /**
   * MIXED
   */
  static MIXED_BEST = new DownloadPreset(
    YoutubeFormatType.MIXED,
    YoutubeFormatQuality.BEST,
    undefined,
    () => ({
      formatCodes: Lists.of("bestvideo", "bestaudio"),
      convertToAudio: false
    })
  )
  static MIXED_2160P = new DownloadPreset(
    YoutubeFormatType.MIXED,
    YoutubeFormatQuality.VIDEO_2160P,
    undefined,
    getFormatFunctionForMixed("bestaudio", 2160)
  )
  static MIXED_1080P = new DownloadPreset(
    YoutubeFormatType.MIXED,
    YoutubeFormatQuality.VIDEO_1080P,
    undefined,
    getFormatFunctionForMixed("bestaudio", 1080)
  )
  static MIXED_720P_60FPS = new DownloadPreset(
    YoutubeFormatType.MIXED,
    YoutubeFormatQuality.VIDEO_720P,
    YoutubeFormatVideoFramerate.FPS_60,
    getFormatFunctionForMixed("bestaudio", 720, 60)
  )
  static MIXED_720P_30FPS = new DownloadPreset(
    YoutubeFormatType.MIXED,
    YoutubeFormatQuality.VIDEO_720P,
    YoutubeFormatVideoFramerate.FPS_30,
    getFormatFunctionForMixed("bestaudio", 720, 30)
  )
  static MIXED_720P = new DownloadPreset(
    YoutubeFormatType.MIXED,
    YoutubeFormatQuality.VIDEO_720P,
    undefined,
    getFormatFunctionForMixed("bestaudio", 720)
  )
  static MIXED_360P = new DownloadPreset(
    YoutubeFormatType.MIXED,
    YoutubeFormatQuality.VIDEO_360P,
    undefined,
    getFormatFunctionForMixed("bestaudio", 360)
  )
  static MIXED_144P = new DownloadPreset(
    YoutubeFormatType.MIXED,
    YoutubeFormatQuality.VIDEO_144P,
    undefined,
    getFormatFunctionForMixed("bestaudio", 144)
  )
  static MIXED_WORST = new DownloadPreset(
    YoutubeFormatType.MIXED,
    YoutubeFormatQuality.WORST,
    undefined,
    () => ({
      formatCodes: Lists.of("worstaudio", "worstvideo"),
      convertToAudio: false
    })
  )

  readonly type: YoutubeFormatType
  readonly quality: YoutubeFormatQuality
  readonly param: PresetParam
  readonly presetFn: PresetCompilationFunction

  constructor(
    type: YoutubeFormatType,
    quality: YoutubeFormatQuality,
    param: PresetParam,
    presetFn: PresetCompilationFunction
  ) {
    super(
      Lists.of(type, quality, param)
        .stream()
        .filterPresent()
        .map(e => e?.key())
        .join("_")
    )
    this.type = type
    this.quality = quality
    this.param = param
    this.presetFn = presetFn
  }

  static parseFromString(str: string): DownloadPreset {
    const split = str.split("_")
    if (split.length < 2) throw Error("Invalid value, missing components !")
    const type = split[0],
      quality = split[1],
      param = split[2] || undefined
    const found = DownloadPreset.all<DownloadPreset>()
      .stream()
      .find(
        dp =>
          dp.type.key().toLowerCase() === type.toLowerCase() &&
          dp.quality.key().toLowerCase() === quality.toLowerCase() &&
          ((dp.param === undefined && param === undefined) ||
            dp.param?.key().toLowerCase() === param?.toLowerCase())
      ) as DownloadPreset
    if (!found) throw Error("Value could not be found !")
    return found
  }

  toPretty(): string {
    let str = `${StringUtils.firstToUpper(
      this.type.key()
    )} | ${StringUtils.firstToUpper(this.quality.key())}`
    if (this.param) str += ` | ${this.param.key().toUpperCase()}`
    return str
  }

  getConfigString(): string {
    return Lists.of(this.type, this.quality, this.param)
      .stream()
      .filterPresent()
      .map(e => e?.key())
      .join("_")
  }

  isAudio() {
    return this.type === YoutubeFormatType.AUDIO
  }

  isVideo() {
    return this.type === YoutubeFormatType.VIDEO
  }

  isMixed() {
    return this.type === YoutubeFormatType.MIXED
  }
}
