import React from "react"
import styles from "./YoutubeUrlSelector.module.scss"
import {TextInput, VD} from "@damntools.fr/react-inputs"
import {YoutubeResourceType} from "../../models"
import {YoutubeUrlParser} from "../../utils"
import {Optionable, Optional} from "@damntools.fr/types"

export type YoutubeUrlSelectorProps = {
  type: YoutubeResourceType
  id: Optionable<string>
  onChangeId: (value: Optionable<string>) => void
}

export const YoutubeUrlSelector = (props: YoutubeUrlSelectorProps) => {
  return (
    <div className={styles.YoutubeUrlSelector}>
      <TextInput
        manageDisplayValueOutside
        dark
        value={props.id.map(VD)}
        required
        placeholder={"Id or youtube url"}
        hideFormat
        onChange={value => onChangeId(value, props.onChangeId, props.type)}
      />
    </div>
  )
}

const onChangeId = (
  value: Optionable<string>,
  handler: (value: Optionable<string>) => void,
  type: YoutubeResourceType
) => {
  if (value.isPresent()) {
    const str = value.get()
    const state = {} as any
    if (str.toLowerCase().startsWith("http")) {
      const ytUrl = YoutubeUrlParser.parse(str, type)
      state.id = ytUrl.id
    } else {
      state.id = Optional.fromString(str)
    }
    handler(state.id)
  } else {
    handler(Optional.empty())
  }
}
