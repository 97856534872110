import {WsEventHandler} from "./WsEvent"
import {FsRootScanDonePayload} from "./ResourceEventPayloads"

export class FsRootScanDoneHandler extends WsEventHandler<FsRootScanDonePayload> {
  getType(): string {
    return "FsRootScanDone"
  }

  process(payload: FsRootScanDonePayload): void {
    console.log(`Fs scan of roots done in ${payload.duration}ms`)
  }
}
