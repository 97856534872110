import React from "react"
import styles from "./YoutubePlaylistDownloadEntry.module.scss"
import prettyBytes from "pretty-bytes"
import {TimeUtils} from "@damntools.fr/utils-simple"

import {png_list, png_outline_open_in_new_black_18dp, ProgressBar} from "../../../common"
import {DownloadStatus, PlaylistDownloadExec} from "../../models"
import {YoutubeTrackDownloadEntry} from "../YoutubeTrackDownloadEntry"
import {ExplorerService} from "../../../explorer"
import {AlertProvider, Notification} from "@damntools.fr/react-alert"

export type YoutubePlaylistDownloadEntryProps = {
  info: PlaylistDownloadExec
}

export class YoutubePlaylistDownloadEntry extends React.Component<
  YoutubePlaylistDownloadEntryProps,
  any
> {
  constructor(props: YoutubePlaylistDownloadEntryProps) {
    super(props)
    this.state = {
      expand: false
    }
  }

  render() {
    const info = this.props.info
    const progression = this.getProgression()
    const size = this.getSize()
    return (
      <div className={styles.YoutubeDLDownloadEntry}>
        <div>
          <div>
            <span></span>
            <img src={png_list} alt={"Playlist"} />
          </div>
          <div onClick={() => this.setState({expand: !this.state.expand})}>
            <div className={styles.Title}>
              <span>{info.title}</span>
            </div>
            <div className={styles.Info}>
              <div className={styles.DlInfo}>
                <span>{info.request.resourceId}</span>
                <span className={styles.Tiret}>-</span>
                <span>{TimeUtils.secondsToDuration(info.resourceDuration)}</span>
                <span className={styles.Tiret}>-</span>
                {progression !== 100 ? (
                  <span>
                    <strong>ETA :</strong>{" "}
                    {TimeUtils.secondsToDuration(this.getEta() || 0.0)}
                  </span>
                ) : null}
                {size ? (
                  <span>
                    <strong>Size :</strong> {prettyBytes(size)}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div>
            <div className={styles.Buttons}>
              {progression >= 100.0 ? (
                <span onClick={() => this.onOpenEntry(info)}>
                  <span></span>
                  <img
                    src={png_outline_open_in_new_black_18dp}
                    alt={"Open in explorer"}
                    title={"Open in explorer"}
                  />
                </span>
              ) : null}
            </div>
          </div>
          <div>
            <div className={styles.ProgressBar}>
              <ProgressBar value={progression || 0} displayText />
            </div>
          </div>
        </div>
        {this.state.expand ? (
          <div>
            {info.tracks
              .stream()
              .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
              .map(download => (
                <YoutubeTrackDownloadEntry
                  info={download}
                  showIcon={false}
                  key={`DownloadInfo${download.id}`}
                />
              ))
              .collectArray()}
          </div>
        ) : null}
      </div>
    )
  }

  getProgression() {
    return (
      this.props.info.tracks
        .stream()
        .map(v => v.progression)
        .reduce((old, cur) => old + cur, 0) / this.props.info.tracks.size()
    )
  }

  getSize() {
    return this.props.info.tracks
      .stream()
      .map(v => v.totalSize)
      .reduce((old, cur) => old + cur, 0)
  }

  getEta() {
    return this.props.info.tracks
      .stream()
      .map(v => v.eta)
      .reduce((old, cur) => old + cur, 0)
  }

  onOpenEntry(info: PlaylistDownloadExec) {
    info.tracks
      .stream()
      .filter(t => t.status.equals(DownloadStatus.DONE))
      .findFirst()
      .ifPresentDo(t => {
        ExplorerService.get()
          .getResourceByPath(t.currentFile)
          .then(res => ExplorerService.get().getResourceByPath(res.parent))
          .then(res => {
            window.location.href = `#/explorer/dir/${encodeURIComponent(res.path)}`
          })
          .catch(() =>
            AlertProvider.submit(
              Notification.error("Explorer can not find file !").Timeout(5000)
            )
          )
      })
  }
}
