import React from "react"
import styles from "./LibraryResourceInfoZone.module.scss"
import {
  FlexCell,
  FlexColumnBlock,
  FlexRowBlock,
  GrowFlexCell,
  ShrinkFlexCell
} from "@damntools.fr/react-layout"
import {IStorage, StorageHelper, StorageSource} from "@damntools.fr/local-storage/browser"
import {LibraryResourceConsumer, LibrarySelectionConsumer} from "../../../providers"
import {List, Lists} from "@damntools.fr/types"
import {LibraryResource} from "../../../models"
import {CssClass} from "@damntools.fr/utils-simple"
import {ResourceInfoView} from "./ResourceInfoView"
import {MediaPlayerConsumer, MediaPlayerPlaylistService} from "../../../../player"

export type LibraryResourceInfoZoneProps = any
export type LibraryResourceInfoZoneState = {
  selected: "current" | "selection"
}

export class LibraryResourceInfoZone extends React.Component<
  LibraryResourceInfoZoneProps,
  LibraryResourceInfoZoneState
> {
  private storage: IStorage

  constructor(props: LibraryResourceInfoZoneProps) {
    super(props)
    this.storage = StorageHelper.with(StorageSource.WINDOW_LOCAL)
    this.state = {
      selected: window.location.hash.includes("/library/") ? "selection" : "current"
    }
  }

  componentDidMount() {
    this.storage.get("seedbox_library_resource_info_selected").then(r => {
      this.setState({
        selected: (r as any) || this.state.selected
      })
    })
  }

  render() {
    return (
      <LibrarySelectionConsumer>
        {({selected}) => (
          <LibraryResourceConsumer>
            {({resources}) => {
              const view = this.state.selected
              return (
                <div className={styles.LibraryResourceInfoZone}>
                  <div>
                    <FlexColumnBlock>
                      <ShrinkFlexCell className={styles.MenuZone}>
                        <div>
                          <FlexRowBlock className={styles.Menus}>
                            <FlexCell
                              grow={1}
                              shrink={1}
                              onClick={() => this.setState({selected: "current"})}
                              className={CssClass.from(styles.Menu)
                                .classIf(styles.MenuSelected, view === "current")
                                .get()}>
                              <span>Currently playing</span>
                            </FlexCell>
                            <FlexCell
                              grow={1}
                              shrink={1}
                              onClick={() => this.setState({selected: "selection"})}
                              className={CssClass.from(styles.Menu)
                                .classIf(styles.MenuSelected, view === "selection")
                                .get()}>
                              <span>Selected</span>
                            </FlexCell>
                          </FlexRowBlock>
                        </div>
                      </ShrinkFlexCell>
                      <GrowFlexCell className={styles.InfoZone}>
                        {view === "selection"
                          ? this.getSelectionZone(resources, selected || Lists.empty())
                          : this.getCurrentlyPlayingZone(resources)}
                      </GrowFlexCell>
                    </FlexColumnBlock>
                  </div>
                </div>
              )
            }}
          </LibraryResourceConsumer>
        )}
      </LibrarySelectionConsumer>
    )
  }

  private getCurrentlyPlayingZone(resources: List<LibraryResource>): React.ReactElement {
    return (
      <MediaPlayerConsumer>
        {({playlist}) => {
          const playlistService = new MediaPlayerPlaylistService(playlist)
          const playing = playlistService.getPlaying()
          return playing
            .flatMap(r =>
              resources.stream().findOptional(rr => rr.resource?.path === r.resource.resource?.path)
            )
            .map(r => <ResourceInfoView resource={r} />)
            .orElseReturn(null as any)
        }}
      </MediaPlayerConsumer>
    )
  }

  private getSelectionZone(
    resources: List<LibraryResource>,
    selected: List<number>
  ): React.ReactElement {
    if(!window.location.hash.includes("/library/"))
      return <div style={{padding: "10px", textAlign: "center"}}>Not in library</div>
    return resources
      .stream()
      .filter(r => selected.includes(r.id))
      .findLast()
      .map(r => <ResourceInfoView resource={r} />)
      .orElseReturn(null as any)
  }
}
