import {CssClass} from "@damntools.fr/utils-simple"
import styles from "../AppContent/AppContent.module.scss"
import {Link} from "react-router-dom"
import React from "react"
import {Lists, Optional} from "@damntools.fr/types"
import {
  png_color,
  png_explorer,
  png_maintenance,
  png_transmission,
  png_youtube
} from "../../../common"
import {Globals} from "@damntools.fr/react-globals"

type MenuEntryProps = {
  name: string
  link: string
  icon: string
  enabled: boolean
}

const isMenuSelected = (link: string): boolean => {
  const pattern = new RegExp("^#" + link + ".*")
  return pattern.test(window.location.hash)
}

export const getMenuEntries = () => {
  const transmissionEnabled = Globals.get<boolean>("components.transmission.enabled")
    .orElse(() => Optional.of(true))
    .filter(e => e)
    .isPresent()
  const slskdEnabled = Globals.get<boolean>("components.slskd.enabled")
    .orElse(() => Optional.of(true))
    .filter(e => e)
    .isPresent()
  return Lists.of<MenuEntryProps>(
    {icon: png_explorer, link: "/explorer", name: "Explorer", enabled: true},
    {icon: png_youtube, link: "/youtubedl", name: "Youtube", enabled: true},
    {icon: png_color, link: "/library", name: "Library", enabled: true},
    {icon: png_maintenance, link: "/soulseek", name: "Soulseek", enabled: slskdEnabled},
    {icon: png_maintenance, link: "/slskd", name: "Slskd client", enabled: slskdEnabled},
    {
      icon: png_transmission,
      link: "/transmission",
      name: "Torrent client",
      enabled: transmissionEnabled
    }
  )
}

export const MenuEntry = ({name, link, icon}: MenuEntryProps) => (
  <li
    className={CssClass.from(styles.MenuEntry)
      .classIf(() => styles.CurrentMenu, isMenuSelected(link))
      .get()}>
    <Link
      to={link}
      onClick={e => {
        e.preventDefault()
        window.location.hash = link
      }}>
      <div>
        <span></span>
        <img src={icon} alt="link" />
      </div>
      <div>
        <span>{name}</span>
      </div>
    </Link>
  </li>
)

export const HomePageMenuEntry = ({name, link, icon}: MenuEntryProps) => (
  <li
    className={CssClass.from(styles.HomePageMenuEntry)
      .classIf(() => styles.CurrentMenu, isMenuSelected(link))
      .get()}>
    <Link
      to={link}
      onClick={e => {
        e.preventDefault()
        window.location.hash = link
      }}>
      <div className={styles.HomeMenuIcon}>
        <span></span>
        <img src={icon} alt="link" />
      </div>
      <div className={styles.HomeMenuName}>
        <span>{name}</span>
      </div>
    </Link>
  </li>
)
