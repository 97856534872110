import React, {ReactElement} from "react"
import {
  LibraryNavigationViewType,
  LibraryResourceType,
  LibraryToolbarSearchType
} from "../../models"
import {Optionable, Optional} from "@damntools.fr/types"
import {RouteComponentProps} from "react-router"
import {IStorage, StorageHelper, StorageSource} from "@damntools.fr/local-storage/browser"

export const LibraryToolbarContext = React.createContext(
  {} as LibraryToolbarProviderState
)

export const LibraryToolbarConsumer = LibraryToolbarContext.Consumer

export type SetLibraryResourceType = (type: Optionable<LibraryResourceType>) => void

export type LibraryToolbarProviderState = {
  navigationViewType: LibraryNavigationViewType
  setViewType: (type: LibraryNavigationViewType) => void

  resourceType: Optionable<LibraryResourceType>
  setResourceType: SetLibraryResourceType
  search: Optionable<string>
  searchType: LibraryToolbarSearchType
  setSearch: (search: Optionable<string>) => void
  setSearchType: (type: LibraryToolbarSearchType) => void
}

export type LibraryToolbarProviderProps = {
  route: RouteComponentProps
  children: ReactElement
}

export class LibraryToolbarProvider extends React.Component<
  LibraryToolbarProviderProps,
  LibraryToolbarProviderState
> {
  private static INSTANCE: LibraryToolbarProvider | null = null

  state: LibraryToolbarProviderState = {
    navigationViewType: LibraryNavigationViewType.RESOURCE_LIST,
    setViewType: type => {
      this.setState({navigationViewType: type}, () => this.saveState())
    },
    resourceType: Optional.empty(),
    setResourceType: (resourceType: Optionable<LibraryResourceType>) => {
      this.setState({resourceType}, () => this.saveState())
    },
    search: Optional.empty(),
    searchType: LibraryToolbarSearchType.ALL,
    setSearch: search => {
      this.setState({search}, () => this.saveState())
    },
    setSearchType: type => this.setState({searchType: type}, () => this.saveState())
  }
  private readonly storage: IStorage

  componentDidUpdate(prevProps: Readonly<LibraryToolbarProviderProps>) {
    console.log(prevProps.route.match.url, this.props.route)
  }

  componentDidMount() {
    this.updateStateFrom()
  }

  constructor(props: any) {
    super(props)
    LibraryToolbarProvider.INSTANCE = this
    this.storage = StorageHelper.with(StorageSource.WINDOW_LOCAL)
  }

  render() {
    return (
      <LibraryToolbarContext.Provider value={this.state}>
        {this.props.children}
      </LibraryToolbarContext.Provider>
    )
  }

  private saveState() {
    this.storage.set("seedbox_library_toolbar", JSON.stringify({
      search: this.state.search.orElseUndefined(),
      viewType: this.state.navigationViewType.key(),
      resourceType: this.state.resourceType.map(t => t.key()).orElseUndefined(),
      searchType: this.state.searchType.key()
    }))
  }

  private updateStateFrom(){
    this.storage.get("seedbox_library_toolbar").then(s => s && JSON.parse(s as string) || undefined)
      .then(s => {
        if(s){
          const state = {} as LibraryToolbarProviderState
          if( s.search)
            state.search = Optional.fromString(s.search)
          if( s.viewType)
            state.navigationViewType = LibraryNavigationViewType.fromValue(s.viewType)
          if( s.resourceType)
            state.resourceType = LibraryResourceType.optionalFromValue(s.resourceType)
          if( s.searchType)
            state.searchType = LibraryToolbarSearchType.fromValue(s.searchType)
          this.setState(state)
        }
      })
  }
}
