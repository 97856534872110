import {Enum} from "@damntools.fr/types"

export class DownloadStatus extends Enum<string> {
  static CREATED = new DownloadStatus("CREATED")
  static QUEUED = new DownloadStatus("QUEUED")
  static STARTED = new DownloadStatus("STARTED")
  static STOPPED = new DownloadStatus("STOPPED")
  static CANCELLED = new DownloadStatus("CANCELLED")
  static POSTPROCESS = new DownloadStatus("POSTPROCESS")
  static DONE = new DownloadStatus("DONE")
}
