import React from "react"
import styles from "./MediaPlayer.module.scss"
import slimStyles from "./SlimMediaPlayer.module.scss"
import {MediaPlayer, MediaPlayerProps} from "./MediaPlayer"
import {CssClass, TimeUtils} from "@damntools.fr/utils-simple"
import {
  MediaPlayerProvider,
  MediaPlayerProviderState,
  PlaylistElement
} from "../../providers"
import {Optionable} from "@damntools.fr/types"
import {
  png_listen_next,
  png_listen_pause,
  png_listen_play,
  png_listen_previous,
  png_listen_random,
  png_listen_replay,
  png_volume_down,
  png_volume_muted,
  png_volume_up
} from "../../../common"
import {ColorUtils, MaskedImage} from "@damntools.fr/react-utils"
import WaveSurfer from "wavesurfer.js"

export class SlimMediaPlayer extends MediaPlayer {
  constructor(props: MediaPlayerProps) {
    super(props)
  }

  getPlayer(
    provider: MediaPlayerProviderState,
    current: Optionable<PlaylistElement>
  ): React.ReactElement {
    return (
      <div>
        <div>
          <div className={CssClass.from(styles.Table).get()}>
            <div
              className={CssClass.from(
                styles.TableCell,
                slimStyles.PlayingControls
              ).get()}>
              <div>
                {this.getButton(
                  "Previous",
                  png_listen_previous,
                  provider.clickPrevious,
                  "#758f24",
                  "32px"
                )}
                <div></div>
                {this.getButton(
                  "Pause",
                  current.filter(c => !c.paused && !c.closed).isPresent()
                    ? png_listen_pause
                    : png_listen_play,
                  () => provider.togglePlayState(),
                  "#758f24",
                  "32px",
                  (current.filter(c => c.paused || c.closed).isPresent() &&
                    slimStyles.PausedButton) ||
                    undefined
                )}
                <div></div>
                {this.getButton(
                  "Next",
                  png_listen_next,
                  provider.clickNext,
                  "#758f24",
                  "32px"
                )}
              </div>
            </div>
            <div className={CssClass.from(styles.TableCell, slimStyles.Elapsed).get()}>
              {current
                .map(c => c.elapsed)
                .mapEmpty(() => 0)
                .map(c => TimeUtils.secondsToDuration(c, true))
                .map(v => {
                  const first = v.substring(0, 9)
                  const second = v.substring(9)
                  return (
                    <span>
                      <span>{first}</span>
                      <span style={{color: "grey"}}>{second}</span>
                    </span>
                  )
                })
                .orElseUndefined()}
            </div>
            <div
              className={CssClass.from(styles.TableCell, slimStyles.ProgressBar).get()}>
              <div>
                <div
                  ref={r => {
                    if (MediaPlayerProvider.get().getAudioRef()) {
                      if (!this.wavesurfer) {
                        this.wavesurfer = WaveSurfer.create({
                          container: r as HTMLDivElement,
                          waveColor: "rgba(0,0,0,0.3)",
                          barWidth: 2,
                          barGap: 1,
                          barRadius: 2,
                          cursorWidth: 3,
                          progressColor: "#758f24",
                          height: 30,
                          media: MediaPlayerProvider.get().getAudioRef()
                        })
                      }
                    }
                  }}></div>
              </div>
            </div>
            <div className={CssClass.from(styles.TableCell, slimStyles.Duration).get()}>
              {current
                .map(c => c.resource.duration)
                .map(TimeUtils.secondsToDuration)
                .orElseReturn(TimeUtils.secondsToDuration(0))}
            </div>
            <div
              className={CssClass.from(
                styles.TableCell,
                slimStyles.PlaylistControls
              ).get()}>
              <div>
                {this.getButton(
                  "Random",
                  png_listen_random,
                  provider.toggleShuffleMode,
                  provider.isShuffled ? "#758f24" : "grey",
                  "24px"
                )}
                <div></div>
                {this.getButton(
                  "Replay",
                  png_listen_replay,
                  provider.toggleReplayMode,
                  provider.isReplay ? "#758f24" : "grey",
                  "24px",
                  slimStyles.Replay
                )}
                <div></div>
                {this.getButton(
                  provider.isMuted ? "Unmute" : "Mute",
                  png_volume_muted,
                  () => provider.setMuted(!provider.isMuted),
                  provider.isMuted ? "#758f24" : "grey",
                  "24px"
                )}
              </div>
            </div>
            <div className={CssClass.from(styles.TableCell, slimStyles.Volume).get()}>
              <div>
                <div>
                  {this.getButton(
                    "Down",
                    png_volume_down,
                    () => provider.setVolume(provider.volume - 0.05),
                    "grey",
                    "20px"
                  )}
                </div>
                <div>
                  <input
                    type="range"
                    value={provider.volume}
                    step={0.05}
                    min={0}
                    max={1}
                    onChange={e => provider.setVolume(e.target.value as any)}
                  />
                </div>
                <div>
                  {this.getButton(
                    "Up",
                    png_volume_up,
                    () => provider.setVolume(provider.volume + 0.05),
                    "grey",
                    "20px"
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>{/*<div ref={r => (this.visualizerRef = r)}></div>*/}</div>
        </div>
      </div>
    )
  }

  componentWillUnmount() {
    this.wavesurfer?.destroy()
    this.wavesurfer = null
  }

  private getButton(
    title: string,
    icon: string,
    callback: () => any,
    color: string,
    size: string,
    className?: string
  ) {
    return (
      <div
        className={CssClass.from(slimStyles.Button, className as string).get()}
        onClick={() => callback()}>
        <span></span>
        <MaskedImage
          icon={icon}
          title={title}
          color={ColorUtils.colorToRGBA(color)}
          width={size}
        />
      </div>
    )
  }
}
