import {BackendService} from "../../common"

import {AxiosWrapper} from "@damntools.fr/http"
import {List, Lists} from "@damntools.fr/types"
import {LibraryArtistDto} from "../models"

export class LibraryArtistService {
  static INSTANCE: LibraryArtistService | null = null
  private readonly client: AxiosWrapper

  constructor() {
    this.client = BackendService.authenticatedAxios().child({baseURL: "/library/artist"})
  }

  getAllArtists(): Promise<List<LibraryArtistDto>> {
    return this.client
      .get("")
      .then(res => res.data as Array<any>)
      .then(res => Lists.from<LibraryArtistDto>(res))
  }

  static get(): LibraryArtistService {
    if (this.INSTANCE === null) this.INSTANCE = new LibraryArtistService()
    return this.INSTANCE
  }
}
