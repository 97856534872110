import {LibraryScanConsumer, ScanStep} from "../../../library"
import {FlexRowBlock, GrowFlexCell, ShrinkFlexCell} from "@damntools.fr/react-layout"

import styles from "./LibraryScanStatusFooter.module.scss"
import {png_color, ProgressBar} from "../../../common"

function getStepText(step: ScanStep) {
  if (step === 0) {
    return "Extracting metadata..."
  } else if (step === 1) {
    return "Processing metadata relations..."
  } else if (step === 2) {
    return "Extracting covers and illustrations..."
  }
}

export const LibraryScanStatusFooter = () => {
  return (
    <LibraryScanConsumer>
      {({lastSummary, expectedFiles, step, running}) => {
        if (running && lastSummary.isPresent()) {
          const summary = lastSummary.get()
          const done =
            summary.scanned +
            summary.baseMetaStored +
            summary.errors +
            summary.processed +
            summary.extracted +
            summary.done
          const donePercent = (done * 100) / expectedFiles
          return (
            <div className={styles.LibraryScanStatusFooter}>
              <FlexRowBlock>
                <ShrinkFlexCell className={styles.IconZone}>
                  <div>
                    <span></span>
                    <img src={png_color} alt={"library icon"} />
                  </div>
                </ShrinkFlexCell>
                <ShrinkFlexCell className={styles.InfoZone}>
                  <div>
                    <div>
                      Step : <strong>{step} / 4</strong> : {getStepText(step)}
                    </div>
                    <div>
                      Scanned : {done} / <strong>{expectedFiles}</strong>
                    </div>
                    <div>
                      {summary.warnings} warnings, {summary.errors} errors
                    </div>
                  </div>
                </ShrinkFlexCell>
                <GrowFlexCell className={styles.ProgressBarZone}>
                  <div>
                    <ProgressBar value={donePercent} displayText animate/>
                  </div>
                </GrowFlexCell>
              </FlexRowBlock>
            </div>
          )
        }
        return null
      }}
    </LibraryScanConsumer>
  )
}
