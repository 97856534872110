import {YoutubeFormat, YoutubeFormatCtor, YoutubeFormatDto, YoutubeFormatType} from "../models"
import {Collectors, List, Lists} from "@damntools.fr/types"

export class YoutubeFormatMapper {
    static INSTANCE: YoutubeFormatMapper | null = null

    toDto(obj: YoutubeFormat): YoutubeFormatDto {
        return {
            audioFrequency: obj.audioFrequency,
            best: obj.best,
            code: obj.code,
            extension: obj.extension,
            frameRate: obj.frameRate,
            resolution: obj.resolution,
            resolutionSize: obj.resolutionSize,
            size: obj.size,
            type: obj.type.key()
        }
    }

    fromDto(dto: YoutubeFormatDto): YoutubeFormat {
        const ctor: YoutubeFormatCtor = {
            audioFrequency: dto.audioFrequency,
            best: dto.best,
            code: dto.code,
            extension: dto.extension,
            frameRate: dto.frameRate,
            resolution: dto.resolution,
            resolutionSize: dto.resolutionSize,
            size: dto.size,
            type: YoutubeFormatType.fromValue<string, YoutubeFormatType>(dto.type)
        }
        return new YoutubeFormat(ctor)
    }

    fromDtos(dtos: Array<YoutubeFormatDto>): List<YoutubeFormat> {
        return Lists.from(dtos)
            .stream()
            .map(dto => this.fromDto(dto))
            .collect(Collectors.toList)
    }

    toDtos(dtos: List<YoutubeFormat>): Array<YoutubeFormatDto> {
        return dtos
            .stream()
            .map(dto => this.toDto(dto))
            .collect(Collectors.toArray)
    }

    static get(): YoutubeFormatMapper {
        if (this.INSTANCE === null) this.INSTANCE = new YoutubeFormatMapper()
        return this.INSTANCE
    }
}
