import {
  Table,
  TableCell,
  TableCellStyle,
  TableScroll,
  TableSelectionConfig,
  TableStyle
} from "@damntools.fr/react-layout"
import {compareStringsIgnoreCase, Optionable} from "@damntools.fr/types"
import {StyleSize} from "@damntools.fr/react-utils"
import {CssClass, NumberUtils} from "@damntools.fr/utils-simple"
import styles from "./LibraryViewTables.module.scss"
import React from "react"
import {LibraryResource} from "../../../models"
import {ResourceRatingTool} from "../../ResourceRatingTool"
import {BIG_SIZE, LibraryViewTableProps} from "./common.types"
import {LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE} from "./LibraryViewTableContextMenus"

export const LibraryViewTableAudio = ({
  resources,
  width,
  setSelected,
  provider
}: LibraryViewTableProps) => {
  width = width || 0
  const cellClasses = CssClass.from(styles.TableCell)
  const headerClasses = cellClasses.with(styles.HeaderCell)
  return (
    <Table
      forceWidth={width ? StyleSize.px(width) : undefined}
      useParentWidth
      data={resources}
      refreshId={provider.refreshId}
      rowIdentifierFn={(row: LibraryResource) => row.id}>
      <TableSelectionConfig onSelected={setSelected} />
      <TableScroll scrollDownElementAddCount={40} />
      <TableStyle classNames={cellClasses} headerClassNames={headerClasses} />

      <TableCell
        title={"Title"}
        rowField={"title"}
        sorted
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        sortFn={(a, b) => compareStringsIgnoreCase(a.title, b.title)}>
        <TableCellStyle align={"left"} />
      </TableCell>
      <TableCell
        title={"Artists"}
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        valueLoader={(row: LibraryResource) =>
          row.artists
            ?.stream()
            .map(a => a.name)
            .join(", ")
        }>
        <TableCellStyle
          align={"left"}
          width={StyleSize.px(width > BIG_SIZE ? 170 : 120)}
        />
      </TableCell>
      <TableCell
        title={"№"}
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        rowField={"albumIndex"}
        displayFn={row =>
          row.albumIndex ? NumberUtils.formatNumber(row.albumIndex, 0) : ""
        }>
        <TableCellStyle
          align={"center"}
          headerAlign={"center"}
          width={StyleSize.px(width > BIG_SIZE ? 50 : 40)}
        />
      </TableCell>
      <TableCell
        sorted
        title={"Album"}
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        valueLoader={(row: LibraryResource) => row.album?.name}>
        <TableCellStyle
          align={"left"}
          width={StyleSize.px(width > BIG_SIZE ? 200 : 150)}
        />
      </TableCell>
      <TableCell
        title={"Genres"}
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        valueLoader={(row: LibraryResource) =>
          row.genres
            ?.stream()
            .map(a => a.name)
            .join(", ")
        }>
        <TableCellStyle
          align={"left"}
          width={StyleSize.px(width > BIG_SIZE ? 150 : 100)}
        />
      </TableCell>
      {width > BIG_SIZE ? (
        <TableCell
          title={"Tags"}
          contextMenu={(r: LibraryResource) =>
            LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
          }
          valueLoader={(row: LibraryResource) =>
            row.tags
              ?.stream()
              .map(a => a.name)
              .join(", ")
          }>
          <TableCellStyle
            align={"left"}
            width={StyleSize.px(width > BIG_SIZE ? 120 : 80)}
          />
        </TableCell>
      ) : (
        (null as any)
      )}
      <TableCell
        title={"Duration"}
        rowField={"duration"}
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        sorted
        displayFn={(row: LibraryResource) => {
          const format = NumberUtils.formatDurationSeconds(row.duration).split(":")
          return (
            <span className={styles.DurationStrong}>
              {format[0]}
              <strong>:</strong>
              <span>{format[1]}</span>
              <strong>:</strong>
              {format[2]}
            </span>
          )
        }}>
        <TableCellStyle
          align={"center"}
          headerAlign={"center"}
          width={StyleSize.px(width > BIG_SIZE ? 70 : 70)}
        />
      </TableCell>
      <TableCell
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        title={"Year"}
        rowField={"year"}
        sorted
        filtered>
        <TableCellStyle
          align={"center"}
          headerAlign={"center"}
          width={StyleSize.px(width > BIG_SIZE ? 50 : 50)}
        />
      </TableCell>
      <TableCell
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        title={"Size"}
        sorted
        displayFn={(row: LibraryResource) => {
          const format = NumberUtils.formatBytes(row.resource?.size || 0, 0).split(" ")
          return (
            <span className={styles.SizeStrong}>
              <span>{format[0]}</span>
              <span>{format[1].at(0)}</span>
              {format[1].at(1)}
            </span>
          )
        }}
        valueLoader={(row: LibraryResource) => row.resource?.size || undefined}>
        <TableCellStyle
          headerAlign={"center"}
          align={"center"}
          width={StyleSize.px(width > BIG_SIZE ? 70 : 70)}
        />
      </TableCell>
      {width > BIG_SIZE ? (
        <TableCell
          title={"Ext."}
          contextMenu={(r: LibraryResource) =>
            LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
          }
          sorted
          filtered
          valueLoader={(row: LibraryResource) => row.resource?.extension || undefined}>
          <TableCellStyle
            headerAlign={"center"}
            align={"center"}
            width={StyleSize.px(width > BIG_SIZE ? 40 : 40)}
          />
        </TableCell>
      ) : (
        (null as any)
      )}
      <TableCell
        contextMenu={(r: LibraryResource) =>
          LIBRARY_VIEW_TABLE_CONTEXT_MENU_BASE(r, provider)
        }
        title={"Rating"}
        sorted
        filtered
        valueLoader={(row: LibraryResource) => row.rating || undefined}
        displayFn={r => (
          <ResourceRatingTool
            value={r.rating}
            onChange={v => onChangeRating(r, v, provider.updateResource)}
          />
        )}>
        <TableCellStyle
          align={"center"}
          headerAlign={"center"}
          width={StyleSize.px(width > BIG_SIZE ? 90 : 90)}
        />
      </TableCell>
    </Table>
    //860
  )
}

export const onChangeRating = (
  row: LibraryResource,
  value: Optionable<number>,
  updateResource: (id: number, field: keyof LibraryResource, value: any) => void
) => {
  updateResource(row.id, "rating", value.orElseUndefined())
}
