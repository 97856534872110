import React from "react"
import styles from "./AudioPlayerWrapper.module.scss"
import AudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"
import {containsMethod} from "@damntools.fr/types"
import {Logger, Logging} from "@damntools.fr/logger-simple"
import {IStorage, StorageHelper, StorageSource} from "@damntools.fr/local-storage/browser"

export type AudioPlayerEvents =
  | "onPlay"
  | "onPause"
  | "onEnded"
  | "onSeeking"
  | "onSeeked"
  | "onAbort"
  | "onError"
  | "onLoadStart"
  | "onLoadedMetaData"
  | "onLoadedData"
  | "onPlaying"
  | "onSuspend"
  | "onListen"
  | "onWaiting"
  | "onVolumeChange"

export type AudioPlayerWrapperProps = {
  src: string
  autoPlay: boolean
  onPlay?: (e: Event) => void
  onPause?: (e: Event) => void
  onListen?: (e: Event) => void
  onEnded?: (e: Event) => void
  onSeeking?: (e: Event) => void
  onSeeked?: (e: Event) => void
  onAbort?: (e: Event) => void
  onError?: (e: Event) => void
  onLoadStart?: (e: Event) => void
  onLoadedMetaData?: (e: Event) => void
  onLoadedData?: (e: Event) => void
  onPlaying?: (e: Event) => void
  onSuspend?: (e: Event) => void
  onWaiting?: (e: Event) => void
  onVolumeChange?: (e: Event) => void
}
export type AudioPlayerWrapperState = {
  volume: number
}

export class AudioPlayerWrapper extends React.Component<
  AudioPlayerWrapperProps,
  AudioPlayerWrapperState
> {
  private readonly logger: Logger
  public static readonly STORAGE_KEY_VOLUME = "seedbox_library_volume"
  private storage: IStorage

  constructor(props: AudioPlayerWrapperProps) {
    super(props)
    this.logger = Logging.getLogger("player.AudioPlayer")
    this.storage = StorageHelper.with(StorageSource.WINDOW_LOCAL)
    const volume = parseFloat(
      window.localStorage.getItem(AudioPlayerWrapper.STORAGE_KEY_VOLUME) || "0.5"
    )
    this.state = {
      volume: volume
    }
  }

  render() {
    return (
      <div className={styles.AudioPlayerWrapper}>
        <AudioPlayer
          autoPlay
          src={this.props.src}
          volume={this.state.volume || undefined}
          onPlay={e => this.onEvent("onPlay", e)}
          onPause={e => this.onEvent("onPause", e)}
          onEnded={e => this.onEvent("onEnded", e)}
          onSeeking={e => this.onEvent("onSeeking", e)}
          onSeeked={e => this.onEvent("onSeeked", e)}
          onAbort={e => this.onEvent("onAbort", e)}
          onError={e => this.onEvent("onError", e)}
          onLoadStart={e => this.onEvent("onLoadStart", e)}
          onLoadedMetaData={e => this.onEvent("onLoadedMetaData", e)}
          onLoadedData={e => this.onEvent("onLoadedData", e)}
          onPlaying={e => this.onEvent("onPlaying", e)}
          onSuspend={e => this.onEvent("onSuspend", e)}
          onWaiting={e => this.onEvent("onWaiting", e)}
          onVolumeChange={e => this.onEvent("onVolumeChange", e)}
          onListen={e => this.onEvent("onListen", e)}
        />
      </div>
    )
  }

  private onEvent(type: AudioPlayerEvents, e: Event) {
    this.logger.trace(type, e)
    try {
      // @ts-ignore
      this[type](e)
    } catch (err) {
      //
    }
    if (containsMethod(this.props, type)) {
      // @ts-ignore
      this.props[type](e)
    }
  }

  onVolumeChange(e: Event) {
    const volume = (e.target as any)?.volume
    if (volume) {
      this.logger.debug("Volume:", volume)
      void this.storage.set(AudioPlayerWrapper.STORAGE_KEY_VOLUME, volume).then(() => {
        this.setState({volume})
      })
    }
  }
}
