import {Globals} from "@damntools.fr/react-globals"

export const SlskdPage = () => {
  // eslint-disable-next-line no-undef
  // const url =
  //   (window as any).GlobalVariables.slskdUrl ||
  //   "https://soulseek.seedbox.damntools.fr"
    const url = Globals.get<string>("components.slskd.url")
        .orElseReturn("https://soulseek.seedbox.damntools.fr")
  return (
    <div style={{width: "100%", height: "100%"}}>
      <iframe title={"Slskd"} src={url} />
    </div>
  )
}

