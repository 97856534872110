import {Enum} from "@damntools.fr/types"
import {png_album, png_cascade, png_filetree, png_genre, png_list} from "../../common"

export class LibraryNavigationViewType extends Enum<string> {
  static readonly RESOURCE_LIST = new LibraryNavigationViewType(
    "RESOURCE_LIST",
    "All listed",
    png_list
  )
  static readonly FILE_TREE = new LibraryNavigationViewType(
    "FILE_TREE",
    "File tree",
    png_filetree
  )
  static readonly ALBUM_LIST = new LibraryNavigationViewType(
    "ALBUM_LIST",
    "Album covers",
    png_album
  )
  static readonly GENRE_LIST = new LibraryNavigationViewType(
    "GENRE_LIST",
    "Genres",
    png_genre
  )
  static readonly CASCADE = new LibraryNavigationViewType(
    "CASCADE",
    "Cascade selection",
    png_cascade
  )
  private readonly _display: string
  private readonly _icon: string

  constructor(key: string, display: string, icon: string) {
    super(key)
    this._display = display
    this._icon = icon
  }

  public display() {
    return this._display
  }

  public icon() {
    return this._icon
  }
}
