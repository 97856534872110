import React from "react"
import styles from "./ResourceRatingTool.module.scss"
import {CssClass} from "@damntools.fr/utils-simple"
import {Optionable, Optional} from "@damntools.fr/types"
import {MaskedImage, RgbValue} from "@damntools.fr/react-utils"

export type ResourceRatingToolProps = {
  value: number
  onChange: (value: Optionable<number>) => void
}
export type ResourceRatingToolState = {
  starWidth: number
  hovers: {[key: number]: boolean}
}

export class ResourceRatingTool extends React.Component<
  ResourceRatingToolProps,
  ResourceRatingToolState
> {
  private container: HTMLDivElement | null = null

  constructor(props: ResourceRatingToolProps) {
    super(props)
    this.state = {
      starWidth: 0,
      hovers: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false
      }
    }
  }

  componentDidMount() {
    const width = this.container?.clientWidth
    if (width) {
      const starWidth = (width - 6) / 5
      if (this.state.starWidth !== starWidth) this.setState({starWidth})
    }
  }

  render() {
    const value = (this.props.value || 0) / 2
    return (
      <div className={styles.ResourceRatingTool} ref={r => (this.container = r)}>
        <div>
          {this.getStar(value, 0)}
          {this.getStar(value, 1)}
          {this.getStar(value, 2)}
          {this.getStar(value, 3)}
          {this.getStar(value, 4)}
        </div>
      </div>
    )
  }

  // @ts-ignore
  private getStar(value: number, index: number) {
    const hovered = this.state.hovers[index]
    const classes = CssClass.from(styles.Star)
      .classIf(styles.FullStar, index < value)
      .classIf(styles.Hovered, hovered)
      .get()
    return (
      <div
        className={classes}
        onMouseEnter={() => this.onMouseHover(index)}
        onMouseLeave={() => this.onMouseHover(index)}
        onClick={e => this.onClickStar(e, index)}>
        <MaskedImage
          title={"Hey"}
          className={"MaskedImage"}
          width={this.state.starWidth + "px"}
          color={this.getColor(index)}
          icon={
            index < value || hovered
              ? "https://icons.iconarchive.com/icons/bootstrap/bootstrap/48/Bootstrap-star-fill-icon.png"
              : "https://icons.iconarchive.com/icons/bootstrap/bootstrap/48/Bootstrap-star-icon.png"
          }
        />
      </div>
    )
  }

  private getColor(index: number): RgbValue {
    switch (index) {
      case 1:
        return {red: 117, green: 143, blue: 36, alpha: 1}
      case 2:
        return {red: 196, green: 165, blue: 45, alpha: 1}
      case 3:
        return {red: 183, green: 100, blue: 35, alpha: 1}
      case 4:
        return {red: 190, green: 47, blue: 43, alpha: 1}
      default:
        return {red: 255, green: 255, blue: 255, alpha: 1}
    }
  }

  private onMouseHover(index: number) {
    const hovers = this.state.hovers
    hovers[index] = !hovers[index]
    for (let i = 0; i < index; i++) {
      hovers[i] = !hovers[i]
    }
    this.setState({hovers})
  }

  private onClickStar(e: React.MouseEvent<HTMLDivElement>, index: number) {
    e.stopPropagation()
    if (e.type === "click") {
      this.props.onChange(Optional.of((index + 1) * 2))
    } else if (e.type === "contextmenu") {
      this.props.onChange(Optional.empty())
    }
  }
}
