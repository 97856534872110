import React from "react"
import {ArrayList, List} from "@damntools.fr/types"

export const LibrarySelectionContext = React.createContext(
  {} as LibrarySelectionProviderState
)

export const LibrarySelectionConsumer = LibrarySelectionContext.Consumer

export type LibrarySelectionProviderState = {
  setSelection: (selection: List<number>) => Promise<any>
  selected: List<number>
}

export class LibrarySelectionProvider extends React.Component<
  any,
  LibrarySelectionProviderState
> {
  private static INSTANCE: LibrarySelectionProvider | null = null

  state: LibrarySelectionProviderState = {
    selected: new ArrayList(),
    setSelection: (selection: List<number>) => {
      return new Promise<void>(resolve =>
        this.setState({selected: selection.copy()}, resolve)
      )
    }
  }

  constructor(props: any) {
    super(props)
    LibrarySelectionProvider.INSTANCE = this
  }

  render() {
    return (
      <LibrarySelectionContext.Provider value={this.state}>
        {this.props.children}
      </LibrarySelectionContext.Provider>
    )
  }
}
