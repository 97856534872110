import {
  DownloadStatus,
  PlaylistDownloadExec,
  PlaylistDownloadExecCtor,
  PlaylistDownloadExecDto,
  TrackDownloadExec,
  TrackDownloadExecCtor,
  TrackDownloadExecDto
} from "../models"
import {YoutubeResourceInfoMapper} from "./YoutubeResourceInfoMapper"
import {Collectors, List, Lists} from "@damntools.fr/types"

export class YoutubeDownloadMapper {
  static INSTANCE: YoutubeDownloadMapper | null = null
  private readonly resourceMapper: YoutubeResourceInfoMapper

  constructor() {
    this.resourceMapper = YoutubeResourceInfoMapper.get()
  }

  trackFromDto(dto: TrackDownloadExecDto): TrackDownloadExec {
    const ctor: TrackDownloadExecCtor = {
      resourceDuration: dto.resourceDuration,
      title: dto.title,
      uploader: dto.uploader,
      uploaderId: dto.uploaderId,
      viewCount: dto.viewCount,
      currentFile: dto.currentFile,
      downloadedFormats: Lists.from(dto.downloadedFormats),
      duration: dto.duration,
      eta: dto.eta,
      finalFile: dto.finalFile,
      id: dto.id,
      playlistId: dto.playlistId,
      progression: dto.progression,
      rate: dto.rate,
      request: this.resourceMapper.requestDownloadFromDto(dto.request),
      status: DownloadStatus.fromValue(dto.status),
      step: dto.step,
      stepCount: dto.stepCount,
      stepName: dto.stepName,
      stoppedOrCancelled: dto.stoppedOrCancelled,
      totalSize: dto.totalSize
    }
    return new TrackDownloadExec(ctor)
  }

  tracksFromDto(dtos: Array<TrackDownloadExecDto>): List<TrackDownloadExec> {
    return Lists.from(dtos)
      .stream()
      .map(dto => this.trackFromDto(dto))
      .collect(Collectors.toList)
  }

  playlistFromDto(dto: PlaylistDownloadExecDto): PlaylistDownloadExec {
    const ctor: PlaylistDownloadExecCtor = {
      id: dto.id,
      itemCount: dto.itemCount,
      itemDone: dto.itemDone,
      resourceDuration: dto.resourceDuration,
      title: dto.title,
      uploader: dto.uploader,
      uploaderId: dto.uploaderId,
      request: this.resourceMapper.requestDownloadFromDto(dto.request),
      status: DownloadStatus.fromValue(dto.status),
      stoppedOrCancelled: dto.stoppedOrCancelled,
      tracks: this.tracksFromDto(dto.tracks)
    }
    return new PlaylistDownloadExec(ctor)
  }

  playlistsFromDto(dtos: Array<PlaylistDownloadExecDto>): List<PlaylistDownloadExec> {
    return Lists.from(dtos)
      .stream()
      .map(dto => this.playlistFromDto(dto))
      .collect(Collectors.toList)
  }

  static get(): YoutubeDownloadMapper {
    if (this.INSTANCE === null) this.INSTANCE = new YoutubeDownloadMapper()
    return this.INSTANCE
  }
}
