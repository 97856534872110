import React, {ReactElement} from "react"
import styles from "./LibraryResourcePropertiesForm.module.scss"
import {
  LibraryResourcePropertiesFormProps,
  LibraryResourcePropertiesFormState
} from "./LibraryResourcePropertiesForm.types"
import {LibraryResource, LibraryResourceType} from "../../../models"
import {ContentBlock, FlexRowBlock, GrowFlexCell} from "@damntools.fr/react-layout"
import {
  DropDownSelector,
  IntegerInput,
  TextArea,
  TextInput,
  VD
} from "@damntools.fr/react-inputs"
import {Lists, Optional} from "@damntools.fr/types"
import {
  AlertProvider,
  Popin,
  PopinButton,
  PopinButtonRow
} from "@damntools.fr/react-alert"
import {ResourceRatingTool} from "../../ResourceRatingTool"
import {NumberUtils} from "@damntools.fr/utils-simple"
import {GENRE_VALUES} from "./FormListValues"

export class LibraryResourcePropertiesForm extends React.Component<
  LibraryResourcePropertiesFormProps,
  LibraryResourcePropertiesFormState
> {
  state: LibraryResourcePropertiesFormState = {}

  constructor(props: LibraryResourcePropertiesFormProps) {
    super(props)
  }

  render() {
    return (
      <div className={styles.LibraryResourcePropertiesForm}>
        {this.getForm(this.props.model)}
        <div className={styles.Buttons}>
          <PopinButtonRow
            buttons={Lists.of(
              <PopinButton
                key={this.props.popinId + "ok"}
                popinId={this.props.popinId}
                action={{
                  callback: () => this.onSuccess(),
                  type: "success",
                  title: "Save"
                }}
                theme={"dark"}
              />,
              <PopinButton
                key={this.props.popinId + "cancel"}
                popinId={this.props.popinId}
                action={{
                  callback: () => this.onCancel(),
                  type: "cancel",
                  title: "Annuler"
                }}
                theme={"dark"}
              />
            )}
          />
        </div>
      </div>
    )
  }

  private getForm(resource: LibraryResource): ReactElement {
    const isAudio = resource.type.equals(LibraryResourceType.MUSIC)
    const isVideo = resource.type.equals(LibraryResourceType.VIDEO)
    const genreValues = GENRE_VALUES(this.props.provider.genres)
    return (
      <div className={styles.Form}>
        <FlexRowBlock>
          <GrowFlexCell className={styles.Left}>
            <div>
              <ContentBlock title={"Filename"}>
                <TextInput
                  dark
                  hideFormat
                  value={Optional.of(resource.filename).map(VD)}
                  onChange={value => this.onChangeValue("filename", value)}
                />
              </ContentBlock>
              <ContentBlock title={"Title"}>
                <TextInput
                  dark
                  hideFormat
                  autoSelect
                  value={Optional.of(resource.title).map(VD)}
                  onChange={value => this.onChangeValue("title", value)}
                />
              </ContentBlock>
              <ContentBlock title={"Duration"}>
                <TextInput
                  dark
                  hideFormat
                  readOnly
                  value={Optional.of(resource.duration)
                    .map(NumberUtils.formatDurationSeconds)
                    .map(VD)}
                  onChange={value => this.onChangeValue("duration", value)}
                />
              </ContentBlock>
              <ContentBlock title={"Year"}>
                <IntegerInput
                  dark
                  hideFormat
                  value={Optional.nullable(resource.year).map(VD)}
                  onChange={value => this.onChangeValue("year", value)}
                />
              </ContentBlock>
              <ContentBlock title={"Description"}>
                <TextArea
                  dark
                  value={Optional.nullable(resource.description)}
                  onChange={value => this.onChangeValue("description", value)}
                />
              </ContentBlock>
              <ContentBlock title={"Genres"}>
                <DropDownSelector
                  dark
                  values={genreValues}
                  showSearch
                  showSelection
                  dontShowValuesIfSearchEmpty
                  maxHeight={"50px"}
                  searchPlaceholder={"search"}
                  selectedValues={genreValues
                    .stream()
                    .log()
                    .filter(gv =>
                      resource.genres
                        .stream()
                        .log()
                        .findFirst(rg => rg.id === gv.compareValue)
                        .isPresent()
                    )
                    .collectArray()}
                  onChange={value => this.onChangeValue("genres", value)}
                />
              </ContentBlock>
              <ContentBlock title={"Tags"}>
                <TextInput
                  dark
                  hideFormat
                  onChange={value => this.onChangeValue("tags", value)}
                />
              </ContentBlock>
            </div>
          </GrowFlexCell>
          <GrowFlexCell className={styles.Right}>
            {isAudio ? this.getAudioElements() : null}
            {isVideo ? this.getVideoElements() : null}
            <ContentBlock title={"Rating"} className={styles.RatingItem}>
              <div>
                <ResourceRatingTool
                  value={1}
                  onChange={value => this.onChangeValue("year", value)}
                />
              </div>
            </ContentBlock>
          </GrowFlexCell>
        </FlexRowBlock>
      </div>
    )
  }

  private getVideoElements(): ReactElement {
    return (
      <div>
        <ContentBlock title={"Codecs"}>
          <TextInput
            dark
            hideFormat
            onChange={value => this.onChangeValue("codecs", value)}
          />
        </ContentBlock>
        <ContentBlock title={"Languages"}>
          <TextInput
            dark
            hideFormat
            onChange={value => this.onChangeValue("languages", value)}
          />
        </ContentBlock>
        <ContentBlock title={"Definition"}>
          <TextInput
            dark
            hideFormat
            onChange={value => this.onChangeValue("definition", value)}
          />
        </ContentBlock>
      </div>
    )
  }

  private getAudioElements(): ReactElement {
    return (
      <div>
        <ContentBlock title={"Album"}>
          <TextInput
            dark
            hideFormat
            onChange={value => this.onChangeValue("album", value)}
          />
        </ContentBlock>
        <ContentBlock title={"Album index"}>
          <TextInput
            dark
            hideFormat
            onChange={value => this.onChangeValue("albumIndex", value)}
          />
        </ContentBlock>
        <ContentBlock title={"Artists"}>
          <TextInput
            dark
            hideFormat
            onChange={value => this.onChangeValue("artists", value)}
          />
        </ContentBlock>
        <ContentBlock title={"Bpm"}>
          <IntegerInput
            dark
            hideFormat
            onChange={value => this.onChangeValue("bpm", value)}
          />
        </ContentBlock>
      </div>
    )
  }

  private onChangeValue(title: string, value: any) {
    console.log("changed", title, value)
  }

  private onSuccess() {
    /*
    return OwnedPlantDataService.get()
      .create(this.state.model.log("model").get())
      .thenDo(() =>
        AlertProvider.submit(Notification.success("Plante enregistrée avec succès"))
      )
      .thenDo(() => OwnedPlantProvider.refresh())
      .thenDo(() => AlertProvider.removeAlert(this.props.popinId))
      .then(p => {
        if (open) {
          window.location.hash = `#/plants/${p.id}/form`
        }
      })
      .catch((err: HttpWrapperError) => {
        HttpErrorUtils.logAndAlertError(err, "Impossible d'enregistrer !")
      })*/
  }

  private onCancel() {
    void AlertProvider.removeAlert(this.props.popinId)
  }
}

export const openLibraryResourcePropertiesForm = (
  props: Omit<LibraryResourcePropertiesFormProps, "popinId">
) => {
  return AlertProvider.submit(
    Popin.title("Resource properties")
      .DisableActions()
      .Content(id => <LibraryResourcePropertiesForm {...props} popinId={id} />)
  )
}
