import {Enum} from "@damntools.fr/types"

export class YoutubeResourceType extends Enum<string> {
  static TRACK = new YoutubeResourceType("TRACK", "v")
  static PLAYLIST = new YoutubeResourceType("PLAYLIST", "list")

  readonly queryParam: string

  constructor(value: string, queryParam: string) {
    super(value)
    this.queryParam = queryParam
  }
}
