import React from "react"
import styles from "./LibraryPlaylistInfoZone.module.scss"
import {LibraryResourceConsumer} from "../../../providers"
import {MediaPlayerConsumer} from "../../../../player"
import {CssClass, TimeUtils} from "@damntools.fr/utils-simple"

export type LibraryPlaylistInfoZoneProps = any
export type LibraryPlaylistInfoZoneState = {
  selected: "current" | "selection"
}

export class LibraryPlaylistInfoZone extends React.Component<
  LibraryPlaylistInfoZoneProps,
  LibraryPlaylistInfoZoneState
> {
  render() {
    return (
      <LibraryResourceConsumer>
        {({resources}) => {
          return (
            <MediaPlayerConsumer>
              {({playlist}) => {
                // const playlistService = new MediaPlayerPlaylistService(playlist)
                resources == null
                return (
                  <div className={styles.LibraryPlaylistInfoZone}>
                    <div>
                      <table>
                        <tbody>
                          {playlist
                            .stream()
                            .sortWith("index")
                            .map(elem => {
                              return (
                                <tr
                                  key={`${elem.resource.type.key()}_${elem.resource.id}_${
                                    elem.index
                                  }`}
                                  className={CssClass.classIf(
                                    styles.Playing,
                                    elem.playing
                                  ).get()}>
                                  <td className={styles.InfoIndex}>
                                    <span>{elem.index}</span>
                                  </td>
                                  <td className={styles.InfoTitle}>
                                    <span>{elem.resource.title}</span>
                                  </td>
                                  <td className={styles.InfoDuration}>
                                    <span>
                                      {TimeUtils.secondsToDuration(
                                        elem.resource.duration
                                      )}
                                    </span>
                                  </td>
                                </tr>
                              )
                            })
                            .collectArray()}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )
              }}
            </MediaPlayerConsumer>
          )
        }}
      </LibraryResourceConsumer>
    )
  }
}
