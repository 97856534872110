import React, {ReactElement} from "react"
import {
  YoutubePlaylistDownloadFormProvider,
  YoutubeProvider,
  YoutubeTrackDownloadFormProvider
} from "../providers"
import {YoutubeDownloads} from "./YoutubeDownloads"
import {YoutubeTrackDownloadForm} from "./YoutubeTrackDownloadForm"
import {YoutubeLinks} from "./YoutubeLinks"
import {YoutubePlaylistDownloadForm} from "./YoutubePlaylistDownloadForm"

export type YoutubePageHolderProps = {
  child: ReactElement
}

export const YoutubePage = ({child}: YoutubePageHolderProps) => {
  return (
    <YoutubeProvider>
      <YoutubeLinks />
      {child}
    </YoutubeProvider>
  )
}
export const YoutubeDownloadsPage = () => {
  return <YoutubePage child={<YoutubeDownloads />} />
}
export const YoutubeTrackRequestFormPage = () => {
  return (
    <YoutubePage
      child={
        <YoutubeTrackDownloadFormProvider>
          <YoutubeTrackDownloadForm />
        </YoutubeTrackDownloadFormProvider>
      }
    />
  )
}
export const YoutubePlaylistRequestFormPage = () => {
  return (
    <YoutubePage
      child={
        <YoutubePlaylistDownloadFormProvider>
          <YoutubePlaylistDownloadForm />
        </YoutubePlaylistDownloadFormProvider>
      }
    />
  )
}
