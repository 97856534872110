import {YoutubeFormatType} from "../YoutubeFormatType"

export type YoutubeFormatCtor = {
    code: string
    extension: string
    type: YoutubeFormatType
    resolution?: string
    resolutionSize?: number
    frameRate?: number
    audioFrequency?: number
    size: number
    best: boolean
}
export type YoutubeFormatDto = {
    code: string
    extension: string
    type: string
    resolution?: string
    resolutionSize?: number
    frameRate?: number
    audioFrequency?: number
    size: number
    best: boolean
}

export class YoutubeFormat {
    code: string
    extension: string
    type: YoutubeFormatType
    resolution: string | undefined
    resolutionSize: number | undefined
    frameRate: number | undefined
    audioFrequency: number | undefined
    size: number
    best: boolean

    constructor(obj?: YoutubeFormatCtor) {
        obj = obj || {} as YoutubeFormatCtor
        this.code = obj.code
        this.extension = obj.extension
        this.type = obj.type
        this.resolution = obj.resolution
        this.resolutionSize = obj.resolutionSize
        this.frameRate = obj.frameRate
        this.audioFrequency = obj.audioFrequency
        this.size = obj.size
        this.best = obj.best
    }
}
