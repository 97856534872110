import {
  ScannedFilesystemResource,
  ScannedFilesystemResourceCtor,
  ScannedFilesystemResourceDto
} from "./ScannedFilesystemResource"

export interface ScannedDirectoryResourceDto extends ScannedFilesystemResourceDto {
  count: number
}

export interface ScannedDirectoryResourceCtor extends ScannedFilesystemResourceCtor {
  count: number
}

export class ScannedDirectoryResource extends ScannedFilesystemResource {
  count: number

  constructor(obj: ScannedDirectoryResourceCtor) {
    super(obj)
    this.count = obj.count
  }
}
