import React from "react"
import styles from "./LibraryAltToolbar.module.scss"
import {LibraryToolbarConsumer, SetLibraryResourceType} from "../../providers"
import {LibraryNavigationViewType, LibraryResourceType} from "../../models"
import {CssClass, StringUtils} from "@damntools.fr/utils-simple"
import {FlexRowBlock, ShrinkFlexCell} from "@damntools.fr/react-layout"
import {Optionable, Optional, toList} from "@damntools.fr/types"
import {png_asterisk} from "../../../common"

export type LibraryAltToolbarProps = any
export type LibraryAltToolbarState = any

export class LibraryAltToolbar extends React.Component<
  LibraryAltToolbarProps,
  LibraryAltToolbarState
> {
  constructor(props: LibraryAltToolbarProps) {
    super(props)
  }

  render() {
    return (
      <div className={styles.LibraryAltToolbar}>
        <LibraryToolbarConsumer>
          {({navigationViewType, setViewType, resourceType, setResourceType}) => {
            return (
              <FlexRowBlock>
                <ShrinkFlexCell className={styles.ViewTypes}>
                  <div>{this.getViewTypeButtons(navigationViewType, setViewType)}</div>
                </ShrinkFlexCell>
                <ShrinkFlexCell>
                  <span className={styles.Separator}>|</span>
                </ShrinkFlexCell>
                <ShrinkFlexCell className={styles.ResourceTypes}>
                  <div>
                    <div>
                      {this.getResourceTypeButtons(resourceType, setResourceType)}
                    </div>
                  </div>
                </ShrinkFlexCell>
              </FlexRowBlock>
            )
          }}
        </LibraryToolbarConsumer>
      </div>
    )
  }

  private getViewTypeButtons(
    navigationViewType: LibraryNavigationViewType,
    setViewType: (type: LibraryNavigationViewType) => void
  ) {
    return LibraryNavigationViewType.all<LibraryNavigationViewType>()
      .stream()
      .sort((a, b) => a.ordinal() - b.ordinal())
      .map(type => {
        const classes = CssClass.from(styles.TypeButton)
          .classIf(styles.SelectedTypeButton, type.equals(navigationViewType))
          .get()
        return (
          <div
            key={type.key()}
            title={type.display()}
            className={classes}
            onClick={() => setViewType(type)}>
            <span></span>
            <img src={type.icon()} alt={"View type"} />
            <span>{type.display()}</span>
          </div>
        )
      })
      .collectArray()
  }

  private getResourceTypeButtons(
    resourceType: Optionable<LibraryResourceType>,
    setResourceType: SetLibraryResourceType
  ) {
    return LibraryResourceType.all<LibraryResourceType>()
      .stream()
      .sort((a, b) => a.ordinal() - b.ordinal())
      .map(type => {
        const classes = CssClass.from(styles.TypeButton)
          .classIf(
            styles.SelectedTypeButton,
            resourceType.filter(t => t.equals(type)).isPresent
          )
          .get()
        return (
          <div
            key={type.key()}
            title={StringUtils.firstToUpper(type.key())}
            className={classes}
            onClick={() => setResourceType(Optional.of(type))}>
            <span></span>
            <img src={type.icon()} alt={"Resource type"} />
            <span>{StringUtils.firstToUpper(type.key())}</span>
          </div>
        )
      })
      .collect(toList)
      .insert(0, [
        <div
          key={"all"}
          title={"All"}
          className={CssClass.from(styles.TypeButton)
            .classIf(styles.SelectedTypeButton, resourceType.isEmpty)
            .get()}
          onClick={() => setResourceType(Optional.empty())}>
          <span></span>
          <img src={png_asterisk} alt={"Resource type"} />
          <span>{"All"}</span>
        </div>
      ])
      .getInner()
  }
}
