import React, {ReactElement} from "react"
import {TextInput} from "@damntools.fr/react-inputs"
import styles from "./ScannedDirectoryResourceView.module.scss"
import {
  Collectors,
  compare,
  compareStringsIgnoreCase,
  List,
  Optionable,
  Optional,
  toList
} from "@damntools.fr/types"
import {
  FileResourceType,
  png_delete,
  png_download,
  png_outline_folder_black_18dp,
  png_play,
  png_play_last,
  png_play_next,
  png_stream,
  ScannedDirectoryResource,
  ScannedFilesystemResource,
  UrlUtils,
  WithAdminRights,
  WithElevatedRights
} from "../../../common"
import {CssClass, NumberUtils, StringUtils} from "@damntools.fr/utils-simple"
import {UUID} from "@damntools.fr/identifiers"
import {LibraryResource} from "../../../library"
import {
  Table,
  TableCell,
  TableCellStyle,
  TableScroll,
  TableSelectionConfig,
  TableStyle
} from "@damntools.fr/react-layout"
import {ColorUtils, MaskedImage, StyleSize} from "@damntools.fr/react-utils"
import {ExplorerService} from "../../services"
import {AlertProvider, Notification} from "@damntools.fr/react-alert"
import entryStyles from "../Entry.module.scss"

const resourceSorter = (a: TableDataItem, b: TableDataItem) =>
  compare(a.itemType, b.itemType) || compareStringsIgnoreCase(a.title, b.title)

const resourceSearchFilter = (
  search: Optionable<string>,
  resource: ScannedFilesystemResource
) =>
  search.isEmpty() ||
  (search.isPresent() &&
    resource.title.toLowerCase().indexOf(search.get().toLowerCase()) !== -1)

export type ScannedDirectoryResourceViewProps = {
  resource: ScannedDirectoryResource
  onDelete: (resourceId: string) => void
  onPlay: (resource: LibraryResource) => void
  onPlayNext: (resource: LibraryResource) => void
  onPlayLast: (resource: LibraryResource) => void
  selectedResources: List<string>
  children: List<ScannedFilesystemResource>
}
export type ScannedDirectoryResourceViewState = {
  search: Optionable<string>
}

export type TableDataItem = {
  itemType: "dir" | "file"
  path: string
  size: number
  title: string
  parent: string
  count: number
  type: FileResourceType
  extension: string
  tags: List<string>
}

export class ScannedDirectoryResourceView extends React.Component<
  ScannedDirectoryResourceViewProps,
  ScannedDirectoryResourceViewState
> {
  constructor(props: ScannedDirectoryResourceViewProps, context: any) {
    super(props, context)
    this.state = {
      search: Optional.empty()
    }
  }

  render() {
    return (
      <div className={styles.ScannedDirectoryResourceView}>
        <div className={styles.DirectoryInfo}>
          <div
            onClick={() => {
              if (!this.props.resource.parent) {
                window.location.hash = `#/explorer/`
              } else {
                window.location.hash = `#/explorer/dir/${encodeURIComponent(
                  this.props.resource.parent
                )}`
              }
            }}>
            <div>
              <MaskedImage
                width={"25px"}
                title={""}
                color={ColorUtils.colorToRGBA("#71a114")}
                icon={
                  "https://icons.iconarchive.com/icons/arturo-wibawa/akar/48/arrow-back-thick-fill-icon.png"
                }
              />
            </div>
          </div>
          <div>
            <strong>Location : </strong>
            <span>{this.getLocation()}</span>
            <span>...</span>
            <strong style={{color: "#ffffff"}}>
              {this.props.resource.getCleanSize()}
            </strong>
          </div>
        </div>
        <div>
          <TextInput
            dark
            onChange={value => this.setState({search: value})}
            placeholder={"Filter..."}
            hideFormat
            focus
          />
        </div>
        <div className={styles.ResourceDiv}>
          <div>{this.getTable()}</div>
        </div>
      </div>
    )
  }

  getTable() {
    const cellClasses = CssClass.from(styles.TableCell)
    const headerClasses = cellClasses.with(styles.HeaderCell)
    const data = this.childrenToTableData(this.props.children)

    return (
      <Table data={data} rowIdentifierFn={r => r.path}>
        <TableSelectionConfig showCheckbox={false} />
        <TableScroll scrollDownElementAddCount={50} />
        <TableStyle
          classNames={cellClasses}
          headerClassNames={headerClasses}
          rowHeight={StyleSize.px(25)}
        />

        <TableCell
          title={"Type"}
          valueLoader={() => ""}
          displayFn={(r: TableDataItem) => (
            <div>
              <img
                style={{verticalAlign: "middle"}}
                width={"25px"}
                src={
                  r.itemType === "dir"
                    ? png_outline_folder_black_18dp
                    : ExplorerService.getImageForType(r.type.key())
                }
                alt={r.itemType}
                title={StringUtils.firstToUpper(r.itemType)}
              />
            </div>
          )}>
          <TableCellStyle
            classNames={styles.IconCell}
            headerClassNames={styles.IconHeaderCell}
            align={"center"}
            headerAlign={"center"}
            width={StyleSize.px(60)}
          />
        </TableCell>
        <TableCell
          title={"Title"}
          rowField={"title"}
          disableSelectionOnClick
          sorted
          sortFn={resourceSorter}
          onClick={r => {
            console.log(r)
            if (r.itemType === "dir")
              window.location.hash = `#/explorer/dir/${encodeURIComponent(r.path)}`
            return Promise.resolve()
          }}>
          <TableCellStyle align={"left"} />
        </TableCell>
        <TableCell
          title={"Size"}
          sorted
          rowField={"size"}
          disableSelectionOnClick
          displayFn={(row: TableDataItem) => {
            const format = NumberUtils.formatBytes(row.size || 0, 0).split(" ")
            return (
              <span className={styles.SizeStrong}>
                <span>{format[0]}</span>
                <span>{format[1].at(0)}</span>
                {format[1].at(1)}
              </span>
            )
          }}
          valueLoader={(row: TableDataItem) => row.size || undefined}>
          <TableCellStyle
            headerAlign={"center"}
            align={"center"}
            width={StyleSize.px(80)}
          />
        </TableCell>
        <TableCell
          disableSelectionOnClick
          title={"Count"}
          rowField={"count"}
          sorted
          displayFn={(r: TableDataItem) => (r.count && r.count + " files") || ""}>
          <TableCellStyle
            headerAlign={"center"}
            align={"center"}
            width={StyleSize.px(80)}
          />
        </TableCell>
        <TableCell
          disableSelectionOnClick
          title={"Tags"}
          rowField={"tags"}
          displayFn={ScannedDirectoryResourceView.getTagCellDisplay}>
          <TableCellStyle
            headerAlign={"center"}
            align={"left"}
            width={StyleSize.px(100)}
          />
        </TableCell>
        <TableCell
          disableSelectionOnClick
          title={"Actions"}
          rowField={"path"}
          displayFn={(r: TableDataItem) => this.getActionCellDisplay(r)}>
          <TableCellStyle
            align={"right"}
            width={StyleSize.px(140)}
            headerAlign={"center"}
          />
        </TableCell>
      </Table>
    )
  }

  static getTagCellDisplay(r: TableDataItem): ReactElement {
    if (r.itemType === "dir") {
      return <div></div>
    } else {
      return (
        <div className={styles.TagIcons}>
          {r.tags &&
            r.tags
              .stream()
              .map((x: string) => (
                <span key={"Tag" + x}>
                  <img src={ExplorerService.getIconForTag(x)} alt={x} title={x} />
                </span>
              ))
              .collectArray()}
        </div>
      )
    }
  }

  getActionCellDisplay(r: TableDataItem): ReactElement {
    if (r.itemType === "dir") {
      return (
        <div className={styles.Actions}>
          <WithElevatedRights>
            <span
              className={CssClass.from(entryStyles.DownloadButton)
                // .classIf(() => entryStyles.Unclickable, this.state.zipCreation as any)
                .get()}
              onClick={() => this.onDirectoryDownloadClick()}>
              <img src={png_download} alt={"Download as ZIP"} title="Download as ZIP" />
            </span>
          </WithElevatedRights>
          <WithAdminRights>
            <span
              className={entryStyles.DeleteButton}
              onClick={() => this.props.onDelete(this.props.resource.path)}>
              <img src={png_delete} alt={"Delete"} title="Delete" />
            </span>
          </WithAdminRights>
        </div>
      )
    } else {
      const isMusic = r.type.equals(FileResourceType.MUSIC)
      return (
        <div className={styles.Actions}>
          {ExplorerService.isStreamable(r.type)
            ? this.getButton(
                isMusic ? "Play" : "Stream",
                () =>
                  ExplorerService.findLibraryResourcePath(r.path).then(r =>
                    this.props.onPlay(r)
                  ),
                isMusic ? png_play : png_stream,
                styles.StreamButton
              )
            : null}
          {ExplorerService.isStreamable(r.type) && isMusic
            ? this.getButton(
                "Play next",
                () =>
                  ExplorerService.findLibraryResourcePath(r.path).then(r =>
                    this.props.onPlayNext(r)
                  ),
                png_play_next,
                styles.StreamButton
              )
            : null}
          {ExplorerService.isStreamable(r.type) && isMusic
            ? this.getButton(
                "Play last",
                () =>
                  ExplorerService.findLibraryResourcePath(r.path).then(r =>
                    this.props.onPlayLast(r)
                  ),
                png_play_last,
                styles.StreamButton
              )
            : null}
          {this.getButton(
            "Download",
            () => this.onDownloadClick(r),
            png_download,
            styles.DownloadButton
          )}
          <WithAdminRights>
            {this.getButton(
              "Delete",
              () => this.props.onDelete(r.path),
              png_delete,
              styles.DeleteButton
            )}
          </WithAdminRights>
        </div>
      )
    }
  }

  getButton(title: string, onClick: () => any, icon: string, className: string) {
    return (
      <span className={CssClass.from(className).get()} onClick={() => onClick()}>
        <img src={icon} alt={title} title={title} />
      </span>
    )
  }

  getLocation(): Array<ReactElement> {
    const isLinux = this.props.resource.path.includes("/")
    return this.props.resource.path
      .split(/[/\\]/g)
      .map((part, i, arr) => {
        const link = arr.slice(0, i + 1).join(isLinux ? "/" : "\\")
        return (
          <span
            key={part}
            onClick={() => {
              if (i > 0 && i < arr.length - 1) {
                window.location.hash = `#/explorer/dir/${encodeURIComponent(link)}`
              }
            }}
            className={CssClass.from(styles.PathPart)
              .classIf(styles.PathPartLast, i === arr.length - 1)
              .classIf(styles.PathPartClickable, i > 0 && i < arr.length - 1)
              .get()}>
            {part}
          </span>
        )
      })
      .toList()
      .stream()
      .collect(
        Collectors.joining(() => (
          <span key={UUID.random()} className={styles.PathSeparator}>
            /
          </span>
        ))
      )
      .getInner()
  }

  private childrenToTableData(
    children: List<ScannedFilesystemResource>
  ): List<TableDataItem> {
    return children
      .stream()
      .filter(d => resourceSearchFilter(this.state.search, d))
      .map(c => {
        return {
          ...c,
          itemType: c instanceof ScannedDirectoryResource ? "dir" : "file",
          onDelete: this.props.onDelete,
          onPlay: this.props.onPlay,
          onPlayNext: this.props.onPlayNext,
          onPlayLast: this.props.onPlayLast
        } as any
      })
      .sort(resourceSorter)
      .collect(toList)
  }

  private onDownloadClick(r: TableDataItem) {
    ExplorerService.get()
      .requestResourceDownload({path: r.path} as any)
      .then(request => {
        if (request.isReady()) {
          const url = ExplorerService.getDownloadUrl({path: r.path} as any, request)
          console.log("Opening", url)
          UrlUtils.openUrlWithFilename(url.url, url.name)
        } else {
          throw new Error("Resource is not ready for download")
        }
      })
      .catch(err => {
        console.error(err)
        AlertProvider.submit(
          Notification.error("Could not start file download !").Subtitle(err?.message)
        )
      })
  }

  private onDirectoryDownloadClick() {
    //
  }
}
