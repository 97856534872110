import React from "react"
import styles from "./LibraryHome.module.scss"
import {
  FlexCell,
  FlexColumnBlock,
  FlexRowBlock,
  ShrinkFlexCell
} from "@damntools.fr/react-layout"
import {LibraryAltToolbar} from "../LibraryAltToolbar"
import {
  LibraryResourceProvider,
  LibrarySelectionProvider,
  LibraryToolbarProvider
} from "../../providers"
import {LibraryMainToolbar} from "../LibraryMainToolbar"
import {LibraryViewSelector} from "../views"
import {LibraryFooter} from "../LibraryFooter"
import {RouteComponentProps} from "react-router"
import {InfoPanel} from "../info"

export type LibraryHomeProps = {
  route: RouteComponentProps
}
export type LibraryHomeState = {
  totalWidth: number
}

export class LibraryHome extends React.Component<LibraryHomeProps, LibraryHomeState> {
  state = {
    totalWidth: 0
  }
  private ref: HTMLDivElement | null = null

  render() {
    const playerWidth = this.getPlayerWidth()
    const tableWidth = this.getTableWidth(playerWidth)
    return (
      <div className={styles.LibraryHome} ref={r => (this.ref = r)}>
        <LibraryToolbarProvider route={this.props.route}>
          <LibrarySelectionProvider>
            <LibraryResourceProvider>
              <div>
                <FlexColumnBlock>
                  <ShrinkFlexCell className={styles.Toolbars}>
                    <div>
                      <LibraryMainToolbar />
                      <LibraryAltToolbar />
                    </div>
                  </ShrinkFlexCell>
                  <FlexCell grow={1} shrink={1} hideOverflow className={styles.MainZone}>
                    <FlexRowBlock>
                      <FlexCell
                        shrink={1}
                        grow={1}
                        hideOverflow
                        // width={this.getTableWidth()}
                        className={styles.NavigationView}>
                        <div style={{width: tableWidth ? tableWidth + "px" : "unset"}}>
                          <LibraryViewSelector width={tableWidth} />
                        </div>
                      </FlexCell>
                      <FlexCell grow={1} shrink={1} className={styles.PlayerInfo}>
                        <div style={{width: playerWidth ? playerWidth + "px" : "unset"}}>
                          <InfoPanel />
                        </div>
                      </FlexCell>
                    </FlexRowBlock>
                  </FlexCell>
                  <ShrinkFlexCell>
                    <LibraryFooter />
                  </ShrinkFlexCell>
                </FlexColumnBlock>
              </div>
            </LibraryResourceProvider>
          </LibrarySelectionProvider>
        </LibraryToolbarProvider>
      </div>
    )
  }

  componentDidMount() {
    const totalWidth = this.ref?.offsetWidth || 0
    this.setState({totalWidth})
  }

  private getPlayerWidth() {
    if (this.state.totalWidth > 0 && this.state.totalWidth <= 1610) {
      return parseInt((25 / 100) * this.state.totalWidth + "")
    } else if (this.state.totalWidth > 1610) {
      return parseInt((25 / 100) * this.state.totalWidth + "")
    }
    return 0
  }

  private getTableWidth(playerWidth: number) {
    if (this.state.totalWidth) return this.state.totalWidth - 2 - playerWidth
    return 0
  }
}
