import {List, toList} from "@damntools.fr/types"
import {
  LibraryAlbumDto,
  LibraryArtistDto,
  LibraryGenreDto,
  LibraryTagDto
} from "../../../models"
import {VD} from "@damntools.fr/react-inputs"
import {
  png_tag_codec_aac,
  png_tag_codec_ac3,
  png_tag_codec_h264,
  png_tag_codec_h265,
  png_tag_codec_x264,
  png_tag_codec_x265,
  png_tag_lg_fr,
  png_tag_lg_multi,
  png_tag_lg_vo,
  png_tag_lg_vostfr
} from "../../../../common";

export const TAG_VALUES = (tags: List<LibraryTagDto>) =>
  tags
  .stream()
  .map(t => VD(t).Compare(t.id).Display(t.name).Sort(t.name))
  .collect(toList)
export const LANGUAGE_VALUES = (languages: List<string>) =>
  languages
  .stream()
  .map(l => {
    let value = VD(l)
    if (l === "MULTI")
      value = value.Icon(png_tag_lg_multi)
    else if (l === "VOSTFR")
      value = value.Icon(png_tag_lg_vostfr)
    else if (l === "VFF")
      value = value.Icon(png_tag_lg_fr)
    else if (l === "VOST")
      value = value.Icon(png_tag_lg_vo)
    return value
  })
  .collect(toList)
export const CODEC_VALUES = (tags: List<string>) =>
  tags
  .stream()
  .map(l => {
    let value = VD(l)
    if (l === "X265")
      value = value.Icon(png_tag_codec_x265)
    else if (l === "X264")
      value = value.Icon(png_tag_codec_x264)
    else if (l === "H264")
      value = value.Icon(png_tag_codec_h264)
    else if (l === "H265")
      value = value.Icon(png_tag_codec_h265)
    else if (l === "AAC")
      value = value.Icon(png_tag_codec_aac)
    else if (l === "AC3")
      value = value.Icon(png_tag_codec_ac3)
    return value
  })
  .collect(toList)
export const GENRE_VALUES = (genres: List<LibraryGenreDto>) =>
  genres
  .stream()
  .map(g => VD(g).Compare(g.id).Display(g.name).Sort(g.name))
  .collect(toList)

export const ARTIST_VALUES = (artists: List<LibraryArtistDto>) =>
  artists
  .stream()
  .map(a => VD(a).Compare(a.id).Display(a.name).Sort(a.name))
  .collect(toList)

export const ALBUM_VALUES = (albums: List<LibraryAlbumDto>) =>
  albums
  .stream()
  .map(a => VD(a).Compare(a.id).Display(`${a.name} ${a.year}`).Sort(a.name))
  .collect(toList)
