import React from "react"
import {HashRouter, Route, Switch} from "react-router-dom"
import styles from "./App.module.scss"
import {
  YoutubeDownloadsPage,
  YoutubePlaylistRequestFormPage,
  YoutubeTrackRequestFormPage
} from "../../../youtube"
import {ExplorerBlock, RootView} from "../../../explorer"
import {HomePage, SlskdPage, SoulseekPage, TransmissionPage} from "../../pages"
import {LibraryHome} from "../../../library"
import {AppContent} from "../AppContent"

export class App extends React.Component {
  render() {
    return (
      <HashRouter>
        <div className={styles.Content}>
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route
              exact
              path="/explorer"
              render={props => (
                <AppContent route={props} title={"Explorer"}>
                  <RootView />
                </AppContent>
              )}
            />
            <Route
              path="/explorer/:type/:id"
              render={props => (
                <AppContent route={props} title={"Explorer"}>
                  {props.match.params.type === "dir" ? (
                    <ExplorerBlock {...props} />
                  ) : (
                    <div></div>
                  )}
                </AppContent>
              )}
            />
            <Route
              exact
              path="/youtubedl/downloads"
              render={props => (
                <AppContent route={props} title={"Youtube downloads"}>
                  <YoutubeDownloadsPage />
                </AppContent>
              )}
            />
            <Route
              path="/youtubedl/form/track"
              render={props => (
                <AppContent route={props} title={"Youtube track download request"}>
                  <YoutubeTrackRequestFormPage />
                </AppContent>
              )}
            />
            <Route
              path="/youtubedl/form/playlist"
              render={props => (
                <AppContent route={props} title={"Youtube playlist download request"}>
                  <YoutubePlaylistRequestFormPage />
                </AppContent>
              )}
            />
            <Route
              exact
              path="/youtubedl"
              render={props => (
                <AppContent route={props} title={"Youtube"}>
                  <YoutubeDownloadsPage />
                </AppContent>
              )}
            />
            <Route
              path="/library"
              render={props => (
                <AppContent route={props}>
                  <LibraryHome route={props} />
                </AppContent>
              )}
            />
            <Route path="/soulseek">
              <SoulseekPage />
            </Route>
            <Route path="/slskd">
              <SlskdPage />
            </Route>
            <Route path="/transmission">
              <TransmissionPage />
            </Route>
            {/*<Route path="/player" component={PlayerBlock}/>*/}
          </Switch>
        </div>
      </HashRouter>
    )
  }
}
