import React from "react"
import styles from "./YoutubeDownloads.module.scss"
import {YoutubeTrackDownloadEntry} from "../YoutubeTrackDownloadEntry"
import {YoutubeConsumer} from "../../providers"
import {PlaylistDownloadExec, TrackDownloadExec} from "../../models"
import {notDefined} from "@damntools.fr/types"
import {YoutubePlaylistDownloadEntry} from "../YoutubePlaylistDownloadEntry"

export class YoutubeDownloads extends React.Component<any, any> {
  render() {
    return (
      <YoutubeConsumer>
        {({downloads}) => (
          <div className={styles.YoutubeDLDownloads}>
            <div className={styles.DownloadList}>
              {downloads
                .stream()
                .filterClass(PlaylistDownloadExec)
                .sort((a, b) =>
                  a.title.toLowerCase().localeCompare(b.title.toLowerCase())
                )
                .map(download => (
                  <YoutubePlaylistDownloadEntry
                    info={download}
                    key={`PlaylistDownloadInfo${download.id}`}
                  />
                ))
                .concat(
                  downloads
                    .stream()
                    .filterClass(TrackDownloadExec)
                    .filter(t => notDefined(t.playlistId))
                    .sort((a, b) =>
                      a.title.toLowerCase().localeCompare(b.title.toLowerCase())
                    )
                    .map(download => (
                      <YoutubeTrackDownloadEntry
                        info={download}
                        showIcon
                        key={`TrackDownloadInfo${download.id}`}
                      />
                    ))
                )
                .collectArray()}
            </div>
          </div>
        )}
      </YoutubeConsumer>
    )
  }
}
