import {YoutubeFormat, YoutubeFormatDto} from "./YoutubeFormat"
import {ResourceInfo, ResourceInfoCtor, ResourceInfoDto} from "./ResourceInfo"
import {ArrayList, List} from "@damntools.fr/types"


export type ThumbnailInfoDto = {
  url: string
  width: number
  height: number
}

export type TrackInfoDto = ResourceInfoDto & {
  index: number
  formats: Array<YoutubeFormatDto>
  thumbnail: ThumbnailInfoDto
  viewCount: number
}

export type TrackInfoCtor = ResourceInfoCtor & {
  index: number
  formats: List<YoutubeFormat>
  thumbnail?: ThumbnailInfoDto
  viewCount: number
}

export class TrackInfo extends ResourceInfo {
  index: number
  formats: List<YoutubeFormat>
  thumbnail?: ThumbnailInfoDto
  viewCount: number

  constructor(obj: TrackInfoCtor  ) {
    super(obj)
    this.index = obj.index
    this.viewCount = obj.viewCount
    this.thumbnail = obj.thumbnail
    this.formats = obj.formats || new ArrayList()
  }
}
