import {DownloadExec, DownloadExecCtor, DownloadExecDto} from "./DownloadExec"
import {TrackDownloadExec, TrackDownloadExecDto} from "./TrackDownloadExec"
import {List} from "@damntools.fr/types"

export type PlaylistDownloadExecCtor = DownloadExecCtor & {
  itemCount: number
  itemDone: number
  tracks: List<TrackDownloadExec>
}

export type PlaylistDownloadExecDto = DownloadExecDto & {
  itemCount: number
  itemDone: number
  tracks: Array<TrackDownloadExecDto>
}

export class PlaylistDownloadExec extends DownloadExec {
  itemCount: number
  itemDone: number
  tracks: List<TrackDownloadExec>

  constructor(obj: PlaylistDownloadExecCtor) {
    super(obj)
    this.itemCount = obj.itemCount
    this.itemDone = obj.itemDone
    this.tracks = obj.tracks
  }
}
