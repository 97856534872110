import React, {ReactElement} from "react"
import {ArrayList, List, Optionable, Optional} from "@damntools.fr/types"
import {
  ScannedDirectoryResource,
  ScannedFilesystemResource,
  ScannedRootDirectoryResource
} from "../../common"
import {ExplorerService} from "../services"
import {AlertProvider, Notification, Popin} from "@damntools.fr/react-alert"
import {RouteComponentProps} from "react-router"
import {hasChanged} from "@damntools.fr/react-utils"
import {Logger, Logging} from "@damntools.fr/logger-simple"

export type ExplorerProviderState = {
  roots: List<ScannedRootDirectoryResource>
  children: List<ScannedFilesystemResource>
  currentResource: Optionable<ScannedFilesystemResource>
  currentType: Optionable<"dir" | "file">
  selectedResources: List<string>

  refreshRoots: () => Promise<List<ScannedRootDirectoryResource>>
  onDelete: (resourceId: string) => void
}

export const ExplorerContext = React.createContext({} as ExplorerProviderState)

export const ExplorerConsumer = ExplorerContext.Consumer

export type ExplorerProviderProps = {
  route: RouteComponentProps
  children: ReactElement
}

export class ExplorerProvider extends React.Component<
  ExplorerProviderProps,
  ExplorerProviderState
> {
  private static INSTANCE: ExplorerProvider
  private refreshInterval: any
  private readonly logger: Logger

  constructor(props: any, context: any) {
    super(props, context)
    this.refreshRoots = this.refreshRoots.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.state = {
      currentType: Optional.empty(),
      currentResource: Optional.empty(),
      selectedResources: new ArrayList(),
      children: new ArrayList(),
      roots: new ArrayList(),

      refreshRoots: this.refreshRoots,
      onDelete: this.onDelete
    }
    this.logger = Logging.getLogger("ExplorerProvider")
    ExplorerProvider.INSTANCE = this
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval)
  }

  componentDidMount() {
    this.refreshData()
  }

  componentDidUpdate(prevProps: Readonly<ExplorerProviderProps>) {
    if (
      hasChanged(prevProps.route.location.pathname, this.props.route.location.pathname)
    ) {
      this.refreshData()
    }
  }

  refreshData() {
    this.setState(
      {
        currentResource: Optional.empty(),
        children: new ArrayList(),
        roots: new ArrayList()
      },
      () => {
        this.refreshRoots().then(() => {
          const params = this.props.route.match.params as any
          if (params["type"]) {
            return this.refreshCurrent(params["type"], params["id"])
          }
        })
      }
    )
  }

  refreshCurrent(type: "dir" | "file", id: string) {
    const path = decodeURIComponent(id)
    ExplorerService.get()
      .getResourceByPath(path)
      .then(currentResource => {
        return new Promise(resolve => {
          this.setState(
            {
              currentResource: Optional.nullable(currentResource),
              currentType: Optional.of(type)
            },
            () => resolve(currentResource)
          )
        })
      })
      .then(cur => {
        if (cur instanceof ScannedDirectoryResource) {
          return ExplorerService.get()
            .getDirectoryChildren(cur)
            .then(children => {
              return new Promise(resolve => this.setState({children}, () => resolve(cur)))
            })
        }
      })
  }

  render() {
    return (
      <ExplorerContext.Provider value={this.state}>
        {this.props.children}
      </ExplorerContext.Provider>
    )
  }

  onDelete(resourceId: string) {
    return ExplorerService.get()
      .getResourceByPath(resourceId)
      .then(res => {
        AlertProvider.submit(
          Popin.warning()
            .Content("Confirm resource delete ?")
            .SuccessAction(id =>
              ExplorerService.get()
                .deleteResource(res.path)
                .then(() => {
                  AlertProvider.submit(
                    Notification.success().Subtitle("Resource deleted !")
                  )
                  return this.refreshData()
                })
                .catch(err => {
                  this.logger.error(err)
                  AlertProvider.submit(
                    Notification.error().Subtitle("Could not delete resource !")
                  )
                })
                .then(() => AlertProvider.removeAlert(id))
            )
        )
      })
  }

  refreshRoots(): Promise<List<ScannedRootDirectoryResource>> {
    return ExplorerService.get()
      .getRoots()
      .then(roots => {
        return new Promise(resolve => {
          this.setState({roots}, () => resolve(roots))
        })
      })
      .catch(err => {
        void AlertProvider.submit(
          Notification.error().Subtitle("Could not get scanned resources")
        )
        this.logger.warn("Could not get scanned resources", err)
      }) as Promise<List<ScannedRootDirectoryResource>>
  }

  static refresh() {
    return this.INSTANCE.refreshData()
  }
}
