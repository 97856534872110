import {FlexCell, FlexColumnBlock} from "@damntools.fr/react-layout"
import {LibraryPlaylistInfoZone} from "../LibraryPlaylistInfoZone"
import {LibraryResourceInfoZone} from "../LibraryResourceInfoZone"
import styles from "./InfoPanel.module.scss"

export const InfoPanel = () => {
  return (
    <FlexColumnBlock className={styles.Playlist}>
      <FlexCell grow={1} shrink={1} className={styles.PlaylistElements} hideOverflow>
        <LibraryPlaylistInfoZone />
      </FlexCell>
      <FlexCell grow={0} shrink={0} width={"40%"} className={styles.ResourceInfo}>
        <LibraryResourceInfoZone />
      </FlexCell>
    </FlexColumnBlock>
  )
}
