import React from "react"
import styles from "./YoutubeLinks.module.scss"
import {
  png_list,
  png_outline_cloud_download_black_18dp,
  png_outline_mixed_black_18dp
} from "../../../common"

export class YoutubeLinks extends React.Component<any, any> {
  render() {
    return (
      <div className={styles.Links}>
        <div>
          <span
            className={
              window.location.hash.startsWith("#/youtubedl/downloads")
                ? styles.SelectedLink
                : undefined
            }
            onClick={() => (window.location.href = "#/youtubedl/downloads")}>
            <span></span>
            <img src={png_outline_cloud_download_black_18dp} alt={""} />
            <span>Downloads</span>
          </span>
          <span
            className={
              window.location.hash.startsWith("#/youtubedl/form/track")
                ? styles.SelectedLink
                : undefined
            }
            onClick={() => (window.location.href = "#/youtubedl/form/track")}>
            <span></span>
            <img src={png_outline_mixed_black_18dp} alt={""} />
            <span>Track download form</span>
          </span>
          <span
            className={
              window.location.hash.startsWith("#/youtubedl/form/playlist")
                ? styles.SelectedLink
                : undefined
            }
            onClick={() => (window.location.href = "#/youtubedl/form/playlist//AUDIO_BEST_MP3")}>
            <span></span>
            <img src={png_list} alt={""} />
            <span>Playlist download form</span>
          </span>
        </div>
      </div>
    )
  }
}
