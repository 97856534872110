import {RouteComponentProps} from "react-router"
import {getMenuEntries, HomePageMenuEntry} from "../component"
import styles from "./Homepage.module.scss"
import React from "react"
import {Globals} from "@damntools.fr/react-globals"

export type HomepageProps = {
  route: RouteComponentProps
}

export const HomePage = () => {
  const title = Globals.get<string>("description.title").orElseReturn("seedbox")
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        textAlign: "left"
      }}>
      <div className={styles.Menus}>
        <div>
          <div className={styles.HeaderContent}>
            <div>Welcome on the</div>
            <div>{title}</div>
          </div>
          <div className={styles.MenuEntries}>
            {getMenuEntries()
              .stream()
              .filter(e => e.enabled)
              .map(e => <HomePageMenuEntry key={e.link} {...e} />)
              .collectArray()}
          </div>
        </div>
      </div>
    </div>
  )
}
