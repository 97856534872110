import React from "react"
import styles from "./LibraryFooter.module.scss"
import {LibraryResourceConsumer} from "../../providers"
import {List} from "@damntools.fr/types"
import {
  LibraryAlbumDto,
  LibraryArtistDto,
  LibraryGenreDto,
  LibraryResource,
  LibraryTagDto
} from "../../models"
import {NumberUtils} from "@damntools.fr/utils-simple"

export type LibraryFooterProps = any
export type LibraryFooterState = any

export class LibraryFooter extends React.Component<
  LibraryFooterProps,
  LibraryFooterState
> {
  constructor(props: LibraryFooterProps) {
    super(props)
  }

  render() {
    return (
      <div className={styles.LibraryFooter}>
        <LibraryResourceConsumer>
          {({resources, albums, artists, tags, genres}) => {
            return (
              <div>
                {this.getResourceStatCartouche(resources, albums, artists, tags, genres)}
              </div>
            )
          }}
        </LibraryResourceConsumer>
      </div>
    )
  }

  private getResourceStatCartouche(
    resources: List<LibraryResource>,
    albums: List<LibraryAlbumDto>,
    artists: List<LibraryArtistDto>,
    tags: List<LibraryTagDto>,
    genres: List<LibraryGenreDto>
  ) {
    const duration = resources
      .stream()
      .filter(r => !!r.duration)
      .reduce((o, c) => o + c.duration, 0)
    return (
      <div className={styles.ResourceStatCartouche}>
        {this.getResourceStatCartoucheButton(
          "Resources",
          NumberUtils.formatNumber(resources.size(), 0)
        )}
        {this.getResourceStatCartoucheButton(
          "Duration",
          `${NumberUtils.formatDurationSeconds(duration)} (~${NumberUtils.formatNumber(duration/86400, 1)} days)`
        )}
        {this.getResourceStatCartoucheButton(
          "Albums",
          NumberUtils.formatNumber(albums.size(), 0)
        )}
        {this.getResourceStatCartoucheButton(
          "Artists",
          NumberUtils.formatNumber(artists.size(), 0)
        )}
        {this.getResourceStatCartoucheButton(
          "Tags",
          NumberUtils.formatNumber(tags.size(), 0)
        )}
        {this.getResourceStatCartoucheButton(
          "Genres",
          NumberUtils.formatNumber(genres.size(), 0)
        )}
      </div>
    )
  }

  private getResourceStatCartoucheButton(title: string, value: string) {
    return (
      <div className={styles.ResourceStatCartoucheButton}>
        <label>{title}</label>
        <span>{value}</span>
      </div>
    )
  }
}
