import {KeycloakService} from "../../common"
import {Logging} from "@damntools.fr/logger-simple"

export class AuthService {
  static INSTANCE: AuthService | null = null
  private readonly logger  = Logging.getLogger("AuthService")

  init(onAuth: any) {
    KeycloakService.get()
      .init()
      .then(auth => {
        if (!auth) {
          window.location.reload()
        } else {
          this.logger.info("Authenticated")
          onAuth()
        }
      })
  }

  static get(): AuthService {
    if (this.INSTANCE === null) this.INSTANCE = new AuthService()
    return this.INSTANCE
  }
}
