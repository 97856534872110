import React from "react"
import {AlertContext, AlertState} from "@damntools.fr/react-alert"
import {Optional} from "@damntools.fr/types"
import {ExplorerConsumer} from "../../providers"
import {ScannedRootDirectoryResource} from "../../../common"
import {RootDirectoryResourceEntry} from "../RootDirectoryResourceEntry"

export class RootView extends React.Component<any, any> {
  static contextType: React.Context<AlertState> = AlertContext

  constructor(props: any) {
    super(props)
    this.state = {
      search: Optional.empty()
    }
  }

  render() {
    return (
      <div>
        <div>
          <ExplorerConsumer>
            {({roots}) => {
              return (
                <div>
                  <div>
                    {roots
                      .stream()
                      .map((root: ScannedRootDirectoryResource, i: number) => (
                        <RootDirectoryResourceEntry
                          index={i}
                          rootCount={roots.size()}
                          key={root.path}
                          resource={root}
                        />
                      ))
                      .collectArray()}
                  </div>
                </div>
              )
            }}
          </ExplorerConsumer>
        </div>
      </div>
    )
  }
}
